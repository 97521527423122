import React from "react";
import "../../Styles/Pc/quaterly.css"
import { Invoices_Container } from "./Invoices_Container";
import { Salaries_Container } from "./Salaries_Container";
import { Purchases_Container } from "./Purchases_Container";
import { Bills_Container } from "./Bills_Container";
import { Tax_Container } from "./Tax_Container";
import { useLocation } from 'react-router-dom';

export const Quaterly = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries':
                return <Salaries_Container tab={"Quaterly"} refresh={props.refresh}/>;
            // case '/purchases':
            //     return <Purchases_Container tab={"Quaterly"}/>;
            case '/bills':
                return  <Bills_Container tab={"Quaterly"} refresh={props.refresh}/>;
            case '/taxes':
                return <Tax_Container tab={"Quaterly"} refresh={props.refresh}/>;
            case '/invoices':
                return  <Invoices_Container tab={"Quaterly"} refresh={props.refresh}/>;
            default:
                return '';
        }
    }

    return (
        <>
             <div id="new_layout_quaterly">
                <div id="top_lbls_purchase_grid_grp_quaterly">
                    <svg class="new_layout_down_card_1_quaterly">
                        <rect id="new_layout_down_card_1_quaterly" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                    <div id="heading_grp_new_lay_quaterly">
                        <div id="upcoming_expiry_lbl_purchase_g_quaterly"  onClick={() => props.changeTab("OverDue")}>
                            <span>Over Due</span>
                        </div>
                        <div id="onetime__lbl_purchase_grid_quaterly" onClick={() => props.changeTab("Upcoming")}>
                            <span>Upcoming</span>
                        </div>
                        <div id="monthly_lbl_purchase_grid_quaterly" onClick={() => props.changeTab("Monthly")}>
                            <span>Monthly</span>
                        </div>
                        <div id="quaterly_lbl_purchase_grid_quaterly">
                            <span>Quaterly</span>
                        </div>
                        <div id="seni_annual__lbl_purchase_grid_quaterly" onClick={() => props.changeTab("SemiAnually")}>
                            <span>Semi Annual </span>
                        </div>
                        <div id="annual_lbl_purchase_grid_quaterly" onClick={() => props.changeTab("Anually")}>
                            <span>Annual</span>
                        </div>
                        <svg class="undesrline_quaterly" viewBox="0 0 69 1">
                            <path id="undesrline_quaterly" d="M 0 0 L 69 0">
                            </path>
                        </svg>
                    </div>
                </div>


                {
                    renderComponents()
                }

            </div>
        </>
    )
}