import React from 'react'
import "../../Styles/Mob/salary_main_mob.css"

export const Salary_Main_Mob = () => {
    return (
        <>
            <div id="only_top">
                <img id="overall_bg_design_mob" src="overall_bg_design_mob.png" srcset="overall_bg_design_mob.png 1x" />
               
                
            </div>
        </>
    )
}
