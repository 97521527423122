import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import "../../Styles/Mob/rightcomponent_mob.css"
import { Salary_RightComponent_Mob } from './Salary_RightComponent_Mob';
import { Purchase_RightComponent_Mob } from './Purchase_RightComponent_Mob';
import { Bill_RightComponent_Mob } from './Bill_RightComponent_Mob';
import { Tax_RightComponent_Mob } from './Tax_RightComponent_Mob';
import { Invoice_RightComponent_Mob } from './Invoice_RightComponent_Mob';

export const RightComponent_Mob = (props) => {

    const [showRightComponent , setShowRightComponent] = useState(false)

    let location = useLocation()


    useEffect(
        () => {
            renderComponents()
        },
        [location]
    )

    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries_mob':
                return <Salary_RightComponent_Mob refreshSalaries={()=>props.refreshSalaries()} componentClose={(value) => setShowRightComponent(value)} />;
            case '/purchases_mob':
                return <Purchase_RightComponent_Mob refreshPurchases={()=>props.refreshPurchases()} componentClose={(value) => setShowRightComponent(value)} />;
            case '/bills_mob':
                return <Bill_RightComponent_Mob refreshBills={()=>props.refreshBills()} componentClose={(value) => setShowRightComponent(value)} />;
            case '/taxes_mob':
                return <Tax_RightComponent_Mob refreshTaxes={()=>props.refreshTaxes()} componentClose={(value) => setShowRightComponent(value)}/>;
            case '/invoices_mob':
                return <Invoice_RightComponent_Mob refreshInvoices={()=>props.refreshInvoices()} componentClose={(value) => setShowRightComponent(value)} />;
            default:
                return '';
        }
    }

    return (
        <>
            <img className='right_icon' src="investment.png" alt="" onClick={()=>setShowRightComponent(true)} />
            {showRightComponent === true ? renderComponents() : ""}
        </>
    )
}
