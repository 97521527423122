import React , {useState} from 'react'
import "../../Styles/Mob/stockdashboard_mob.css"
import { OverviewNearExpiry_Mob } from './OverviewNearExpiry_Mob';
import { OverviewInUse_Mob } from './OverviewInUse_Mob';
import { OverviewInStock_Mob } from './OverviewInStock_Mob';
import { OverviewExpired_Mob } from './OverviewExpired_Mob';

export const StockDashboard_Mob = () => {

    
  const [overviewSelectedMobTab, setOverviewSelectedMobTab] = useState("NearExpiry_Mob");

  return (
    <>

    <div className='stock_mpob'>
      {overviewSelectedMobTab === "NearExpiry_Mob" ? <OverviewNearExpiry_Mob overviewSelectedMobTab={(e) => setOverviewSelectedMobTab(e)} /> : ""}
      {overviewSelectedMobTab === "InUse_Mob" ? <OverviewInUse_Mob overviewSelectedMobTab={(e) => setOverviewSelectedMobTab(e)} /> : ""}
      {overviewSelectedMobTab === "InStock_Mob" ? <OverviewInStock_Mob overviewSelectedMobTab={(e) => setOverviewSelectedMobTab(e)} /> : ""}
      {overviewSelectedMobTab === "Expired_Mob" ? <OverviewExpired_Mob overviewSelectedMobTab={(e) => setOverviewSelectedMobTab(e)} /> : ""}
    </div>

  </>
  )
}

