import React, { useState, useEffect } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import { UserContext } from "../UserContext";

export const Invoice_Right_Donutchart = () => {

    //Invoices total
    const [invoiceChartData, setInvoiceChartData] = useState([]);
    const [invoiceChart, setInvoiceChart] = useState(UserContext);

    useEffect(() => {
        GetInvoice()

    }, [])


    const GetInvoice = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/invoice/getchartinvoices";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setInvoiceChartData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const totalInvoiceExpence = invoiceChartData.reduce((a, v) => a = a + v.finalAmount, 0)

    const statusPaid = 'Paid';
    const filteredInvoiceDataPaid = invoiceChartData.filter(v => v.status === statusPaid);
    const totalExpenceStatusPaid = filteredInvoiceDataPaid.reduce((a, v) => a + v.finalAmount, 0)
    const percentagePaid = isNaN((totalExpenceStatusPaid / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusPaid / totalInvoiceExpence) * 100);

    const statusDraft = 'Draft';
    const filteredInvoiceDataDraft = invoiceChartData.filter(v => v.status === statusDraft);
    const totalExpenceStatusDraft = filteredInvoiceDataDraft.reduce((a, v) => a + v.finalAmount, 0)
    const percentageDraft = isNaN((totalExpenceStatusDraft / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusDraft / totalInvoiceExpence) * 100);

    const statusOnHold = 'On Hold';
    const filteredInvoiceDataOnHold = invoiceChartData.filter(v => v.status === statusOnHold);
    const totalExpenceStatusOnHold = filteredInvoiceDataOnHold.reduce((a, v) => a + v.finalAmount, 0)
    const percentageOnHold = isNaN((totalExpenceStatusOnHold / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusOnHold / totalInvoiceExpence) * 100);

    const statusApproved = 'Approved';
    const filteredInvoiceDataApproved = invoiceChartData.filter(v => v.status === statusApproved);
    const totalExpenceStatusApproved = filteredInvoiceDataApproved.reduce((a, v) => a + v.finalAmount, 0) 
    const percentageApproved = isNaN((totalExpenceStatusApproved / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusApproved / totalInvoiceExpence) * 100);

    const statusCancelled = 'Cancelled';
    const filteredInvoiceDataCancelled = invoiceChartData.filter(v => v.status === statusCancelled);
    const totalExpenceStatusCancelled = filteredInvoiceDataCancelled.reduce((a, v) => a + v.finalAmount, 0) 
    const percentageCancelled = isNaN((totalExpenceStatusCancelled / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusCancelled / totalInvoiceExpence) * 100);

    const statusSkipped = 'Skipped';
    const filteredInvoiceDataSkipped = invoiceChartData.filter(v => v.status === statusSkipped);
    const totalExpenceStatusSkipped = filteredInvoiceDataSkipped.reduce((a, v) => a + v.finalAmount, 0)
    const percentageSkipped = isNaN((totalExpenceStatusSkipped / totalInvoiceExpence) * 100) ? 0 : ((totalExpenceStatusSkipped / totalInvoiceExpence) * 100);

    const unpaidTotal = totalExpenceStatusApproved + totalExpenceStatusDraft +totalExpenceStatusOnHold



    const data = [
        { value: percentageCancelled, color: 'rgba(153,153,153,1)' },
        { value: percentageDraft, color: '#c088d6' },
        { value: percentagePaid, color: 'rgba(106,169,255,1)' },
        { value: percentageOnHold, color: 'rgba(255,153,0,1)' },
        { value: percentageApproved, color: '#fc8787' },
        { value: percentageSkipped, color: 'rgba(255,0,0,1)' },
    ]

    const totalChartValue = data.reduce((sum, { value }) => sum + value, 0);

    const donutInvoiceChartData = data.map(({ value, color }) => ({

        value,
        color,
        percentage: isNaN((value / totalChartValue) * 100) ? 0 : ((value / totalChartValue) * 100).toFixed(0),
    }));

    const centerContent = (
        <g>
            <text
                x="50%"
                y="31%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                    fontSize: '15px',
                    fontFamily: 'sans-serif',
                    fill: 'rgb(224, 52, 52)',
                }}
            >
                Unpaid
            </text>
            <text
                x="50%"
                y="42%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    fill: 'rgb(224, 52, 52)',
                }}
            >
                {unpaidTotal}
            </text>
            <text
                x="50%"
                y="61%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                    fontSize: '15px',
                    fontFamily: 'sans-serif',
                    fill: 'rgb(55, 55, 232)',
                }}
            >
                Paid
            </text>
            <text
                x="50%"
                y="71%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    fill: 'rgb(55, 55, 232)',
                }}
            >
                {totalExpenceStatusPaid}
            </text>
        </g>
    );
    
    // const svgWidth = 180;
    // const svgHeight = 180;


    return (
        <div>
            {/* <svg width={svgWidth} height={svgHeight}> */}
            <svg>
                <PieChart
                    data={donutInvoiceChartData}
                    label={({ dataEntry }) => ` ${dataEntry.percentage}%`}
                    labelStyle={{
                        fontSize: '7px',
                        fontFamily: 'sans-serif',
                    }}
                    lineWidth={33}
                    labelPosition={83}
                    animate
                />
                {centerContent}
            </svg>

        </div>)
}
