import React, { useState, useEffect } from "react";
import "../../Styles/Pc/bills_container.css"
import { AddBills_Popup } from "./AddBills_Popup";
import { UserContext } from "../UserContext";

export const Bills_Container = (props) => {


	const [billData, setBillData] = useState([]);
	const [bill, setBill] = useState(UserContext);
	const [showBillsPopup, setShowBillsPopup] = useState("none");
	const [selectedBill, setSelectedBill] = useState({});

	useEffect(() => {
		GetBill(props.tab)

	}, [showBillsPopup])

const MonthText = (monthNumber)=>{
	
	return (monthNumber === 0? "Jan":monthNumber === 1? "Feb":monthNumber === 2?"Mar":monthNumber === 3?"Apr"
	:monthNumber === 4? "May":monthNumber === 5? "Jun":monthNumber === 6? "Jul":monthNumber === 7? "Aug":
	 monthNumber === 8? "Sep":monthNumber === 9? "Oct":monthNumber === 10? "Nov":monthNumber === 11? "Dec":'');
	
}

	const GetBill = (tab) => {

		let apiURL = process.env.REACT_APP_API_URL + "/bill/getbills/" + tab;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					setBillData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));
	}


	return (
		<>
			<div className="bill_overall_container">
				<div id="add_btn_bill" onClick={() => setShowBillsPopup("Add")} >
					<svg class="add_bg_Add_bill">
						<rect id="add_bg_Add_bill" rx="8" ry="8" x="0" y="0" width="26" height="26">
						</rect>
					</svg>
					<svg class="Add_bill" viewBox="6.75 6.75 18 18">
						<path id="Add_bill" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
						</path>
					</svg>
				</div>
				<div id="lbl_bill">
					<span>Bills</span>
				</div>
				<div id="bill_scroll_card">
					{billData.map((rec) => {
						return (
							<div id="card1_grp" style={{ position: "relative", display: "inline-flex", margin: "05px", marginTop: "10px" }} onClick={() => { setShowBillsPopup("Edit"); setSelectedBill(rec) }}>

								<svg class="inside_bg_card1" >
									<rect id="inside_bg_card1" rx="10" ry="10" x="0" y="0" width="182" height="113" >
									</rect>
								</svg>
								<div id="n_50_card1">
									<span>₹ {rec.billAmount}</span>
								</div>
								<img id="img_card_" src="img_card_.png" srcset="img_card_.png 1x" />

								<div id="Groceries_card1">
									<marquee behavior="scroll" direction="">
										<span>{rec.vendorName}</span>
									</marquee>
								</div>
								<div id="view_btn_">
									<svg class="view_btn_1">
										<rect id="view_btn_1" rx="14" ry="14" x="0" y="0" width="60" height="28">
										</rect>
									</svg>
									<div id="View_lbl">
										<span>{rec.dueDate.substr(8,2)} {MonthText(parseInt(rec.dueDate.substr(5,2)))}</span>
									</div>
								</div>
								<div id="pay_btn">
									<svg class="pay_btn_bg" style={{ backgroundColor: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)",borderRadius:"25px" }}>
										<rect id="pay_btn_bg" rx="14" ry="14" x="0" y="0" width="56.752" height="28">
										</rect>
									</svg>
									<div id="Pay_lbl" >
										<span>{rec.status}</span>
									</div>
								</div>
								<div id="Amount_card1">
									<span>Amount</span>
								</div>
								<div id="n__Days_card1">
									<span>{parseInt((new Date(rec.dueDate) - new Date()) / 86400000)} days</span>
								</div>
								<div id="Due_In_card1">
									<span>Due In</span>
								</div>
							</div>
						)
					})}
				</div>



				<AddBills_Popup display={showBillsPopup} data={selectedBill} closePopup={() => setShowBillsPopup("none")} refresh={props.refresh} />
			</div>
		</>
	)
}