import React, { useState, useEffect } from "react";
import "../../Styles/Pc/invoices_container.css"
import { UserContext } from "../UserContext";
import { AddInvoice_Popup } from "./AddInvoice_Popup";

export const Invoices_Container = (props) => {


    const [invoice, setInvoice] = useState(UserContext);
    const [invoiceData, setInvoiceData] = useState([]);
    const [showInvoicePopup, setShowInvoicePopup] = useState("none");
    const [selectedInvoice, setSelectedInvoice] = useState({});

    useEffect(() => {
        GetInvoice(props.tab)

    }, [showInvoicePopup])


    const GetInvoice = (tab) => {

        let apiURL = process.env.REACT_APP_API_URL + "/invoice/getinvoices/" + tab;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setInvoiceData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div className="salary_overall_container">
                <div id="add_btn_new_invoice" onClick={() => setShowInvoicePopup("Add")}>
                    <svg class="add_bg_Add_new_invoice">
                        <rect id="add_bg_Add_new_invoice" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_new_invoice" viewBox="6.75 6.75 18 18">
                        <path id="Add_new_invoice" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="lbl_new_invoice">
                    <span>Invoices</span>
                </div>

                <div className="detIAIL_HEADING_invoice">
                    <div className="invoice_heading">
                        <div id="Noinvoice">
                            <span>No.</span>
                        </div>
                        <div id="Client_invoice">
                            <span>Client</span>
                        </div>
                        <div id="due_date_lbl_invoice_">
                            <span>Invoice Date</span>
                        </div>
                        <div id="Date_invoice">
                            <span>Due Date</span>
                        </div>
                        <div id="Amount_invoice">
                            <span>Billed Duration</span>
                        </div>
                        <div id="Date_paid">
                            <span>Paid Date</span>
                        </div>
                        <div id="Amount_lbl">
                            <span>Amount</span>
                        </div>
                        <div id="Status_invoice">
                            <span>Status</span>
                        </div>
                    </div>
                </div>
                <div id="invoice_scroll_pc">
                    {invoiceData.map((rec) => {
                        return (
                            <div id="card_1_invoice" style={{ position: "relative", display: "block", marginBottom: "10px" }} onClick={() => { setShowInvoicePopup("Edit"); setSelectedInvoice(rec) }}>
                                <svg class="card_bg">
                                    <rect id="card_bg" rx="6" ry="6" x="0" y="0" width="100%" height="44">
                                    </rect>
                                </svg>
                                <div className="invoice_data">
                                    <div id="BCS101_invoice">
                                        <span>{rec.invoiceNumber}</span>
                                    </div>
                                    <div id="Mohammed_Affan_invoice">
                                        <span>{rec.client}</span>
                                    </div>
                                    <div id="due_date_invoice">
                                        <span>{rec.invoiceDate && rec.invoiceDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="Jun_21_2023_invoice">
                                        <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="n_23450_invoice">
                                        <span>{rec.fromDate && rec.fromDate.slice(0, 10)} to {rec.toDate && rec.toDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="paid_invoice">
                                        <span>{rec.paidDate && rec.paidDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="n_8_invoice">
                                        <span>{rec.finalAmount}</span>
                                    </div>
                                    <div className="status_inv">
                                        <div id="Draft_invoice" style={{ color: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                            <span>{rec.status}</span>
                                        </div>
                                        <svg class="invoice_status" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                            <ellipse id="invoice_status" rx="6" ry="6" cx="6" cy="6">
                                            </ellipse>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>


                <AddInvoice_Popup display={showInvoicePopup} data={selectedInvoice} closePopup={() => setShowInvoicePopup("none")} refresh={props.refresh} />
            </div>
        </>
    )
}