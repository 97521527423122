import React, { useState, useEffect } from "react";
import "../../Styles/Pc/layout_main.css"
import { Enav } from "./Enav";
import { SmallNav } from "./SmallNav";
import { Finance_Footer } from "./Finance_Footer";
import { Company_Logo } from "./Company_Logo";
import { RightSide_Component } from "./RightSide_Component";
import { Main_Component } from "./Main_Component";
import { Settings_Component } from "./Settings_Component";
import { Switch, useLocation } from 'react-router-dom';
import { ActivePageProvider } from "../Pc/Active_Page";
import { ResetPassword } from "./ResetPassword";




export const LayoutMain = (props) => {

    const [toggleNav, setToggleNav] = useState("SmallNav");

    const [refreshSalaries, setRefreshSalaries] = useState("");
    const [refreshBills, setRefreshBills] = useState("");
    const [refreshTaxes, setRefreshTaxes] = useState("");
    const [refreshInvoices, setRefreshInvoices] = useState("");
    const [refreshSettings, setRefreshSettings] = useState("");


    let location = useLocation()


    useEffect(
        () => {
            renderMainComponents()
        },
        [location]
    )

    const renderMainComponents = () => {
        switch (location.pathname) {
            case '/salaries':
                return <Main_Component refresh={refreshSalaries} />;
            case '/bills':
                return <Main_Component refresh={refreshBills} />;
            case '/taxes':
                return <Main_Component refresh={refreshTaxes} />;
            case '/invoices':
                return <Main_Component refresh={refreshInvoices} />;
            case '/settings':
                return <Settings_Component refresh={refreshSettings} />;
            default:
                return '';
        }
    }

    const renderRightComponents = () => {
        switch (location.pathname) {
            case '/salaries':
                return <RightSide_Component refreshSalaries={() => setRefreshSalaries(!refreshSalaries)} />;
            case '/purchases':
                return <RightSide_Component refreshPurchases={() => props.refreshPurchases()} />;
            case '/bills':
                return <RightSide_Component refreshBills={() => setRefreshBills(!refreshBills)} />;
            case '/taxes':
                return <RightSide_Component refreshTaxes={() => setRefreshTaxes(!refreshTaxes)} />;
            case '/invoices':
                return <RightSide_Component refreshInvoices={() => setRefreshInvoices(!refreshInvoices)} />;
            default:
                return '';
        }
    }


    return (
        <>

            <div className="main_res">
                <ResetPassword />
                <Company_Logo />
                {
                    renderRightComponents()
                }
            </div>
            <Finance_Footer />
            <ActivePageProvider>
                {toggleNav === "SmallNav" ? <SmallNav toggleNav={(e) => setToggleNav(e)} /> : ""}
                {toggleNav === "Enav" ? <Enav toggleNav={(e) => setToggleNav(e)} /> : ""}
            </ActivePageProvider>

            {
                renderMainComponents()
            }
        </>
    )
}