import React from "react";
import "../../Styles/Pc/purchase_nearexpiry.css"
import { Purchases_Container } from "./Purchases_Container";
import { useLocation } from 'react-router-dom';

export const Purchase_Nearexpiry = (props) => {

	let location = useLocation()
	const renderComponents = () => {
		switch (location.pathname) {
			case '/purchases':
				return <Purchases_Container tab={"NearExpiry"} refresh={props.refresh} />;
			default:
				return '';
		}
	}


	return (
		<>

			<div id="new_layout_near_expiry">
				<div id="top_lbls_purchase_grid_grp_near_expiry">
					<svg class="new_layout_down_card_1_near_expiry">
						<rect id="new_layout_down_card_1_near_expiry" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
						</rect>
					</svg>
					<div id="heading_grp_new_lay_near_expiry">
						<div id="near_expiry_lbl_purchase_g_near_expiry">
							<span>Near Expiry</span>
						</div>
						<div id="inuse__lbl_purchase_grid_near_expiry" onClick={() => props.purchaseSelectedTab("Inuse")}>
							<span>In Use</span>
						</div>
						<div id="instock_lbl_purchase_grid_near_expiry" onClick={() => props.purchaseSelectedTab("Instock")}>
							<span>In Stock</span>
						</div>
						<div id="expired_lbl_purchase_grid_near_expiry" onClick={() => props.purchaseSelectedTab("Expired")}>
							<span>Expired</span>
						</div>
						<svg class="undesrline_near_expiry" viewBox="0 0 69 1">
							<path id="undesrline_near_expiry" d="M 0 0 L 69 0">
							</path>
						</svg>
					</div>
				</div>


				{
					renderComponents()
				}

			</div>


		</>
	)
}
