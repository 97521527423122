import React from 'react'
import "../../Styles/Mob/bills_main_mob.css"

export const Bills_Main_Mob = () => {
    return (
        <>
            <div id="only_top_bills">
                <img id="overall_bg_design_mob_bills" src="overall_bg_design_mob.png" srcset="overall_bg_design_mob.png 1x" />

            </div>
        </>
    )
}
