import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/salariescontainer_mob.css"
import { UserContext } from "../UserContext";
import { AddSalary_Popup } from '../Pc/AddSalary_Popup';

export const SalariesContainer_Mob = (props) => {

    const [salaryData, setSalaryData] = useState([]);
    const [salary, setSalary] = useState(UserContext);
    const [addSalaryPopup, setAddSalaryPopup] = useState("none");
    const [selectedSalary, setSelectedSalary] = useState({});

    useEffect(() => {
        GetSalary(props.tab)

    }, [addSalaryPopup])


    const GetSalary = (tab) => {

        let apiURL = process.env.REACT_APP_API_URL + "/salary/getsalaries/" + tab;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setSalaryData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div id="salary_page">
                <div id="over_all_product_page">
                    <div id="Scroll_salary_mob">
                    {salaryData.map((rec) => {
                        return (
                            <div id="card_1_salary_page_mob" style={{ position: "relative", display: "inline-flex", margin: "05px", marginTop: "10px" }} onClick={() => { setAddSalaryPopup("Edit"); setSelectedSalary(rec) }}>
                            <div id="com_req_grp_card_1">
                                <svg class="com_req_card_1">
                                    <rect id="com_req_card_1" rx="10" ry="10" x="0" y="0" width="156" height="150">
                                    </rect>
                                </svg>
                                <svg class="Rectangle_63" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                    <rect id="Rectangle_63" rx="9" ry="9" x="0" y="0" width="156" height="30">
                                    </rect>
                                </svg>
                                <div id="name_salary_upcoming_page_mob_1">
                                    <span>{rec.staffName}</span>
                                </div>
                                <img id="due-date" src="due-date.png" srcset="due-date.png 1x" />

                                <div id="from_date_salary_upcoming_page_mob">
                                    <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                </div>
                                <img id="salary" src="salary.png" srcset="salary.png 1x" />

                                <div id="to_date_upcoming_page_mob">
                                    <span>{rec.finalAmount}</span>
                                </div>
                                <div id="to_date_upcoming_page_dl">
                                    <span>Due in {parseInt((new Date(rec.dueDate) - new Date()) / 86400000)} Days</span>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                    </div>
                    <div id="salary_btn_grp_mob">
                        <div id="add_btn_tax_authority"  onClick={() => setAddSalaryPopup("Add")}>
                            <svg class="add_bg_Add_Tax_Authority">
                                <rect id="add_bg_Add_Tax_Authority" rx="8" ry="8" x="0" y="0" width="26" height="26">
                                </rect>
                            </svg>
                            <svg class="Add_Tax_Authorityplus_" viewBox="6.75 6.75 18 18">
                                <path id="Add_Tax_Authorityplus_" d="M 6.75 16.94998168945312 L 14.55002021789551 16.94998168945312 L 14.55002021789551 24.75000190734863 L 16.94998168945312 24.75000190734863 L 16.94998168945312 16.94998168945312 L 24.75000190734863 16.94998168945312 L 24.75000190734863 14.55002021789551 L 16.94998359680176 14.55002021789551 L 16.94998359680176 6.75 L 14.55002021789551 6.75 L 14.55002021789551 14.55001831054688 L 6.75 14.55001831054688 L 6.75 16.94998168945312 Z">
                                </path>
                            </svg>
                        </div>
                        <div id="salarycontainer_lbl_tax_page_mob">
                            <span>Salaries</span>
                        </div>
                    </div>

                    <AddSalary_Popup display={addSalaryPopup} data={selectedSalary} closeAddSalaryPopup={() => setAddSalaryPopup("none")} refresh={props.refresh} />

                </div>
            </div>
        </>
    )
}
