import React from 'react'
import "../../Styles/Mob/inuse_mob.css"
import { useLocation } from 'react-router-dom';
import { PurchaseContainer_Mob } from './PurchaseContainer_Mob';

export const InUse_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {           
            case '/purchases_mob':
                return <PurchaseContainer_Mob tab={"InUse"} refresh={props.refresh} />;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="tax_page_mob_inuse">
                <div id="UOTMQSA_inuse_task_mob">
                    <div id="near_inuse_task_mob" onClick={() => props.changePurchaseTabMob("NearExpiryMob")}>
                        <span>Near Expiry</span>
                    </div>
                    <div id="inuse_inuse_task_mob" >
                        <span>In Use</span>
                    </div>
                    <div id="instock_inuse_task_mob" onClick={() => props.changePurchaseTabMob("InStockMob")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_inuse_task_mob" onClick={() => props.changePurchaseTabMob("ExpiredMob")}>
                        <span>Expired</span>
                    </div>
                    <svg class="undesrline_inuse_mob" viewBox="0 0 69 1">
                        <path id="undesrline_inuse_mob" d="M 0 0 L 69 0">
                        </path>
                    </svg>
                </div>
                <svg class="ot_inuse">
                    <rect id="ot_inuse" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>
            </div>

            {
					renderComponents()
				}

        </>
    )
}
