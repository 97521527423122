import React, { useState } from 'react'
import "../../Styles/Pc/stockoverview_dashboard.css"
import { Overview_NearExpiry } from './Overview_NearExpiry';
import { Overview_InUse } from './Overview_InUse';
import { Overview_InStock } from './Overview_InStock';
import { Overview_Expired } from './Overview_Expired';

export const StockOverview_Dashboard = () => {


  const [overviewSelectedTab, setOverviewSelectedTab] = useState("NearExpiry");

  return (
    <>

      <div className='stock_overview_all'>
        {overviewSelectedTab === "NearExpiry" ? <Overview_NearExpiry overviewSelectedTab={(e) => setOverviewSelectedTab(e)} /> : ""}
        {overviewSelectedTab === "InUse" ? <Overview_InUse overviewSelectedTab={(e) => setOverviewSelectedTab(e)} /> : ""}
        {overviewSelectedTab === "InStock" ? <Overview_InStock overviewSelectedTab={(e) => setOverviewSelectedTab(e)} /> : ""}
        {overviewSelectedTab === "Expired" ? <Overview_Expired overviewSelectedTab={(e) => setOverviewSelectedTab(e)} /> : ""}
      </div>

    </>
  )
}
