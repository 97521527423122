import React, { useState } from 'react'
import "../../Styles/Mob/purchase_main_mob.css"
import { NearExpiry_Mob } from './NearExpiry_Mob'
import { InUse_Mob } from './InUse_Mob'
import { InStock_Mob } from './InStock_Mob'
import { Expired_mob } from './Expired_mob'

export const Purchase_Main_Mob = (props) => {


    const [selectedPurchaseTabMob, setSelectedPurchaseTabMob] = useState("NearExpiryMob")

    return (
        <>
            <div id="only_top_purchase">
                <img id="overall_bg_design_mob_purchase" src="overall_bg_design_mob.png" srcset="overall_bg_design_mob.png 1x" />

            </div>

            {selectedPurchaseTabMob === "NearExpiryMob" ? <NearExpiry_Mob changePurchaseTabMob={(e) => setSelectedPurchaseTabMob(e)} refresh={props.refresh} /> : ""}
            {selectedPurchaseTabMob === "InUseMob" ? <InUse_Mob changePurchaseTabMob={(e) => setSelectedPurchaseTabMob(e)} refresh={props.refresh} /> : ""}
            {selectedPurchaseTabMob === "InStockMob" ? <InStock_Mob changePurchaseTabMob={(e) => setSelectedPurchaseTabMob(e)} refresh={props.refresh} /> : ""}
            {selectedPurchaseTabMob === "ExpiredMob" ? <Expired_mob changePurchaseTabMob={(e) => setSelectedPurchaseTabMob(e)} refresh={props.refresh} /> : ""}
        </>
    )
}
