import React, { useState, useEffect } from "react";
import "../../Styles/Pc/salaries_container.css"
import { UserContext } from "../UserContext";
import { AddSalary_Popup } from "./AddSalary_Popup";

export const Salaries_Container = (props) => {

    const [salaryData, setSalaryData] = useState([]);
    const [salary, setSalary] = useState(UserContext);
    const [addSalaryPopup, setAddSalaryPopup] = useState("none");
    const [selectedSalary, setSelectedSalary] = useState({});

    useEffect(() => {
        GetSalary(props.tab)

    }, [addSalaryPopup])


    const GetSalary = (tab) => {

        let apiURL = process.env.REACT_APP_API_URL + "/salary/getsalaries/" + tab;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setSalaryData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div className="salary_overall_container">
                <div id="add_btn_salary" onClick={() => setAddSalaryPopup("Add")} >
                    <svg class="add_bg_Add_salary">
                        <rect id="add_bg_Add_salary" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_salary" viewBox="6.75 6.75 18 18">
                        <path id="Add_salary" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="lbl_salary">
                    <span>Salaries</span>
                </div>
                <div id="detIAIL_HEADING">
                    <svg class="detail_bg">
                        <rect id="detail_bg" rx="15" ry="15" x="0" y="0" width="100%" height="41">
                        </rect>
                    </svg>
                    <div className="salary_heading">
                        <div id="EID_lbl">
                            <span>Staff Id</span>
                        </div>
                        <div id="name_lbl">
                            <span>Name</span>
                        </div>
                        <div id="from_date_lbl">
                            <span>Payment Duration</span>
                        </div>
                        <div id="due_date_lbl_dslaary">
                            <span>Due Date</span>
                        </div>
                        <div id="paid_date_lbl_dslaary">
                            <span>Paid Date</span>
                        </div>
                        <div id="amount_lbl">
                            <span>Amount</span>
                        </div>
                        <div id="status_lbl">
                            <span>Status</span>
                        </div>
                    </div>
                </div>
                <div id="salary_scroll">
                    {salaryData.map((rec) => {
                        return (
                            <div id="card_1_salary_page_grp" style={{ position: "relative", display: "block", marginBottom: "10px" }} onClick={() => { setAddSalaryPopup("Edit"); setSelectedSalary(rec) }} >
                                <svg class="card_1_bg">
                                    <rect id="card_1_bg" rx="15" ry="15" x="0" y="0" width="100%" height="41">
                                    </rect>
                                </svg>
                                <div className="salary_data">
                                    <div id="EID_salary">
                                        <span>{rec.staffId}</span>
                                    </div>
                                    <div id="name_salary">
                                        <span>{rec.staffName}</span>
                                    </div>
                                    <div id="due_date_timesalary">
                                        <span>{rec.fromDate && rec.fromDate.slice(0, 10)} to {rec.toDate && rec.toDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="to_date">
                                        <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="paid_date_timesalary">
                                        <span>{rec.paidDate && rec.paidDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="amount_salary">
                                        <span>{rec.finalAmount}</span>
                                    </div>
                                    <div className="status_sal">
                                        <svg class="to_pay_salary" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                            <rect id="to_pay_salary" rx="15" ry="15" x="0" y="0" width="122" height="41">
                                            </rect>
                                        </svg>
                                        <div id="status_salary">
                                            <div className="status_sword">
                                                <span>{rec.status}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>


                <AddSalary_Popup display={addSalaryPopup} data={selectedSalary} closeAddSalaryPopup={() => setAddSalaryPopup("none")} refresh={props.refresh} />
            </div>
        </>
    )
}