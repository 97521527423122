import React, { useState } from "react";
import "../../Styles/Pc/bill.css"
import { Bills_Right_Side_Component } from "./Bills_Right_Side_Component";
import { AddBills_Popup } from "./AddBills_Popup";


export const Bill = () => {


	return (
		<>

			<div id="small_nav_bills">
				<img id="over_all_bg_page" src="over_all_bg_page.png" srcset="over_all_bg_page.png 1x" />
				
			</div>


			

		</>



	)
}	