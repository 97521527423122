import React, { useState } from "react";
import "../../Styles/Pc/settings_main.css"
import { Users } from "./Users";
import { PaymentModes} from "./PaymentModes";


export const Settings_Main = (props) => {

    const [settingsSelectedTab, setSettingsSelectedTab] = useState("Users");
    

    return (
        <>

            <div id="settings_page">
                <img id="Add_Settings_Authority_bg_overall" src="../Tax_Main/Add_Tax_Authority_bg_overall.png" srcset="../Tax_Main/Add_Tax_Authority_bg_overall.png 1x" />

                
            </div>


            {/* {settingsSelectedTab === "Users" ? <Users changeTab={(e) => setSettingsSelectedTab(e)} refresh={props.refresh} /> : 
            settingsSelectedTab === "PaymentModes" ? <PaymentModes changeTab={(e) => setSettingsSelectedTab(e)} refresh={props.refresh} /> : ""}
      */}

        </>
    )
}
