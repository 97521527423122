import React from 'react'
import "../../Styles/Mob/overviewnearexpiry_mob.css"
import OverviewContainer_Mob from './OverviewContainer_Mob'
import { useLocation } from 'react-router-dom';

export const OverviewNearExpiry_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/mydashboard_mob':
                return <OverviewContainer_Mob tab={"NearExpiry"} />;
            default:
                return <OverviewContainer_Mob tab={"NearExpiry"} />;
        }
    }

    return (
        <>
            <div>
                <div id="stock_grp_mob">
                    <svg class="stock_overview_bdr_lbl_mob_sto">
                        <rect id="stock_overview_bdr_lbl_mob_sto" rx="10" ry="10" x="0" y="0" width="100%" height="260">
                        </rect>
                    </svg>
                    <div id="Stock_Overview_lbl_mob_stock">
                        <span>Stock Overview</span>
                    </div>
                    {
                        renderComponents()
                    }
                </div>
                <div id="dashboard_mob_stock_grp">
                    <svg class="over_all_secter_bg_mob_stock">
                        <rect id="over_all_secter_bg_mob_stock" rx="16" ry="16" x="0" y="0" width="344" height="32">
                        </rect>
                    </svg>
                    <svg class="mark_about_hanger_mob_stock">
                        <rect id="mark_about_hanger_mob_stock" rx="16" ry="16" x="0" y="0" width="103" height="32">
                        </rect>
                    </svg>
                    <div id="near_expiry_lbl_purchase_mob_s">
                        <span>Near Expiry</span>
                    </div>
                    <div id="in_use__lbl_purchase_mob_stock" onClick={() => props.overviewSelectedMobTab("InUse_Mob")}>
                        <span>In Use</span>
                    </div>
                    <div id="instock_lbl_purchase_mob_stock" onClick={() => props.overviewSelectedMobTab("InStock_Mob")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_lbl_purchase_mob_stock" onClick={() => props.overviewSelectedMobTab("Expired_Mob")}>
                        <span>Expired</span>
                    </div>
                </div>
            </div>
        </>

    )
}
