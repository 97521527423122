import React , {useState} from "react";
import { Upcoming } from "./Upcoming";
import { Monthly } from "./Monthly";
import { OverDue } from "./OverDue";
import { Quaterly } from "./Quaterly";
import { SemiAnually } from "./SemiAnually";
import { Anually } from "./Anually";

export const Main_Component = (props) => {

    const [taxSelectedTab , setTaxSelectedTab] = useState("OverDue");

    return(
        <>
            { taxSelectedTab === "OverDue" ? <OverDue changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh}/> : "" }
            { taxSelectedTab === "Upcoming" ? <Upcoming changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Monthly" ? <Monthly changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Quaterly" ? <Quaterly changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "SemiAnually" ? <SemiAnually changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Anually" ? <Anually changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }

        </>
    )
}