import React, { useState, useEffect } from "react";
import "../../Styles/Pc/salary_right_component.css"
import { Staff_Popup } from "./Staff_Popup";
import { UserContext } from "../UserContext";
import { Salary_Right_Donutchart } from "./Salary_Right_Donutchart";

export const Salary_Right_Component = (props) => {

    const [staff, setStaff] = useState(UserContext);
    const [staffPopup, setStaffPopup] = useState("none");
    const [staffData, setStaffData] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState({});

    const [searchText, setSearchText] = useState("");

    useEffect(() => {

        if (staff) {
            GetStaff()
        }
    }, [staffPopup])


    const GetStaff = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/staff/getstaffs";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div >
                <div id="add_btn_salary_right" onClick={() => setStaffPopup("Add")} >
                    <svg class="add_bg_Add_salary_right">
                        <rect id="add_bg_Add_salary_right" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_Tax_salary_right" viewBox="6.75 6.75 18 18">
                        <path id="Add_Tax_salary_right" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="scroll_left_component_salary_right">
                    {staffData.filter(rec => rec.staffName.toLowerCase().includes(searchText.toLowerCase()) || rec.staffId.toLowerCase().includes(searchText.toLowerCase()) || rec.staffType.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                        return (
                            <div id="tax_authority_card_1_salary_righ" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setStaffPopup("Edit"); setSelectedStaff(rec) }}>
                                <svg class="card_1_bdr_salary_right">
                                    <rect id="card_1_bdr_salary_right" rx="10" ry="10" x="0" y="0" width="197" height="117">
                                    </rect>
                                </svg>
                                <div id="Tax_Authority_lbl_salary_right">
                                    <span><marquee behavior="scroll" direction="">{rec.staffName}</marquee></span>
                                </div>
                                <div id="Registration_Number_salary_right">
                                    <span>{rec.staffId}</span>
                                </div>
                                <div id="State_tax_authority_salary_right">
                                    <span>{rec.staffType}</span>
                                </div>
                                <svg class="btw_tax_and_reg_salary_right" viewBox="0 0 184 1">
                                    <path id="btw_tax_and_reg_salary_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <svg class="between_reg_and_state_line_salary_right" viewBox="0 0 184 1">
                                    <path id="between_reg_and_state_line_salary_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <img id="taxes_1__img_all_salary_right" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                <img id="reg__img_all_salary_right" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                <img id="location_img_all_salary_right" src="../RightSide_Component/categorization.png" srcset="../RightSide_Component/categorization.png 1x" />

                            </div>
                        )
                    })}
                </div>
                <input class="filter_tax_salary_right" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <img id="search_salary_right" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />

                <div id="lbl_page_pc_salary_right">
                    <span>Staff</span>
                </div>
            </div>

            <Staff_Popup display={staffPopup} data={selectedStaff} closePopup={(refresh) => { refresh === true && props.refreshSalaries(); setStaffPopup("none") }} />


            <div id="salary_chart">
                <Salary_Right_Donutchart />
            </div>
        </>
    )
}