import React, { useState, useEffect } from "react";
import "../../Styles/Pc/rightside_component.css"
import { useLocation } from 'react-router-dom';
import { Salary_Right_Component } from "./Salary_Right _Component";
import { Purchase_Right_Component } from "./Purchase_Right_Component";
import { Tax_Right_Component } from "./Tax_Right_Component";
import { Invoice_Right_Component } from "./Invoice_Right_Component";
import { Bills_Right_Side_Component } from "./Bills_Right_Side_Component";

export const RightSide_Component = (props) => {


    let location = useLocation()


    useEffect(
        () => {
            renderComponents()
        },
        [location]
    )

    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries':
                return <Salary_Right_Component refreshSalaries={()=>props.refreshSalaries()} />;
            case '/purchases':
                return <Purchase_Right_Component refreshPurchases={()=>props.refreshPurchases()} />;
            case '/bills':
                return <Bills_Right_Side_Component refreshBills={()=>props.refreshBills()} />;
            case '/taxes':
                return <Tax_Right_Component refreshTaxes={()=>props.refreshTaxes()}/>;
            case '/invoices':
                return <Invoice_Right_Component refreshInvoices={()=>props.refreshInvoices()} />;
            default:
                return '';
        }
    }

    return (
        <div id="right_side_component">
            {
                renderComponents()
            }


        </div>
    )
}