import React, { useEffect, useState } from 'react'
import "../../Styles/Mob/layout_main_mob.css"
import { Company_Logo_Mob } from './Company_Logo_Mob'
import { Switch, useLocation } from 'react-router-dom';
import { MainComponent_Mob } from './MainComponent_Mob';
import { Navbar_Mob } from './Navbar_Mob';
import { RightComponent_Mob } from './RightComponent_Mob';

export const Layout_Main_Mob = (props) => {

    const [showNav, setShowNav] = useState(false)

    const [refreshSalaries, setRefreshSalaries] = useState("");
    const [refreshBills, setRefreshBills] = useState("");
    const [refreshTaxes, setRefreshTaxes] = useState("");
    const [refreshInvoices, setRefreshInvoices] = useState("");

    let location = useLocation()


    useEffect(
        () => {
            renderMainComponentMob()
        },
        [location]
    )

    const renderMainComponentMob = () => {
        switch (location.pathname) {
            case '/salaries_mob':
                return <MainComponent_Mob refresh={refreshSalaries} />;
            case '/bills_mob':
                return <MainComponent_Mob refresh={refreshBills} />;
            case '/taxes_mob':
                return <MainComponent_Mob refresh={refreshTaxes} />;
            case '/invoices_mob':
                return <MainComponent_Mob refresh={refreshInvoices} />;
            default:
                return '';
        }
    }

    const renderRightComponentsMob = () => {
        switch (location.pathname) {
            case '/salaries_mob':
                return <RightComponent_Mob refreshSalaries={() => setRefreshSalaries(!refreshSalaries)} />;
            case '/purchases_mob':
                return <RightComponent_Mob refreshPurchases={() => props.refreshPurchases()} />;
            case '/bills_mob':
                return <RightComponent_Mob refreshBills={() => setRefreshBills(!refreshBills)} />;
            case '/taxes_mob':
                return <RightComponent_Mob refreshTaxes={() => setRefreshTaxes(!refreshTaxes)} />;
            case '/invoices_mob':
                return <RightComponent_Mob refreshInvoices={() => setRefreshInvoices(!refreshInvoices)} />;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="task_page_mob_side_menu_btn_d" onClick={() => setShowNav(true)}>
                <svg class="side_menu_mid_d" viewBox="4.5 18 27 3">
                    <path id="side_menu_mid_d" d="M 4.5 18 L 31.5 18">
                    </path>
                </svg>
                <svg class="side_menu_top_d" viewBox="4.5 9 27 3">
                    <path id="side_menu_top_d" d="M 4.5 9 L 31.5 9">
                    </path>
                </svg>
                <svg class="side_menu_down_d" viewBox="4.5 27 27 3">
                    <path id="side_menu_down_d" d="M 4.5 27 L 31.5 27">
                    </path>
                </svg>
            </div>
            {showNav === true ? <Navbar_Mob isClose={(value) => setShowNav(value)} /> : ""}
            <Company_Logo_Mob />
            {
                renderMainComponentMob()
            }
            {
                renderRightComponentsMob()
            }
        </>
    )
}
