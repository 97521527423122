import React, { useState, useEffect , useContext } from "react";
import "../../Styles/Pc/addbills_popup.css"
import Modal from '@mui/material/Modal';
import { UserContext } from "../UserContext";

export const AddBills_Popup = (props) => {

    const currentDate = new Date();
    
    const { user, setUser } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [paymentModesData, setPaymentModesData] = useState([]);

    // Add bills
    const [billDate, setBillDate] = useState(currentDate.getFullYear().toString() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0'));
    const [dueDate, setDueDate] = useState("");
    const [billAmount, setBillAmount] = useState("0");
    const [fine, setFine] = useState("0");
    const [interest, setInterest] = useState("0");
    const [remarks, setRemarks] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [status, setStatus] = useState("Draft");

    //Vendor Fetching
    const [vendor, setVendor] = useState(UserContext);
    const [billAccountId, setBillAccountId] = useState("");
    const [vendorsData, setVendorsData] = useState([]);
    const [vendorName, setVendorName] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [paidDate, setPaidDate] = useState("");
    const [modeId, setModeId] = useState("");
    const [modeType, setModeType] = useState("");
    const [modeName, setModeName] = useState("");
    const [accountId, setAccountId] = useState("");


    const [billId, setBillId] = useState("");


    useEffect(() => {
        if (user) {
            GetUser()
        }
    }, [user, props.refresh])

    //fetching Vendor
    useEffect(() => {

        if (vendor) {
            GetVendors()
            GetPaymentModes();
        }
    }, [vendor,props.refresh])

    useEffect(() => {
        if (props.display === "Edit") {
            setBillId(props.data ? props.data._id : "")
            setVendorId(props.data ? props.data.vendorId : "")
            setVendorName(props.data ? props.data.vendorName : "")
            setBillAccountId(props.data ? props.data.accountId : "")
            setBillDate(props.data ? new Date(props.data.billDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.billDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.billDate).getDate() ).toString().padStart(2, '0'):"")
            setDueDate(props.data ? new Date(props.data.dueDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.dueDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.dueDate).getDate() ).toString().padStart(2, '0') : "")
            setBillAmount(props.data ? props.data.billAmount : "0")
            setFine(props.data ? props.data.fine : "0")
            setInterest(props.data ? props.data.interest : "0")
            setRemarks(props.data ? props.data.remarks : "")
            setApprovedBy(props.data ? props.data.approvedBy : "")
            setStatus(props.data ? props.data.status : "")
            setPaidDate(props.data ? new Date(props.data.paidDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.paidDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.paidDate).getDate()).toString().padStart(2, '0') : "")

            let mode = paymentModesData.filter(e=>e.modeType===props.data.paidMode && e.modeName===props.data.paidName && e.accountId===props.data.paidAccountId)[0]
            if(props.data && mode)
            {
                setModeId(mode._id)
            }
        }
    }, [props.data])

    useEffect(() => {
        let mode = paymentModesData.filter(e=>e._id===modeId)[0];
        if(mode)
         {
             setModeType(mode.modeType);
             setModeName(mode.modeName);
             setAccountId(mode.accountId);
         }
         else
         {
             setModeType("")
             setModeName("")
             setAccountId("")
         }
     }, [modeId])

    const clearData = ()=>{
        setVendorName("")
        setBillAccountId("")
        setBillDate("")
        setDueDate("")
        setBillAmount("0")
        setFine("0")
        setInterest("0")
        setRemarks("")
        setApprovedBy("")
        setStatus("")
        setPaidDate("")
        setModeId("")
        setModeType("")
        setModeName("")
        setAccountId("")
    }


    const GetPaymentModes = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/getmodes";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPaymentModesData(repos.data)
                    if (repos.data[0]) {
                        setModeId(repos.data[0]._id);
                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }
    //fetching user
    const GetUser = async () => {

        let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
        let apiParams = {
            method: "GET",

        };

        await fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setUserData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }




    const GetVendors = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/vendor/getvendors";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setVendorsData(repos.data)
                    if (repos.data[0]) {
                        setVendorName(repos.data[0].vendorName)
                        setVendorId(repos.data[0]._id)
                        setBillAccountId(repos.data[0].accountId)
                        setDefaultDate(repos.data[0])

                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const setDefaultDate = (rec) => {
        if (rec.frequency === "One-Time") {
            const dueDate = currentDate && currentDate.getFullYear().toString() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');
            setDueDate(dueDate);
        } else if (rec.frequency === "Monthly") {
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 8) + day;
            setDueDate(dueDate);
        } else {
            const month = rec.month.toString().padStart(2, '0');
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 5) + month + '-' + day;
            setDueDate(dueDate);
        }
    }



    const addRecord = () => {
        if (validateRecord("add"))
            {

        let apiURL = process.env.REACT_APP_API_URL + "/bill/addbill";
        let apiParams = {
            method: "POST",
            headers: {
                vendorid: vendorId,
                vendorname: vendorsData.filter((rec) => rec._id === vendorId)[0].vendorName,
                accountid: billAccountId,
                billdate: new Date(billDate),
                duedate: new Date(dueDate),
                billamount: billAmount,
                fine: fine,
                interest: interest,
                remarks: encodeURIComponent(remarks),
                approvedby: approvedBy,
                status: status,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };


        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup(false) ; clearData()
                    alert("Bill Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }



    const updateRecord = () => {
        if (validateRecord("edit"))
            {

        let apiURL = process.env.REACT_APP_API_URL + "/bill/editbills";
        let apiParams = {
            method: "POST",
            headers: {
                billid: billId,
                vendorid: vendorId,
                vendorname: vendorsData.filter((rec) => rec._id === vendorId)[0].vendorName,
                accountid: billAccountId,
                billdate: new Date(billDate),
                duedate: new Date(dueDate),
                billamount: billAmount,
                fine: fine,
                interest: interest,
                remarks: encodeURIComponent(remarks),
                approvedby: approvedBy,
                status: status,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };


        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                props.closePopup(false) ; clearData()
                alert(repos.message);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }


    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && billId.trim().length <= 0 )
        {
            validationText+="Bill Id is invalid"+"\n";
        }
        if(vendorId.trim().length <= 0) 
        {
            validationText+="Please select Vendor"+"\n";
        }
        if(isNaN(Date.parse(billDate))) 
        {
            validationText+="Please select Bill Date"+"\n";
        }
        if(billAccountId.trim().length <= 0) 
        {
            validationText+="Please enter Account ID or NA if not available"+"\n";
        }
        if(isNaN(Date.parse(dueDate))) 
        {
            validationText+="Please select Due Date"+"\n";
        }
        if(isNaN(billAmount) || billAmount<=0) 
        {
            validationText+="Please enter Bill Amount"+"\n";
        }
        if(isNaN(fine)) 
        {
            validationText+="Please enter Fine Amount"+"\n";
        }
        if(isNaN(interest)) 
        {
            validationText+="Please enter Interest Amount"+"\n";
        }
            if(approvedBy.trim().length <= 0) 
                {
                    validationText+="Please select Approved By"+"\n";
                }
        if(status.trim().length <= 0) 
            {
                validationText+="Please select Status"+"\n";
            }
        if(validationText.trim().length > 0)
            {
    alert(validationText);
    return false;
            }
            else
            {
    return true;
            }
        }
    

    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="billpopup_layout">
                    <div id="bill_pop_up">
                        <img id="bg_design_of_pop_up_bill" src="../AddBills_Popup/bg_design_of_pop_up_bill.png" srcset="../AddBills_Popup/bg_design_of_pop_up_bill.png 1x" />

                       
                        <div id="tax_auth_lbl_pop_up_bill">
                            <span>Bill Date</span>
                        </div>
                        <input tabIndex="2" placeholder="Bill Date" type="date" class="tax_auth_innput_pop_up_bill" value={billDate} onChange={(e) => setBillDate(e.target.value)} />
                       
                        <rect id="tax_auth_innput_pop_up_bill" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                        <div id="add_tax_lbl_pop_up_bill">
                        <select  class="tax_auth_innput_pop_up_head" tabIndex="1" autoFocus
                            value={vendorId}
                            onChange={(e) => {
                                let accId = vendorsData.filter((item) => item._id === e.target.value)[0].accountId;
                                setBillAccountId(accId);
                                setVendorId(e.target.value);
                                props.display === "Add" && setDefaultDate(vendorsData.filter((item) => item._id === e.target.value)[0])
                            }}>
                            <rect id="tax_auth_innput_pop_up_head" rx="0" ry="0" x="0" y="0" width="260" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {vendorsData.map((rec) => (
                                <option value={rec._id} >{rec.vendorName}</option>
                            ))}
                        </select> 
                        </div>
                        <div id="reg_no_lbl_pop_up_bill">
                            <span>Account ID</span>
                        </div>
                        <input placeholder="Account Id" tabIndex="3" class="redistration_n0_input_pop_up_b" viewBox="0 0 157.254 40" value={billAccountId} onChange={(e) => setBillAccountId(e.target.value)} />
                     
                        <div id="interest_paid_pop_up_bill">
                            <span>Interest</span>
                        </div>
                        <input placeholder="Interest Paid" type="number" tabIndex="7" class="interest_paid_inpit_pop_up_bil" value={interest} onChange={(e) => setInterest(e.target.value)} />
                        <div id="tax_amount_paid_lbl_pop_up_bil">
                            <span>Bill Amount</span>
                        </div>
                        <input placeholder="Bill Amount" type="number" tabIndex="5" class="tax_amount_paid_inputpop_up_bi" value={billAmount} onChange={(e) => setBillAmount(e.target.value)} />
                        <div id="fine_paid_lbl_pop_upbill">
                            <span>Fine</span>
                        </div>
                        <input placeholder="Fine Paid" type="number" tabIndex="6" class="fine_paid_input_pop_up_bill" value={fine} onChange={(e) => setFine(e.target.value)} />
                        <div id="remark_tax_pop_up_bill">
                            <span>Remarks</span>
                        </div>
                        <textarea placeholder="Remarks" tabIndex="8" class="remark_input_pop_up_bill" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                        <button id="tax_btn_grp_pop_up_bill">
                            <svg class="passs_bdr_btn_pop_up_bill">
                                <rect id="passs_bdr_btn_pop_up_bill" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                </rect>
                            </svg>
                            <div id="add_lbl_pop_up_bill">
                                <span tabIndex="11" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit" : "Add"}</span>
                            </div>
                        </button>
                        <img onClick={() => {props.closePopup(false) ; clearData()}} id="close_tax_pop_up_bill" src="../AddBills_Popup/close_tax_pop_up_bill.png" srcset="../AddBills_Popup/close_tax_pop_up_bill.png 1x" />

                        <div id="due_date_lbl_pop_up_bill1">
                            <span>Due Date</span>
                        </div>
                        <input placeholder="Due Date" tabIndex="4" type="date" class="due_date_pop_up_billdate_1" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                    
                           <div id="status_pop_up_bill">
                            <span>Status</span>
                        </div>
                        <select name="status" tabIndex="10" className="status_input_pop_up_bill" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" >--- Select ---</option>
                            <option value="Draft" >Draft</option>
                            <option value="Approved" >Approved</option>
                            <option value="On Hold" >On Hold</option>
                            <option value="Paid">Paid</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Skipped">Skipped</option>
                        </select>
                        <div id="approved_by_paid_lbl_pop_up_bi">
                            <span>Approved By</span>
                        </div>
                        <select className="approved_by_input_pop_up_bill" tabIndex="9" value={approvedBy} onChange={(e) => setApprovedBy(e.target.value)}>
                            <option value="">-- Select --</option>
                            {userData.filter(v =>v.userDepartments === "Administration" || v.userDepartments === "Directors").map((rec) => (
                                <option value={rec.name} >{rec.name}</option>
                            ))}
                        </select>
                        <div id="paid_through_paid_lbl_pop_up_bi">
                            <span>Paid Through</span>
                        </div>
                        <select tabIndex="13" class="paid_through_input_pop_up_bill" 
                        value={modeId} 
                        onChange={(e) => setModeId(e.target.value)} >
                            <rect id="tax_auth_innput_pop_up_salary" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {paymentModesData.map((rec) => (
                                <option value={rec._id} >{rec.modeType.toUpperCase()+" - "+rec.modeName}</option>
                            ))}
                        </select>
                        <div id="paid_on_paid_lbl_pop_up_bi" >
                            <span>Paid On</span>
                        </div>
                        <input tabIndex="14" type="date" class="paid_on_input_pop_up_bill" value={paidDate} onChange={(e) => setPaidDate(e.target.value)} />
                        <div id="paid_name_paid_lbl_pop_up_bi">
                            <span>Acc. Id / Name</span>
                        </div>
                        <input placeholder="Acc. Id / Name" autoFocus tabIndex="1" class="paid_by_input_pop_up_bill" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
                </div>
                </div>

            </Modal>

        </>
    )
}