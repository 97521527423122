import React from 'react'
import "../../Styles/Mob/expired_mob.css"
import { useLocation } from 'react-router-dom';
import { PurchaseContainer_Mob } from './PurchaseContainer_Mob';

export const Expired_mob = (props) => {

    let location = useLocation()
	const renderComponents = () => {
		switch (location.pathname) {
			case '/purchases_mob':
				return <PurchaseContainer_Mob tab={"Expired"} refresh={props.refresh}/>;
			default:
				return '';
		}
	}

    return (
        <>
            <div id="tax_page_mob_expired">
                <div id="UOTMQSA_expired_task_mob">
                    <div id="near_expired_task_mob" onClick={() => props.changePurchaseTabMob("NearExpiryMob")}>
                        <span>Near Expiry</span>
                    </div>
                    <div id="inuse_expired_task_mob" onClick={() => props.changePurchaseTabMob("InUseMob")}>
                        <span>In Use</span>
                    </div>
                    <div id="instock_expired_task_mob" onClick={() => props.changePurchaseTabMob("InStockMob")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_expired_task_mob">
                        <span>Expired</span>
                    </div>
                    <svg class="undesrline_expired_mob" viewBox="0 0 69 1">
                        <path id="undesrline_expired_mob" d="M 0 0 L 69 0">
                        </path>
                    </svg>
                </div>
                <svg class="ot_expired">
                    <rect id="ot_expired" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>
            </div>

            {
					renderComponents()
				}

        </>
    )
}
