import React from 'react'
import "../../Styles/Mob/nearexpiry_mob.css"
import { useLocation } from 'react-router-dom';
import { PurchaseContainer_Mob } from './PurchaseContainer_Mob';

export const NearExpiry_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/purchases_mob':
                return <PurchaseContainer_Mob tab={"NearExpiry"} refresh={props.refresh} />;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="tax_page_mob_nearexpiry">
                <div id="UOTMQSA_nearexpiry_task_mob">
                    <div id="near_nearexpiry_task_mob">
                        <span>Near Expiry</span>
                    </div>
                    <div id="inuse_nearexpiry_task_mob" onClick={() => props.changePurchaseTabMob("InUseMob")}>
                        <span>In Use</span>
                    </div>
                    <div id="instock_nearexpiry_task_mob" onClick={() => props.changePurchaseTabMob("InStockMob")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_nearexpiry_task_mob" onClick={() => props.changePurchaseTabMob("ExpiredMob")}>
                        <span>Expired</span>
                    </div>
                    <svg class="undesrline_nearexpiry_mob" viewBox="0 0 1 89">
                        <path id="undesrline_nearexpiry_mob" d="M 0 0 L 0 89">
                        </path>
                    </svg>
                </div>
                <svg class="ot_nearexpiry">
                    <rect id="ot_nearexpiry" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>
            </div>

            {
                renderComponents()
            }

        </>
    )
}
