import React, { useState, useContext } from 'react'
import "../../Styles/Mob/navbar_mob.css"
import { UserContext } from '../UserContext';
import { Link } from "react-router-dom";
import { ResetPassword } from '../Pc/ResetPassword';
import { ResetPassword_Popup } from '../Pc/ResetPassword_Popup';

export const Navbar_Mob = (props) => {

    const { user, setUser } = useContext(UserContext);

    const [resetPass, setResetPass] = useState(false);


    const handlelogout = () => {
        localStorage.clear();
        setUser(null);
        window.location.href = "/";
    }

    return (
        <>
            <div id="mob_menu_page" className='fadeIn'>
                <div id="mob_menu_overall_grp">
                    <div id="mob_menu_grp">
                        <svg class="green_bg_menu_mob">
                            <rect id="green_bg_menu_mob" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
                            </rect>
                        </svg>
                        <div id="all_menu_details_mob">
                            <Link to={"/mydashboard_mob"} onClick={() => props.isClose(false)}>
                                <div id="dashboard_grp_mob" >
                                    <div id="Dashboard_side_lbl_mob" >
                                        <span>Dashboard</span>
                                    </div>
                                    <img id="dashboard_Icon_I_1_mob" src="../NavBar/dashboard.png" srcset="../NavBar/dashboard.png 1x" />
                                </div>
                            </Link>
                            {
                                (user && (user.userDepartments === "Human Resource") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                                <Link to={"/salaries_mob"} onClick={() => props.isClose(false)}>
                                    <div id="salary_grp_mob">
                                        <div id="salary_mob_side_lbl">
                                            <span>Salaries</span>
                                        </div>
                                        <img id="salaery_Icon_I_1" src="../NavBar/Salaries.png" srcset="../NavBar/Salaries.png 1x" />
                                    </div>
                                </Link>
                            }
                            {
                                (user && (user.userDepartments === "Administration") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors") || (user.userDepartments === "Management")) &&
                                <Link to={"/purchases_mob"} onClick={() => props.isClose(false)}>
                                    <div id="purchase_grp_expand">
                                        <div id="ourchase_mob_side_lbl_pc">
                                            <span>Purchases</span>
                                        </div>
                                        <img id="purchase_mob_icon_I_1" src="../NavBar/purchases.png" srcset="../NavBar/purchases.png 1x" />
                                    </div>
                                </Link>
                            }
                            {
                                (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Administration") || (user.userDepartments === "Directors")) &&
                                <Link to={"/bills_mob"} onClick={() => props.isClose(false)}>
                                    <div id="bill_mob_grp_expand">
                                        <div id="mob_bill_side_lbl_pc">
                                            <span>Bill</span>
                                        </div>
                                        <img id="bill_mob_icon_I_1" src="../NavBar/bill.png" srcset="../NavBar/bill.png 1x" />
                                    </div>
                                </Link>
                            }
                            {
                                (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                                <Link to={"/taxes_mob"} onClick={() => props.isClose(false)}>
                                    <div id="tax_mob_grp_expand">
                                        <div id="tax_mob_lbl_pc">
                                            <span>Tax</span>
                                        </div>
                                        <img id="tax_mob_icon_I_1" src="../NavBar/taxes.png" srcset="../NavBar/taxes.png 1x" />
                                    </div>
                                </Link>
                            }
                            {
                                (user && (user.userDepartments === "Management") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                                <Link to={"/invoices_mob"} onClick={() => props.isClose(false)}>
                                    <div id="invoice_grp_expand">
                                        <div id="invoice_side_lbl_mob">
                                            <span>Invoice</span>
                                        </div>
                                        <img id="invoice_icon_I_1_mob" src="../NavBar/invoice.png" srcset="../NavBar/invoice.png 1x" />
                                    </div>
                                </Link>
                            }
                            <Link to={"/settings_mob"} onClick={() => props.isClose(false)}>
                                <div id="setup_grp_expand_mob">
                                    <div id="setup_lbl_mob">
                                        <span>Setup</span>
                                    </div>
                                    <img className="setup_icon_ext_mob" src="../NavBar/management.png" alt="" />
                                </div>
                            </Link>
                            <div className='reset_pass_mob' onClick={() => setResetPass(true)} >
                                <div id="reset_pass_lbl_mob">
                                    <span>Change Password</span>
                                </div>
                                <img className='reset_pass_mob_icon' src="../resetpassword2.png" alt="" />
                            </div>
                            <div id="logout_grp_expand" onClick={() => handlelogout()}>
                                <div id="logout_mob_side_lbl_pc">
                                    <span>Log Out</span>
                                </div>
                                <img id="logout_icon_I_mob" src="../NavBar/logout.png" srcset="../NavBar/logout.png 1x" />
                            </div>
                        </div>
                        <marquee id="menu_name_pc_mob">
                            <span>{user && user.name}</span>
                        </marquee>
                        <div id="pfp_grp_menu_mob">
                            <svg class="pfp_bdr_menu_mob">
                                <ellipse id="pfp_bdr_menu_mob" rx="28.5" ry="28.5" cx="28.5" cy="28.5">
                                </ellipse>
                            </svg>
                            <img id="pfp_img_menu_mob"
                                src={user.imageId && user.imageId.length > 10
                                    ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
                                    user.imageId
                                    :
                                    "default_profile.png"
                                }
                            />
                        </div>
                    </div>
                    <svg class="blur_img_bd" onClick={() => props.isClose(false)}>
                    </svg>
                </div>
            </div>

            <ResetPassword_Popup display={resetPass} closePopup={(value) => setResetPass(value)} />
        </>
    )
}
