import React, { useEffect, useState } from 'react'
import "../../Styles/Pc/resetpassword.css"
import { ResetPassword_Popup } from './ResetPassword_Popup';

export const ResetPassword = () => {

    const [resetPass, setResetPass] = useState(false);




    return (
        <>
            <div className='reset_layout_res'>
                <img className='changePass' src="../resetpassword.png" alt="" onClick={() => setResetPass(true)} />


                <ResetPassword_Popup display={resetPass} closePopup={(value) => setResetPass(value)} />
            </div>
        </>
    )
}
