import React, { useState, useEffect } from "react";
import "../../Styles/Pc/purchases_container.css"
import { AddPurchase_Popup } from "./AddPurchase_Popup";
import { UserContext } from "../UserContext";

export const Purchases_Container = (props) => {


	const [purchaseData, setPurchaseData] = useState([]);
	const [purchase, setPurchase] = useState(UserContext);
	const [addPurchasePopup, setAddPurchasePopup] = useState("none");
	const [selectedPurchase, setSelectedPurchase] = useState({});

	useEffect(() => {
		GetPurchase(props.tab)

	}, [addPurchasePopup])


	const GetPurchase = (tab) => {

		let apiURL = process.env.REACT_APP_API_URL + "/purchase/getpurchases/" + tab;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					setPurchaseData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));
	}



	return (
		<>
			<div className="nearexpiry_overall_container">
				<div id="purchases_btn_lbl">
					<span>Purchases</span>
				</div>
				<div id="add_btn_purchase" onClick={() => setAddPurchasePopup("Add")} >
					<svg class="add_bg_Add_purchases">
						<rect id="add_bg_Add_purchases" rx="8" ry="8" x="0" y="0" width="26" height="26">
						</rect>
					</svg>
					<svg class="Add_Tax_purchaseyplus_" viewBox="6.75 6.75 18 18">
						<path id="Add_Tax_purchaseyplus_" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
						</path>
					</svg>
				</div>

				<div className="detIAIL_HEADING_purchase">
					<div className="purchase_heading">
						<div id="purchase_purchase_grid">
							<span>Product</span>
						</div>
						<div id="category_purchase_grid">
							<span>Category</span>
						</div>
						<div id="expiry_purchase_grid_near_expiry">
							<span>Due Date</span>
						</div>
						<div id="cost_purchase_grid">
							<span>Cost</span>
						</div>
						
						<div id="status_purchase_grid">
							<span>Status</span>
						</div>
						<div id="contact_staff_purchase_grid">
							<span>Allocated To</span>
						</div>
						<div id="view_invoice_purchase_grid">
							<span>View Invoice</span>
						</div>
					</div>
					<svg class="Line_purchase_grid" viewBox="0 0 960.775 1">
						<path id="Line_purchase_grid" d="M 0 0 L 960.77490234375 0">
						</path>
					</svg>
				</div>
				<div id="Scroll_purchase">
					{purchaseData.map((rec) => {
						return (
							<div id="card1_example_purchase_grid" style={{ position: "relative", display: "block", marginBottom: "10px" }} onClick={() => { setAddPurchasePopup("Edit"); setSelectedPurchase(rec) }}>
								<div className="purchase_data">
								<div id="product_example_purchase_grid">
									<span>{rec.product}</span>
								</div>
								<div id="category_example_purchase_grid">
									<span>{rec.category}</span>
								</div>
								<div id="Expiry_example_example_purchas">
									<span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
								</div>
								<div id="cost_example_purchase_grid">
									<span>{rec.itemCost}</span>
								</div>
								<div className="status_example_purchase_grid" style={{ color: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
									<span>{rec.status}</span>
								</div>
								<div id="contact_staff_purchase_grid_dl">
									<span>{rec.allocatedTo}</span>
								</div>
								</div>
							</div>
						)
					})}
				</div>


				<AddPurchase_Popup display={addPurchasePopup} data={selectedPurchase} closeAddPurchasePopup={() => setAddPurchasePopup("none")} refresh={props.refresh} />
			</div>
		</>
	)
}