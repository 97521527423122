import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Pc/expenceanalytics.css"
import { EaSalary } from './EaSalary'
import { EaInvoices } from './EaInvoices'
import { EaBills } from './EaBills'
import { EaTaxes } from './EaTaxes'
import { EaPurchaces } from './EaPurchaces'
import { UserContext } from "../UserContext";
import { AddSalary_Popup } from './AddSalary_Popup'
import { AddBills_Popup } from './AddBills_Popup'
import { AddTax_Popup } from './AddTax_Popup'
import { AddPurchase_Popup } from './AddPurchase_Popup'
import { AddInvoice_Popup } from './AddInvoice_Popup'

export const ExpenceAnalytics = (props) => {

    const { user, setUser } = useContext(UserContext);

    const [addSalaryPopup, setAddSalaryPopup] = useState("none");
    const [showBillsPopup, setShowBillsPopup] = useState("none");
    const [addTaxPopup, setAddTaxPopup] = useState("none");
    const [addPurchasePopup, setAddPurchasePopup] = useState("none");
    const [showInvoicePopup, setShowInvoicePopup] = useState("none");
	const [ eaactive, setEaActive ] = useState("SalaryTab");

    const tabValue = () => {
        if (user && user.userDepartments === "Human Resource") {
            return "EaSalary"
        }
        else if (user && user.userDepartments === "Administration") {
            return "EaBills"
        }
        else if (user && user.userDepartments === "Accounts") {
            return "EaSalary"
        }

        else if (user && user.userDepartments === "Management") {
            return "EaPurchase"
        }
        else if (user && user.userDepartments === "Directors") {
            return "EaSalary"
        }
    }

    const [EaSelectedTab, setEaSelectedTab] = useState(tabValue());

    //Salary total
    const [salaryData, setSalaryData] = useState([]);
    const [salary, setSalary] = useState(UserContext);

    useEffect(() => {
        if (props.salaryData) {
            setSalaryData(props.salaryData)
        }
    }, [props.salaryData])





    //Bills Total
    const [billData, setBillData] = useState([]);
    const [bill, setBill] = useState(UserContext);

    useEffect(() => {
        if (props.billData) {
            setBillData(props.billData)
        }
    }, [props.billData])




    //Taxes Total
    const [taxData, setTaxData] = useState([]);
    const [tax, setTax] = useState(UserContext);

    useEffect(() => {
        if (props.taxData) {
            setTaxData(props.taxData)
        }
    }, [props.taxData])





    //Purchases total
    const [purchaseData, setPurchaseData] = useState([]);
    const [purchase, setPurchase] = useState(UserContext);

    useEffect(() => {
        if (props.purchaseData) {
            setPurchaseData(props.purchaseData)
        }
    }, [props.purchaseData])




    //Invoices total
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoice, setInvoice] = useState(UserContext);

    useEffect(() => {
        if (props.invoiceData) {
            setInvoiceData(props.invoiceData)
        }
    }, [props.invoiceData])


    const popups = () => {
        switch (EaSelectedTab) {
            case "EaSalary":
                return setAddSalaryPopup("Add")
                break;
            case "EaBills":
                return setShowBillsPopup("Add")
                break;
            case "EaTaxes":
                return setAddTaxPopup("Add")
                break;
            case "EaPurchase":
                return setAddPurchasePopup("Add")
                break;
            case "EaInvoice":
                return setShowInvoicePopup("Add")
                break;

            default:
                break;
        }
    }



    return (
        <>
            <div className='overall_ea'>
                {
                    (user && user.userDepartments === "Human Resource") &&
                    <svg class="expense_analytics_over_bdr">
                        <rect id="expense_analytics_over_all_bdr" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                }
                {
                    (user && (user.userDepartments === "Administration") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors") || (user.userDepartments === "Management")) &&
                    <svg class="expense_analytics_over_all_bdr">
                        <rect id="expense_analytics_over_all_bdr" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                }
                <div id="Expense_Analytics_lbl_dash_pc">
                    <span>Expense Analytics</span>
                </div>
                <div id="all_grp_exxpense_alanytic">
                    {
                        (user && (user.userDepartments === "Human Resource") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                        <div id="salaries_expense_analytuics_gr" onClick={() => {setEaSelectedTab("EaSalary") ; setEaActive("SalaryTab")}} className={eaactive === "SalaryTab" ? "eaactive_tab" : ""}>
                            <svg class="salaries_dash_pc">
                                <rect id="salaries_dash_pc" rx="3" ry="3" x="0" y="0" width="100%" height="6px">
                                </rect>
                            </svg>
                            <div className='salary_ea_dash'>
                                <svg class="salaries_dash_pc_e">
                                    <ellipse id="salaries_dash_pc_e" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="Salaries_lbl_dash_pc">
                                    <span>Salaries</span>
                                </div>
                            </div>
                            <div id="n_100000_salary_dash_pc">
                                <span>₹ {salaryData.reduce((a, v) => a = a + v.finalAmount, 0).toLocaleString()}</span>
                            </div>
                        </div>
                    }
                    {
                        (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Administration") || (user.userDepartments === "Directors")) &&
                        <div id="bills_expense_analytuics_grp" onClick={() => {setEaSelectedTab("EaBills") ; setEaActive("BillTab")}} className={eaactive === "BillTab" ? "eaactive_tab" : ""}>
                            <svg class="bills_bar_dash_pc">
                                <rect id="bills_bar_dash_pc" rx="3" ry="3" x="0" y="0" width="100%" height="6">
                                </rect>
                            </svg>
                            <div className='bill_ea_dash'>
                                <svg class="bills_circle_dash_pc">
                                    <ellipse id="bills_circle_dash_pc" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="Bills_lbl_dash_pc">
                                    <span>Bills</span>
                                </div>
                            </div>
                            <div id="n_10000_bills_dash_pc">
                                <span>₹ {billData.reduce((a, v) => a = a + v.billAmount, 0).toLocaleString()}</span>
                            </div>
                        </div>
                    }
                    {
                        (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                        <div id="taxes_expense_analytuics_grp" onClick={() => {setEaSelectedTab("EaTaxes") ; setEaActive("TaxTab")}} className={eaactive === "TaxTab" ? "eaactive_tab" : ""}>
                            <svg class="taxes_bar_dash_pc">
                                <rect id="taxes_bar_dash_pc" rx="3" ry="3" x="0" y="0" width="100%" height="6">
                                </rect>
                            </svg>
                            <div className='tax_ea_dash'>
                                <svg class="taxes_bdr_dash_pc">
                                    <ellipse id="taxes_bdr_dash_pc" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="Taxes_lvbl_dash_pc">
                                    <span>Taxes</span>
                                </div>
                            </div>
                            <div id="n_1000000_taxes_dash_pc">
                                <span>₹ {taxData.reduce((a, v) => a = a + v.taxAmount, 0).toLocaleString()}</span>
                            </div>
                        </div>
                    }
                    {
                        (user && (user.userDepartments === "Administration") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors") || (user.userDepartments === "Management")) &&
                        <div id="purchase_expense_analytuics_gr" onClick={() => {setEaSelectedTab("EaPurchase") ; setEaActive("PurchaseTab")}} className={eaactive === "PurchaseTab" ? "eaactive_tab" : ""}>
                            <svg class="purchase_bdr_dash_pc">
                                <rect id="purchase_bdr_dash_pc" rx="3" ry="3" x="0" y="0" width="100%" height="6">
                                </rect>
                            </svg>
                            <div className='purchase_ea_dash'>
                                <svg class="purchase_circe_dash_pc">
                                    <ellipse id="purchase_circe_dash_pc" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="Purchases_lv_dash_pc">
                                    <span>Purchases</span>
                                </div>
                            </div>
                            <div id="n_10000_purchase_dash_pc">
                                <span>₹ {purchaseData.reduce((a, v) => a = a + v.itemCost, 0).toLocaleString()}</span>
                            </div>
                        </div>
                    }
                    {
                        (user && (user.userDepartments === "Management") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                        <div id="invoice_expense_analytuics_grp" onClick={() => {setEaSelectedTab("EaInvoice") ; setEaActive("InvoiceTab")}} className={eaactive === "InvoiceTab" ? "eaactive_tab" : ""}>
                            <svg class="invoice_bdr_progress_bar_dash_">
                                <rect id="invoice_bdr_progress_bar_dash_" rx="3" ry="3" x="0" y="0" width="100%" height="6">
                                </rect>
                            </svg>
                            <div className='invoice_ea_dash'>
                                <div id="Invoices_lbl_dash_pc">
                                    <span>Invoices</span>
                                </div>
                                <svg class="invoice_color_circle_dash_pc">
                                    <ellipse id="invoice_color_circle_dash_pc" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="n_10000_invoice_lbl_dash_pc">
                                <span>₹ {invoiceData.reduce((a, v) => a = a + v.finalAmount, 0).toLocaleString()}</span>
                            </div>
                        </div>
                    }
                </div>
                <button id="add_btn_all" onClick={() => popups()} >
                    <svg class="add_bg_all">
                        <rect id="add_bg_all" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_all" viewBox="6.75 6.75 18 18">
                        <path id="Add_all" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </button>


                {EaSelectedTab === "EaSalary" ? <><EaSalary salaryData={props.salaryData} /> <AddSalary_Popup display={addSalaryPopup} closeAddSalaryPopup={() => setAddSalaryPopup("none")} refresh={props.refresh} /></> : ""}
                {EaSelectedTab === "EaBills" ? <><EaBills billData={props.billData} /> <AddBills_Popup display={showBillsPopup} closePopup={() => setShowBillsPopup("none")} refresh={props.refresh} /></> : ""}
                {EaSelectedTab === "EaTaxes" ? <><EaTaxes taxData={props.taxData} /> <AddTax_Popup display={addTaxPopup} closeAddTaxPopup={() => setAddTaxPopup("none")} refresh={props.refresh} /></> : ""}
                {EaSelectedTab === "EaInvoice" ? <><EaInvoices invoiceData={props.invoiceData} /> <AddInvoice_Popup display={showInvoicePopup} closePopup={() => setShowInvoicePopup("none")} refresh={props.refresh} /></> : ""}
                {EaSelectedTab === "EaPurchase" ? <><EaPurchaces purchaseData={props.purchaseData} /> <AddPurchase_Popup display={addPurchasePopup} closeAddPurchasePopup={() => setAddPurchasePopup("none")} refresh={props.refresh} /></> : ""}
            </div>
        </>
    )
}










