import React, { useState, useEffect , useContext } from "react";
import "../../Styles/Pc/addpurchase_popup.css"
import Modal from '@mui/material/Modal';
import { UserContext } from "../UserContext";

export const AddPurchase_Popup = (props) => {

    const { user, setUser } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    const [additionalCharges, setAdditionalCharges] = useState([]);

    //merchant Fetching
    const [merchant, setMerchant] = useState(UserContext);
    const [merchantId, setMerchantId] = useState([]);
    const [merchantData, setMerchantData] = useState([]);

    //add purchase
    const [product, setProduct] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [category, setCategory] = useState("");
    const [itemCost, setItemCost] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [purchasedFrom, setPurchasedFrom] = useState("");
    const [contactStaff, setContactStaff] = useState("");
    const [staffNumber, setStaffNumber] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [purchasedBy, setPurchasedBy] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [status, setStatus] = useState("");
    const [allocatedTo, setAllocatedTo] = useState("");
    const [allocatedOn, setAllocatedOn] = useState("");

    //edit purchase
    const [purchaseId, setPurchaseId] = useState("");



    useEffect(() => {
        if (user) {
            GetUser()
        }
    }, [user, props.refresh])

    useEffect(() => {
        if (props.display === "Edit") {
            setPurchaseId(props.data ? props.data._id : "")
            setProduct(props.data ? props.data.product : "")
            setPurchaseDate(props.data ? new Date(props.data.purchaseDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.purchaseDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.purchaseDate).getDate()).toString().padStart(2, '0') : "")
            setExpiryDate(props.data ? new Date(props.data.expiryDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.expiryDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.expiryDate).getDate()).toString().padStart(2, '0') : "")
            setCategory(props.data ? props.data.category : "")
            setItemCost(props.data ? props.data.itemCost : "")
            setAdditionalCharges(props.data.additionalCharges ? JSON.parse(props.data.additionalCharges) || [] : [])
            setTotalAmount(props.data ? props.data.totalAmount : 0)
            setMerchantId(props.data ? props.data.merchantId : "")
            setPurchasedFrom(props.data ? props.data.purchasedFrom : "")
            setContactStaff(props.data ? props.data.contactStaff : "")
            setStaffNumber(props.data ? props.data.staffNumber : "")
            setApprovedBy(props.data ? props.data.approvedBy : "")
            setPurchasedBy(props.data ? props.data.purchasedBy : "")
            setDueDate(props.data ? new Date(props.data.dueDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.dueDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.dueDate).getDate()).toString().padStart(2, '0') : "")
            setStatus(props.data ? props.data.status : "")
            setAllocatedTo(props.data ? props.data.allocatedTo : "")
            setAllocatedOn(props.data ? new Date(props.data.allocatedOn).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.allocatedOn).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.allocatedOn).getDate()).toString().padStart(2, '0') : "")

        }
    }, [props.data])



    const clearData = () => {
        setProduct("")
        setPurchaseDate("")
        setExpiryDate("")
        setCategory("")
        setItemCost("")
        setAdditionalCharges([])
        setTotalAmount(0)
        setPurchasedFrom("")
        setContactStaff("")
        setStaffNumber("")
        setApprovedBy("")
        setPurchasedBy("")
        setDueDate("")
        setStatus("")
        setAllocatedTo("")
        setAllocatedOn("")
    }




    const addPurchase = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/purchase/addpurchase";
        let apiParams = {
            method: "POST",
            headers: {
                product: product,
                purchasedate: purchaseDate,
                expirydate: expiryDate,
                category: category,
                itemcost: itemCost,
                additionalcharges: JSON.stringify(additionalCharges),
                totalamount: totalAmount,
                merchantid: merchantId,
                purchasedfrom: merchantData.filter((rec) => rec._id === merchantId)[0].name,
                contactstaff: contactStaff,
                staffnumber: staffNumber,
                approvedby: approvedBy,
                purchasedby: purchasedBy,
                duedate: dueDate,
                status: status,
                allocatedto: allocatedTo,
                allocatedon: allocatedOn
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closeAddPurchasePopup(false); clearData()
                    alert("Purchase Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    const updatePurchase = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/purchase/editpurchase";
        let apiParams = {
            method: "POST",
            headers: {
                purchaseid: purchaseId,
                product: product,
                purchasedate: purchaseDate,
                expirydate: expiryDate,
                category: category,
                itemcost: itemCost,
                additionalcharges: JSON.stringify(additionalCharges),
                totalamount: totalAmount,
                merchantid: merchantId,
                purchasedfrom: merchantData.filter((rec) => rec._id === merchantId)[0].name,
                contactstaff: contactStaff,
                staffnumber: staffNumber,
                approvedby: approvedBy,
                purchasedby: purchasedBy,
                duedate: dueDate,
                status: status,
                allocatedto: allocatedTo,
                allocatedon: allocatedOn
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closeAddPurchasePopup(false); clearData()
                    alert("Purchase Updated Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    //fetching user
    const GetUser = async () => {

        let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
        let apiParams = {
            method: "GET",

        };

        await fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setUserData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    //fetching  merchant
    useEffect(() => {

        if (merchant) {
            GetMerchants()
        }
    }, [merchant, props.refresh])


    const GetMerchants = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/merchant/getmerchants";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setMerchantData(repos.data)
                    if (repos.data[0]) {
                        setPurchasedFrom(repos.data[0].merchant);
                        setMerchantId(repos.data[0]._id);
                        setDefaultDate(repos.data[0])

                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    const setDefaultDate = (rec) => {
        if (rec.frequency === "onetime") {
            const currentDate = new Date();
            const dueDate = currentDate && currentDate.getFullYear().toString() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');
            setDueDate(dueDate);
        } else if (rec.frequency === "monthly") {
            const currentDate = new Date();
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 8) + day;
            setDueDate(dueDate);
        } else {
            const currentDate = new Date();
            const month = rec.month.toString().padStart(2, '0');
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 5) + month + '-' + day;
            setDueDate(dueDate);
        }
    }


    const handleTextChange = (text, index) => {
        var newArray = additionalCharges;
        newArray[index].name = text;

        setAdditionalCharges([...newArray])
    }
    const handleAmountChange = (amount, index) => {

        var newArray = additionalCharges;
        newArray[index].amount = amount;

        setAdditionalCharges([...newArray])
        setTotalAmount(additionalCharges.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.amount)
        }, 0))
    }
    const handleDelete = (i) => {
        const deleteVal = [...additionalCharges]
        deleteVal.splice(i, 1)
        setAdditionalCharges(deleteVal)
    }

    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closeAddPurchasePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >




                <div className="purchasepopup_layout" >
                    <div id="pop_up_purchase">
                        <img id="pop_up_bg_pop__ourchase__bg" src="../AddPurchase_Popup/pop_up_bg_pop__ourchase__bg.png" srcset="../AddPurchase_Popup/pop_up_bg_pop__ourchase__bg.png 1x" />

                        <svg onClick={() => { props.closeAddPurchasePopup(false); clearData() }} class="Icon_ionic-mpoppurchase" viewBox="6.75 6.75 18 18">
                            <path id="Icon_ionic-mpoppurchase" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                        <div id="add_purchases_lbl_purchase_pop">
                            <span>{props.display === "Edit" ? "Edit Purchase" : "Add Purchase"}</span>
                        </div>
                        <button id="add_purch_btn_grp">
                            <svg class="pop_up_purch_btn">
                                <rect id="pop_up_purch_btn" rx="6" ry="6" x="0" y="0" width="167" height="44">
                                </rect>
                            </svg>
                            <div id="Add_purch_pop_up">
                                <span tabIndex="19" onClick={() => { props.display === "Edit" ? updatePurchase() : addPurchase() }}>{props.display === "Edit" ? "Edit Purchase" : "Add Purchase"}</span>
                            </div>
                        </button>
                        <div id="add_purchase_scroll_pop_up">
                            <div id="Group_54">
                                <div id="product_lbl_purchase_pop_up">
                                    <span>Product</span>
                                </div>
                                <input type="text" tabIndex="1" autoFocus placeholder="Product" className="product_input_purchase_pop_up" value={product} onChange={(e) => setProduct(e.target.value)} />
                            </div>
                            <div id="purchase_date_lbl_purchase_pop">
                                <span>Purchase Date</span>
                            </div>
                            <input type="date" tabIndex="3" className="expiry_date_input_purchase_pop" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} />
                            <div id="expiry_date_purchase_pop_up">
                                <span>Expiry Date</span>
                            </div>
                            <input type="date" tabIndex="2" className="purchase_date_purchase_pop_up" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                            <div id="total_amount_lbl_purchase_pop_" style={{ top: additionalCharges.length < 3 ? 285 + (20 * (additionalCharges.length + 1)) + 'px' : "385px" }}>
                                <span>Total Amount</span>
                            </div>
                            <input placeholder="Total Amount" tabIndex="9" class="total_amount_input_purchase_po" style={{ top: additionalCharges.length < 3 ? 310 + (20 * (additionalCharges.length + 1)) + 'px' : "406px" }}
                                value={additionalCharges.reduce((accumulator, currentValue) => {
                                    return accumulator + parseInt(currentValue.amount)
                                }, 0)}
                            />
                            <div id="status_lbl_purchase_pop_up" style={{ top: additionalCharges.length < 3 ? 512 + (20 * (additionalCharges.length + 1)) + 'px' : "599px" }}>
                                <span>Status</span>
                            </div>
                            <select name="status" tabIndex="16" className="status_input_purchase_pop_up" style={{ top: additionalCharges.length < 3 ? 535 + (20 * (additionalCharges.length + 1)) + 'px' : "620px" }} value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="" >--- Select ---</option>
                                <option value="Draft" >Draft</option>
                                <option value="Approved" >Approved</option>
                                <option value="On Hold" >On Hold</option>
                                <option value="Paid">Paid</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Skipped">Skipped</option>
                            </select>
                            <div id="contact_staff_lbl_purchase_pop" style={{ top: additionalCharges.length < 3 ? 365 + (20 * (additionalCharges.length + 1)) + 'px' : "458px" }}>
                                <span>Contact Staff</span>
                            </div>
                            <input placeholder="Contact Staff"  tabIndex="11" class="contact_staff_input_purchase_p" style={{ top: additionalCharges.length < 3 ? 389 + (20 * (additionalCharges.length + 1)) + 'px' : "480px" }} value={contactStaff} onChange={(e) => setContactStaff(e.target.value)} />
                            <div id="staff_number_lbl_purchase_pop_" style={{ top: additionalCharges.length < 3 ? 365 + (20 * (additionalCharges.length + 1)) + 'px' : "458px" }}>
                                <span>Staff Mobile</span>
                            </div>
                            <input placeholder="Staff Mobile" tabIndex="12" class="staff_number_input_purchase_po" style={{ top: additionalCharges.length < 3 ? 389 + (20 * (additionalCharges.length + 1)) + 'px' : "480px" }} value={staffNumber} onChange={(e) => setStaffNumber(e.target.value)} />
                            <div id="approved_by_lbl_purchase_pop_u" style={{ top: additionalCharges.length < 3 ? 438 + (20 * (additionalCharges.length + 1)) + 'px' : "530px" }}>
                                <span>Approved By</span>
                            </div>
                            <select name="approver" tabIndex="13" className="approved_by_inpout_purchase_po" style={{ top: additionalCharges.length < 3 ? 462 + (20 * (additionalCharges.length + 1)) + 'px' : "553px" }} value={approvedBy} onChange={(e) => setApprovedBy(e.target.value)}>
                                <option value="" >--- Select ---</option>
                                {userData.filter(v =>v.userDepartments === "Administration" || v.userDepartments === "Directors").map((rec) => (
                                    <option value={rec.name} >{rec.name}</option>
                                ))}
                            </select>
                            <div id="purchased_by_lbl_purchase_pop_" style={{ top: additionalCharges.length < 3 ? 438 + (20 * (additionalCharges.length + 1)) + 'px' : "530px" }}>
                                <span>Purchased By</span>
                            </div>
                             <input type="text" tabIndex="14" placeholder="Purchased By" className="purchase_by_input_purchase_pop" style={{ top: additionalCharges.length < 3 ? 462 + (20 * (additionalCharges.length + 1)) + 'px' : "553px" }} value={purchasedBy} onChange={(e) => setPurchasedBy(e.target.value)}></input>
                            <div id="approved_by_lbl_purchase_pop_u_d" style={{ top: additionalCharges.length < 3 ? 512 + (20 * (additionalCharges.length + 1)) + 'px' : "599px" }}>
                                <span>Due Date</span>
                            </div>
                            <input type="date" tabIndex="15" class="approved_by_inpout_purchase_du" style={{ top: additionalCharges.length < 3 ? 535 + (20 * (additionalCharges.length + 1)) + 'px' : "620px" }} value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                            <div id="catrgory_lbl_purchase_pop_up">
                                <span>Category</span>
                            </div>
                            <input placeholder="Category" tabIndex="4" class="category_input_purchase_pop_up" value={category} onChange={(e) => setCategory(e.target.value)} />
                            <div id="item_cost_lbl_purchase_pop_up">
                                <span>Item Cost</span>
                            </div>
                            <input type="text" placeholder="Item Cost" tabIndex="5" className="item_cost_input_purchase_pop_u" value={itemCost} onChange={(e) => setItemCost(e.target.value)} />
                            <div id="purchased_from_lbl_purchase_po" style={{ top: additionalCharges.length < 3 ? 285 + (20 * (additionalCharges.length + 1)) + 'px' : "385px" }}>
                                <span>Purchased From</span>
                            </div>
                            <select name="vendor" tabIndex="10" className="purchased_from_input_purchase_" style={{ top: additionalCharges.length < 3 ? 310 + (20 * (additionalCharges.length + 1)) + 'px' : "406px" }}
                                value={merchantId}
                                onChange={(e) => {
                                    setMerchantId(e.target.value);
                                    props.display === "Add" && setDefaultDate(merchantData.filter((item) => item._id === e.target.value)[0])
                                }}>
                                    
                            <option value="" >--- Select ---</option>
                                {merchantData.map((rec) => (
                                    <option value={rec._id} >{rec.name}</option>
                                ))}
                            </select>
                            <div id="purchases_pop_up_grp" >
                                <svg class="add_charges_bdr_pop_up" >
                                    <rect id="add_charges_bdr_pop_up" rx="0" ry="0" x="0" y="0" width="295" height={additionalCharges.length < 3 ? 50 + (40 * additionalCharges.length) : 155}>
                                    </rect>
                                </svg>
                                <div id="add_charges_lbl_pfp_pop_up_inv">
                                    <span>Additional Charges ({additionalCharges.reduce((accumulator, currentValue) => {
                                        return accumulator + parseInt(currentValue.amount)
                                    }, 0)}) </span>
                                </div>
                                <div style={{ height: (40 * additionalCharges.length) + "px", overflowY: "scroll", maxHeight: "115px", top: "30px", position: "relative" }}>
                                    {
                                        <ol>
                                            {additionalCharges.map((element, i) => {
                                                return (
                                                    <div>
                                                        <input tabIndex="7" onChange={(e) => handleTextChange(e.target.value, i)} placeholder="To Pay" class="additional_charges_text_enter" value={element.name} type="text" />
                                                        <input tabIndex="8" onChange={(e) => handleAmountChange((e.target.value).length > 0 ? parseInt(e.target.value) : 0, i)} placeholder="₹" class="amount_input_pfp" value={element.amount} type="number" />
                                                        <img onClick={() => handleDelete(i)} id="minus_remove_btn_pop" src="../AddPurchase_Popup/minus_remove_btn_pop.png" srcset="../AddPurchase_Popup/minus_remove_btn_pop.png 1x" />
                                                    </div>
                                                )
                                            })}
                                        </ol>
                                    }
                                </div>


                                <img tabIndex="6" onClick={() => setAdditionalCharges([...additionalCharges, { name: "", amount: 0 }])} id="add-button_purchases_pop_up" src="../AddPurchase_Popup/add-button_purchases_pop_up.png" srcset="../AddPurchase_Popup/add-button_purchases_pop_up.png 1x" />
                            </div>
                            <div id="allocated_to_lbl_purchase_pop_" style={{ top: additionalCharges.length < 3 ? 585 + (20 * (additionalCharges.length + 1)) + 'px' : "669px" }}>
                                <span>Allocated To</span>
                            </div>
                            <input placeholder="Allocated To" tabIndex="17" class="allocated_to_inpout_purchase_p" style={{ top: additionalCharges.length < 3 ? 608 + (20 * (additionalCharges.length + 1)) + 'px' : "690px" }} value={allocatedTo} onChange={(e) => setAllocatedTo(e.target.value)} />
                            <div id="allocated_on_lbl_purchase_pop_" style={{ top: additionalCharges.length < 3 ? 585 + (20 * (additionalCharges.length + 1)) + 'px' : "669px" }}>
                                <span>Allocated On</span>
                            </div>
                            <input type="date" placeholder="Allocated On" tabIndex="18" class="allocated_on_inpout_purchase_p" style={{ top: additionalCharges.length < 3 ? 608 + (20 * (additionalCharges.length + 1)) + 'px' : "690px" }} value={allocatedOn} onChange={(e) => setAllocatedOn(e.target.value)} />

                        </div>
                        <div id="upload_invoice_lbl_purchase_po">
                            <span>Upload Invoice</span>
                        </div>
                        <div id="Icon_feather-upload">
                            <svg class="invoice_down_" viewBox="4.5 22.5 23.94 9">
                                <path id="invoice_down_" d="M 28.44000053405762 22.5 L 28.44000053405762 28.5 C 28.44000053405762 30.1568546295166 27.24907684326172 31.5 25.78000068664551 31.5 L 7.159999847412109 31.5 C 5.690921783447266 31.5 4.5 30.1568546295166 4.5 28.5 L 4.5 22.5">
                                </path>
                            </svg>
                            <svg class="invoice_arrow_diagnal" viewBox="10.5 4.5 15 7.5">
                                <path id="invoice_arrow_diagnal" d="M 25.5 12 L 18 4.5 L 10.5 12">
                                </path>
                            </svg>
                            <svg class="invoice_line_arrow" viewBox="18 4.5 3 18">
                                <path id="invoice_line_arrow" d="M 18 4.5 L 18 22.5">
                                </path>
                            </svg>
                        </div>
                        <div id="Icon_feather-download">
                            <svg class="arrow_bottom" viewBox="4.5 22.5 23.941 9">
                                <path id="arrow_bottom" d="M 28.44140625 22.5 L 28.44140625 28.5 C 28.44140625 30.1568546295166 27.25041389465332 31.5 25.78125 31.5 L 7.16015625 31.5 C 5.69099235534668 31.5 4.5 30.1568546295166 4.5 28.5 L 4.5 22.5">
                                </path>
                            </svg>
                            <svg class="arrow_angle" viewBox="10.5 15 15 7.5">
                                <path id="arrow_angle" d="M 10.5 15 L 18 22.5 L 25.5 15">
                                </path>
                            </svg>
                            <svg class="arrow_line" viewBox="18 4.5 3 18">
                                <path id="arrow_line" d="M 18 22.5 L 18 4.5">
                                </path>
                            </svg>
                        </div>
                        <div id="download_invoice_lbl_purchase_">
                            <span>Download Invoice</span>
                        </div>

                    </div>
                </div>

            </Modal>

        </>
    )
}



