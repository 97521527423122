import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Mob/dashboard_mob.css"
import { UserContext } from "../UserContext";
import { StockDashboard_Mob } from './StockDashboard_Mob'
import { ExpenceAnalytics_Mob } from './ExpenceAnalytics_Mob'
import { DonutChart1 } from '../Pc/DonutChart1';
import { DonutChart2 } from '../Pc/DonutChart2';

export const Dashboard_Mob = () => {

    const { user, setUser } = useContext(UserContext);
    const [selectedDuration, setSelectedDuration] = useState("month");
    const [dateRange, setDateRange] = useState("");
    const [salaryData, setSalaryData] = useState([]);
    const [billData, setBillData] = useState([]);
    const [taxData, setTaxData] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [purchaseChartData, setPurchaseChartData] = useState([]);

    const [totalExpense, setTotalExpense] = useState(0);
    const [incomeLoss, setIncomeLoss] = useState(0);
    const [percentage, setPercentage] = useState(0);






    useEffect(() => {

        if (user && user.userDepartments === "Human Resource") {
            GetSalary()
        }
        else if (user && user.userDepartments === "Administration") {
            GetBill(); GetPurchase()
        }
        else if (user && user.userDepartments === "Accounts") {
            GetSalary(); GetTax(); GetBill(); GetPurchase(); GetInvoice()
        }

        else if (user && user.userDepartments === "Management") {
            GetInvoice(); GetPurchase()
        }
        else if (user && user.userDepartments === "Directors") {
            GetSalary(); GetTax(); GetBill(); GetPurchase(); GetInvoice()
        }

        GetPurchaseChart()

    }, [user])



    useEffect(() => {
        if (selectedDuration) {
            
            setDateRange(getRange(selectedDuration));
          
            if (user) {
                if (user.userDepartments === "Human Resource") {
                    GetSalary()
                }
                else if (user.userDepartments === "Administration") {
                    GetBill(); GetPurchase()
                }
                else if (user.userDepartments === "Accounts") {
                    GetSalary(); GetTax(); GetBill(); GetPurchase(); GetInvoice()
                }

                else if (user.userDepartments === "Management") {
                    GetInvoice(); GetPurchase()
                }
                else if (user.userDepartments === "Directors") {
                    GetSalary(); GetTax(); GetBill(); GetPurchase(); GetInvoice()
                }
            }
        }
    }, [selectedDuration, user])


    const getMonthText = (monthNumber) => {
        switch (monthNumber) {
            case 0:
                return "Jan"
                break;
            case 1:
                return "Feb"
                break;
            case 2:
                return "Mar"
                break;
            case 3:
                return "Apr"
                break;
            case 4:
                return "May"
                break;
            case 5:
                return "Jun"
                break;
            case 6:
                return "Jul"
                break;
            case 7:
                return "Aug"
                break;
            case 8:
                return "Sep"
                break;
            case 9:
                return "Oct"
                break;
            case 10:
                return "Nov"
                break;
            case 11:
                return "Dec"
                break;
            default:
                break;
        }
    }


    const getRange = (range) => {
        const curYear = (new Date().getFullYear()).toString();
        const prvYear = (new Date().getFullYear()-1).toString();
        const nxtYear = (new Date().getFullYear()+1).toString();
        switch (new Date().getMonth()) {
            case 0:
                return range === "month" ? "01 Jan "+curYear+" - "+"31 Jan "+curYear :range === "quarter" ? "01 Jan "+curYear+" - "+"31 Mar "+curYear: range === "halfyear" ? "01 Oct "+prvYear+" - "+"31 Mar "+curYear:"01 Apr "+prvYear+" - "+"31 Mar "+curYear;
                break;
            case 1:
                return range === "month" ? "01 Feb "+curYear+" - "+"28 Feb "+curYear :range === "quarter" ? "01 Jan "+curYear+" - "+"31 Mar "+curYear: range === "halfyear" ? "01 Oct "+prvYear+" - "+"31 Mar "+curYear:"01 Apr "+prvYear+" - "+"31 Mar "+curYear;
                break;
            case 2:
                return range === "month" ? "01 Mar "+curYear+" - "+"31 Mar "+curYear :range === "quarter" ? "01 Jan "+curYear+" - "+"31 Mar "+curYear: range === "halfyear" ? "01 Oct "+prvYear+" - "+"31 Mar "+curYear:"01 Apr "+prvYear+" - "+"31 Mar "+curYear;
                break;
            case 3:
                return range === "month" ? "01 Apr "+curYear+" - "+"30 Apr "+curYear :range === "quarter" ? "01 Apr "+curYear+" - "+"30 Jun "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 4:
                return range === "month" ? "01 May "+curYear+" - "+"31 May "+curYear :range === "quarter" ? "01 Apr "+curYear+" - "+"30 Jun "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 5:
                return range === "month" ? "01 Jun "+curYear+" - "+"30 Jun "+curYear :range === "quarter" ? "01 Apr "+curYear+" - "+"30 Jun "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 6:
                return range === "month" ? "01 Jul "+curYear+" - "+"31 Jul "+curYear :range === "quarter" ? "01 Jul "+curYear+" - "+"30 Sep "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 7:
                return range === "month" ? "01 Aug "+curYear+" - "+"31 Aug "+curYear :range === "quarter" ? "01 Jul "+curYear+" - "+"30 Sep "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 8:
                return range === "month" ? "01 Sep "+curYear+" - "+"30 Sep "+curYear :range === "quarter" ? "01 Jul "+curYear+" - "+"30 Sep "+curYear: range === "halfyear" ? "01 Apr "+curYear+" - "+"30 Sep "+curYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 9:
                return range === "month" ? "01 Oct "+curYear+" - "+"31 Oct "+curYear :range === "quarter" ? "01 Oct "+curYear+" - "+"31 Dec "+curYear: range === "halfyear" ? "01 Oct "+curYear+" - "+"31 Mar "+nxtYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 10:
                return range === "month" ? "01 Nov "+curYear+" - "+"30 Nov "+curYear :range === "quarter" ? "01 Oct "+curYear+" - "+"31 Dec "+curYear: range === "halfyear" ?  "01 Oct "+curYear+" - "+"31 Mar "+nxtYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            case 11:
                return range === "month" ? "01 Dec "+curYear+" - "+"31 Dec "+curYear :range === "quarter" ? "01 Oct "+curYear+" - "+"31 Dec "+curYear: range === "halfyear" ?  "01 Oct "+curYear+" - "+"31 Mar "+nxtYear:"01 Apr "+curYear+" - "+"31 Mar "+nxtYear;
                break;
            default:
                break;
        }
    }

    //Salary total
    const GetSalary = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/salary/getsalaries";
        let apiParams = {
            method: "GET",
            headers: {
                selectedduration: selectedDuration,
            }

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setSalaryData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    //Bills Total
    const GetBill = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/bill/getbills";
        let apiParams = {
            method: "GET",
            headers: {
                selectedduration: selectedDuration,
            }
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setBillData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    //Taxes Total
    const GetTax = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/tax/gettaxes";
        let apiParams = {
            method: "GET",
            headers: {
                selectedduration: selectedDuration,
            }
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setTaxData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    //Purchases total
    const GetPurchase = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/purchase/getpurchases";
        let apiParams = {
            method: "GET",
            headers: {
                selectedduration: selectedDuration,
            }
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPurchaseData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    //Invoices total
    const GetInvoice = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/invoice/getinvoices";
        let apiParams = {
            method: "GET",
            headers: {
                selectedduration: selectedDuration,
            }
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setInvoiceData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    //Purchases chart total
    const GetPurchaseChart = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/purchase/getcpurchases";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPurchaseChartData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    let calculateExpenses = () => {
        let totalExpencess =
            salaryData.reduce((a, v) => a + v.finalAmount, 0) +
            billData.reduce((a, v) => a + v.billAmount, 0) +
            taxData.reduce((a, v) => a + v.taxAmount, 0) +
            purchaseData.reduce((a, v) => a + v.itemCost, 0);
        setTotalExpense(totalExpencess);
    };

    let calculateIncomeLoss = () => {
        let incomeLosses =
            invoiceData.reduce((a, v) => a + v.finalAmount, 0) - totalExpense;
        setIncomeLoss(incomeLosses);
    };

    let calculatePercentage = () => {
        let percentagess = isNaN((incomeLoss / totalExpense) * 100)
            ? 0
            : (incomeLoss / totalExpense) * 100;
        setPercentage(percentagess);
    };

    useEffect(() => {
        calculateExpenses();
        calculateIncomeLoss();
        calculatePercentage();
    }, [
        salaryData,
        billData,
        taxData,
        purchaseData,
        invoiceData,
        totalExpense,
        incomeLoss,
    ]);

    //Chart 1 starts
    let statusPaid = 'Paid';

    let filteredSalaryDataPaid = salaryData.filter(v => v.status === statusPaid);
    let filteredBillDataPaid = billData.filter(v => v.status === statusPaid);
    let filteredTaxDataPaid = taxData.filter(v => v.status === statusPaid);
    let filteredPurchaseDataPaid = purchaseData.filter(v => v.status === statusPaid);

    let totalExpenceStatusPaid = filteredSalaryDataPaid.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataPaid.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataPaid.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataPaid.reduce((a, v) => a + v.itemCost, 0);

    let percentagePaid = isNaN((totalExpenceStatusPaid / totalExpense) * 100) ? 0 : ((totalExpenceStatusPaid / totalExpense) * 100);


    let statusDraft = 'Draft';

    let filteredSalaryDataDraft = salaryData.filter(v => v.status === statusDraft);
    let filteredBillDataDraft = billData.filter(v => v.status === statusDraft);
    let filteredTaxDataDraft = taxData.filter(v => v.status === statusDraft);
    let filteredPurchaseDataDraft = purchaseData.filter(v => v.status === statusDraft);

    let totalExpenceStatusDraft = filteredSalaryDataDraft.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataDraft.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataDraft.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataDraft.reduce((a, v) => a + v.itemCost, 0);

    let percentageDraft = isNaN((totalExpenceStatusDraft / totalExpense) * 100) ? 0 : ((totalExpenceStatusDraft / totalExpense) * 100);


    let statusOnHold = 'On Hold';

    let filteredSalaryDataOnHold = salaryData.filter(v => v.status === statusOnHold);
    let filteredBillDataOnHold = billData.filter(v => v.status === statusOnHold);
    let filteredTaxDataOnHold = taxData.filter(v => v.status === statusOnHold);
    let filteredPurchaseDataOnHold = purchaseData.filter(v => v.status === statusOnHold);

    let totalExpenceStatusOnHold = filteredSalaryDataOnHold.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataOnHold.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataOnHold.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataOnHold.reduce((a, v) => a + v.itemCost, 0);

    let percentageOnHold = isNaN((totalExpenceStatusOnHold / totalExpense) * 100) ? 0 : ((totalExpenceStatusOnHold / totalExpense) * 100);



    let statusApproved = 'Approved';

    let filteredSalaryDataApproved = salaryData.filter(v => v.status === statusApproved);
    let filteredBillDataApproved = billData.filter(v => v.status === statusApproved);
    let filteredTaxDataApproved = taxData.filter(v => v.status === statusApproved);
    let filteredPurchaseDataApproved = purchaseData.filter(v => v.status === statusApproved);

    let totalExpenceStatusApproved = filteredSalaryDataApproved.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataApproved.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataApproved.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataApproved.reduce((a, v) => a + v.itemCost, 0);

    let percentageApproved = isNaN((totalExpenceStatusApproved / totalExpense) * 100) ? 0 : ((totalExpenceStatusApproved / totalExpense) * 100);


    let statusCancelled = 'Cancelled';

    let filteredSalaryDataCancelled = salaryData.filter(v => v.status === statusCancelled);
    let filteredBillDataCancelled = billData.filter(v => v.status === statusCancelled);
    let filteredTaxDataCancelled = taxData.filter(v => v.status === statusCancelled);
    let filteredPurchaseDataCancelled = purchaseData.filter(v => v.status === statusCancelled);

    let totalExpenceStatusCancelled = filteredSalaryDataCancelled.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataCancelled.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataCancelled.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataCancelled.reduce((a, v) => a + v.itemCost, 0);

    let percentageCancelled = isNaN((totalExpenceStatusCancelled / totalExpense) * 100) ? 0 : ((totalExpenceStatusCancelled / totalExpense) * 100);



    let statusSkipped = 'Skipped';

    let filteredSalaryDataSkipped = salaryData.filter(v => v.status === statusSkipped);
    let filteredBillDataSkipped = billData.filter(v => v.status === statusSkipped);
    let filteredTaxDataSkipped = taxData.filter(v => v.status === statusSkipped);
    let filteredPurchaseDataSkipped = purchaseData.filter(v => v.status === statusSkipped);

    let totalExpenceStatusSkipped = filteredSalaryDataSkipped.reduce((a, v) => a + v.finalAmount, 0) +
        filteredBillDataSkipped.reduce((a, v) => a + v.billAmount, 0) +
        filteredTaxDataSkipped.reduce((a, v) => a + v.taxAmount, 0) +
        filteredPurchaseDataSkipped.reduce((a, v) => a + v.itemCost, 0);

    let percentageSkipped = isNaN((totalExpenceStatusSkipped / totalExpense) * 100) ? 0 : ((totalExpenceStatusSkipped / totalExpense) * 100);
    //chart1 ends

    //chart2 starts
    let totalPurchaseExpence = purchaseData.reduce((a, v) => a = a + v.itemCost, 0)

    let nearExpiry = purchaseChartData && purchaseChartData.nearExpiry && purchaseChartData.nearExpiry.length > 0 ? purchaseChartData.nearExpiry[0].totalItemCost : 0;
    let inUse = purchaseChartData && purchaseChartData.inUse && purchaseChartData.inUse.length > 0 ? purchaseChartData.inUse[0].totalItemCost : 0;
    let inStock = purchaseChartData && purchaseChartData.inStock && purchaseChartData.inStock.length > 0 ? purchaseChartData.inStock[0].totalItemCost : 0;
    let expired = purchaseChartData && purchaseChartData.expired && purchaseChartData.expired.length > 0 ? purchaseChartData.expired[0].totalItemCost : 0;


    let percentageNearExpiry = isNaN((nearExpiry / totalPurchaseExpence) * 100) ? 0 : ((nearExpiry / totalPurchaseExpence) * 100);
    let percentageInUse = isNaN((inUse / totalPurchaseExpence) * 100) ? 0 : ((inUse / totalPurchaseExpence) * 100);
    let percentageinStock = isNaN((inStock / totalPurchaseExpence) * 100) ? 0 : ((inStock / totalPurchaseExpence) * 100);
    let percentagexpired = isNaN((expired / totalPurchaseExpence) * 100) ? 0 : ((expired / totalPurchaseExpence) * 100);
    //chart2 ends



    return (
        <>
            <div id="dashboard_Mob">
                <img id="overall_bg_design_mob_dashboard" src="overall_bg_design_mob.png" srcset="overall_bg_design_mob.png 1x" />

                <div className='name_mob'>
                    <span id="Welcome_Mohammed_Affan__lbl_ex">Welcome, {user && user.name}</span>
                    <select class="filter_mob" value={selectedDuration} onChange={(e) => setSelectedDuration(e.target.value)}>
                        <option value="month">Month</option>
                        <option value="quarter">Quarter</option>
                        <option value="halfyear">Semi Annual</option>
                        <option value="year">Annual</option>
                    </select>
                </div>

                <div id="overall_grp_compo_mob">
                    <div id="Scroll_top_cards_mob_grp">
                        <div id="all_grp_card">
                            <div id="income_loss_card_grp_dashboard">
                                <svg class="income_loss_card">
                                    <rect id="income_loss_card" rx="10" ry="10" x="0" y="0" width="225" height="132">
                                    </rect>
                                </svg>
                            </div>
                            {
                                ((user && user.userDepartments === "Management") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors")) &&
                                <div id="total_revenue_grp_dashboardmob">
                                    <svg class="over_all_card_bdr_revenbue_das">
                                        <rect id="over_all_card_bdr_revenbue_das" rx="10" ry="10" x="0" y="0" width="225" height="132">
                                        </rect>
                                    </svg>
                                    <div id="top_img_total_dashboardmob">
                                        <div id="Total_Revenue_lbl_dashboardmob">
                                            <span>Total Invoice</span>
                                        </div>
                                        <svg class="bdr_img_revenue_dashboardmob">
                                            <rect id="bdr_img_revenue_dashboardmob" rx="7" ry="7" x="0" y="0" width="30" height="30">
                                            </rect>
                                        </svg>
                                        <img id="revenue_img_dashboardmob" src="../Dashboard/revenue_img_dash_pc.png" srcset="../Dashboard/revenue_img_dash_pc.png 1x" />

                                    </div>
                                    <div id="Column_Chart1top_dashboardmob">
                                        <svg class="graph_2_45_dashboardmob">
                                            <rect id="graph_2_45_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_12_dashboardmob">
                                            <rect id="graph_2_12_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="24.935">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_0_dashboardmob">
                                            <rect id="graph_2_0_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="21.818">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_9_dashboardmob">
                                            <rect id="graph_2_9_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_8_dashboardmob">
                                            <rect id="graph_2_8_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="20.571">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_7_dashboardmob">
                                            <rect id="graph_2_7_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_6_dashboardmob">
                                            <rect id="graph_2_6_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="18.701">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_5_dashboardmob">
                                            <rect id="graph_2_5_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="28.052">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_4_dashboardmob">
                                            <rect id="graph_2_4_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="37.403">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_3_dashboardmob">
                                            <rect id="graph_2_3_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_2_dashboardmob">
                                            <rect id="graph_2_2_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="27.429">
                                            </rect>
                                        </svg>
                                        <svg class="graph_2_1_dashboardmob">
                                            <rect id="graph_2_1_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="48">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Column_dashboardmob_1_shadow">
                                        <svg class="graph_1_21_dashboardmob">
                                            <rect id="graph_1_21_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_1_dashboardmob">
                                            <rect id="graph_1_1_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="24.935">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_0_dashboardmob">
                                            <rect id="graph_1_0_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="21.818">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_9_dashboardmob">
                                            <rect id="graph_1_9_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_8_dashboardmob">
                                            <rect id="graph_1_8_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="20.571">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_7_dashboardmob">
                                            <rect id="graph_1_7_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_6_dashboardmob">
                                            <rect id="graph_1_6_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="18.701">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_5_dashboardmob">
                                            <rect id="graph_1_5_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="28.052">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_4_dashboardmob">
                                            <rect id="graph_1_4_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="37.403">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_3_dashboardmob">
                                            <rect id="graph_1_3_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_2_dashboardmob">
                                            <rect id="graph_1_2_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="27.429">
                                            </rect>
                                        </svg>
                                        <svg class="graph_1_1_dashboardmob_eh">
                                            <rect id="graph_1_1_dashboardmob_eh" rx="0" ry="0" x="0" y="0" width="4.522" height="17.897">
                                            </rect>
                                        </svg>
                                    </div>
                                    <svg class="underline_revenuedashboardmob" viewBox="0 0 158 1">
                                        <path id="underline_revenuedashboardmob" d="M 0 0 L 158 0">
                                        </path>
                                    </svg>
                                    <div id="n_lbl_revenu_dashboardmob">
                                        <span>₹</span>
                                    </div>
                                    <div id="n_2345_lbl_reven_dashboardmob">
                                        <span>{invoiceData.reduce((a, v) => a = a + v.finalAmount, 0).toLocaleString()}</span>
                                    </div>
                                    <div id="dashboardmob_date_rdashboardmo">
                                        <span>{dateRange}</span>
                                    </div>
                                    {percentage <= 0 ?
                                        <svg class="Icon_awesom_dashboardmob_minus" viewBox="0.397 2.246 13.411 13.762">
                                            <path id="Icon_awesom_dashboardmob_minus" d="M 1.295708656311035 10.15739059448242 L 0.6138134598731995 9.475495338439941 C 0.3250830769538879 9.186763763427734 0.3250830769538879 8.719882011413574 0.6138134598731995 8.434222221374512 L 6.581932544708252 2.463032007217407 C 6.870662689208984 2.174301624298096 7.337545871734619 2.174301624298096 7.623205184936523 2.463032007217407 L 13.59132480621338 8.431150436401367 C 13.88005542755127 8.719882011413574 13.88005542755127 9.186763763427734 13.59132480621338 9.472423553466797 L 12.9094295501709 10.15431976318359 C 12.61762714385986 10.44612121582031 12.14152908325195 10.43997764587402 11.85587024688721 10.14203262329102 L 8.332744598388672 6.443826198577881 L 8.332744598388672 15.27160453796387 C 8.332744598388672 15.68012809753418 8.004083633422852 16.0087890625 7.595560550689697 16.0087890625 L 6.612648487091064 16.0087890625 C 6.204125881195068 16.0087890625 5.87546443939209 15.68012809753418 5.87546443939209 15.27160453796387 L 5.87546443939209 6.443826198577881 L 2.349267482757568 10.14510345458984 C 2.063608646392822 10.446120262146 1.587510585784912 10.45226383209229 1.295708537101746 10.15739059448242 Z">
                                            </path>
                                        </svg> :
                                        <svg class="Icon_awesom_dashboardmob_plus" viewBox="0.397 2.246 13.411 13.762">
                                            <path id="Icon_awesom_dashboardmob_plus" d="M 1.295708656311035 10.15739059448242 L 0.6138134598731995 9.475495338439941 C 0.3250830769538879 9.186763763427734 0.3250830769538879 8.719882011413574 0.6138134598731995 8.434222221374512 L 6.581932544708252 2.463032007217407 C 6.870662689208984 2.174301624298096 7.337545871734619 2.174301624298096 7.623205184936523 2.463032007217407 L 13.59132480621338 8.431150436401367 C 13.88005542755127 8.719882011413574 13.88005542755127 9.186763763427734 13.59132480621338 9.472423553466797 L 12.9094295501709 10.15431976318359 C 12.61762714385986 10.44612121582031 12.14152908325195 10.43997764587402 11.85587024688721 10.14203262329102 L 8.332744598388672 6.443826198577881 L 8.332744598388672 15.27160453796387 C 8.332744598388672 15.68012809753418 8.004083633422852 16.0087890625 7.595560550689697 16.0087890625 L 6.612648487091064 16.0087890625 C 6.204125881195068 16.0087890625 5.87546443939209 15.68012809753418 5.87546443939209 15.27160453796387 L 5.87546443939209 6.443826198577881 L 2.349267482757568 10.14510345458984 C 2.063608646392822 10.446120262146 1.587510585784912 10.45226383209229 1.295708537101746 10.15739059448242 Z">
                                            </path>
                                        </svg>}
                                    <div id="n_2_ups_and_dashboardmob" style={{ color: percentage > 0 ? "#2ECC71" : "#E74C3C" }}>
                                        <span>{percentage.toFixed(2)}</span>
                                    </div>
                                </div>
                            }
                            <div id="total_expense_carddashboardmob">
                                <svg class="total_expense_dashboardmob">
                                    <rect id="total_expense_dashboardmob" rx="10" ry="10" x="0" y="0" width="225" height="132">
                                    </rect>
                                </svg>
                                <div id="Total_Expensedashboardmob">
                                    {(user && user.userDepartments === "Accounts") &&
                                        <span>Total</span>}
                                    {(user && user.userDepartments === "Human Resource") &&
                                        <span>Total Salaries</span>}
                                    {(user && user.userDepartments === "Management") &&
                                        <span>Total Expence</span>}
                                    {(user && user.userDepartments === "Directors") &&
                                        <span>Total</span>}
                                    {(user && user.userDepartments === "Administration") &&
                                        <span>Total Expence</span>}
                                </div>
                                <div id="card_grp_imgdashboardmob">
                                    <svg class="bdr_total_expense_dashboardmob">
                                        <rect id="bdr_total_expense_dashboardmob" rx="7" ry="7" x="0" y="0" width="30" height="30">
                                        </rect>
                                    </svg>
                                    <img id="budget_imgdashboardmob" src="../Dashboard/budget_img_dash_pc.png" srcset="../Dashboard/budget_img_dash_pc.png 1x" />

                                </div>
                                <div id="Colu_dashboardmob_upper_total_">
                                    <svg class="top_graph_12_dashboardmob">
                                        <rect id="top_graph_12_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_11_dashboardmob">
                                        <rect id="top_graph_11_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="24.935">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_0_dashboardmob">
                                        <rect id="top_graph_0_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="21.818">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_9_dashboardmob">
                                        <rect id="top_graph_9_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_8_dashboardmob">
                                        <rect id="top_graph_8_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="20.571">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_7_dashboardmob">
                                        <rect id="top_graph_7_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_6_dashboardmob">
                                        <rect id="top_graph_6_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="18.701">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_5_dashboardmob">
                                        <rect id="top_graph_5_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="28.052">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_4_dashboardmob">
                                        <rect id="top_graph_4_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="37.403">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_3_dashboardmob">
                                        <rect id="top_graph_3_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_2_dashboardmob">
                                        <rect id="top_graph_2_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="27.429">
                                        </rect>
                                    </svg>
                                    <svg class="top_graph_1_dashboardmob">
                                        <rect id="top_graph_1_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="48">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="dashboardmobtotal_expense_grp">
                                    <svg class="shadow_12_dashboardmob">
                                        <rect id="shadow_12_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_11_dashboardmob">
                                        <rect id="shadow_11_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="24.935">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_0dashboardmob">
                                        <rect id="shadow_0dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="21.818">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_9_dashboardmob">
                                        <rect id="shadow_9_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="9.351">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_8_dashboardmob">
                                        <rect id="shadow_8_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="20.571">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_7_dashboardmob">
                                        <rect id="shadow_7_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_6_dashboardmob">
                                        <rect id="shadow_6_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="18.701">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_5_dashboardmob">
                                        <rect id="shadow_5_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="28.052">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_4_dashboardmob">
                                        <rect id="shadow_4_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="37.403">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_3_dashboardmob">
                                        <rect id="shadow_3_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="34.286">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_2_dashboardmob">
                                        <rect id="shadow_2_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="27.429">
                                        </rect>
                                    </svg>
                                    <svg class="shadow_1_dashboardmob">
                                        <rect id="shadow_1_dashboardmob" rx="0" ry="0" x="0" y="0" width="4.522" height="17.897">
                                        </rect>
                                    </svg>
                                </div>
                                <svg class="border_linevdashboardmob" viewBox="0 0 158 1">
                                    <path id="border_linevdashboardmob" d="M 0 0 L 158 0">
                                    </path>
                                </svg>
                                <div id="n_lbl_dashboardmob">
                                    <span>₹</span>
                                </div>
                                <div id="n_2345_moneydashboardmob">
                                    <span>{totalExpense.toLocaleString()}</span>
                                </div>
                                <div id="n__Jan_2023_-_1_Jan_2024_dashb">
                                    <span>{dateRange}</span>
                                </div>
                            </div>
                            <div id="Income__Loss_lbl_lbl_dashboard">
                                <span>Income / Loss</span>
                            </div>
                            <svg class="income_line_dashboardmob" viewBox="0 0 158 1">
                                <path id="income_line_dashboardmob" d="M 0 0 L 158 0">
                                </path>
                            </svg>
                            <div id="n_10000000_lbldashboardmob" style={{ color: incomeLoss > 0 ? "#2ECC71" : "#E74C3C" }}>
                                <span>₹ {incomeLoss.toLocaleString()}</span>
                            </div>
                            <svg class="money_income_bdr_dashboardmob">
                                <rect id="money_income_bdr_dashboardmob" rx="7" ry="7" x="0" y="0" width="30" height="30">
                                </rect>
                            </svg>
                            <img id="money-bag_2_img_lbl_dashboardm" src="../Dashboard/money-bag_2_img_lbl_dashboard_.png" srcset="../Dashboard/money-bag_2_img_lbl_dashboard_.png 1x" />

                        </div>
                    </div>
                    <div id="chart_overall_grp_mob">
                        <div id="expne_all_grp_expense_mob">
                            <div id="pie_chart_expense_mob">
                                <DonutChart1 data={[
                                    { value: percentageCancelled, color: 'rgba(153,153,153,1)' },
                                    { value: percentageDraft, color: '#c088d6' },
                                    { value: percentagePaid, color: 'rgba(106,169,255,1)' },
                                    { value: percentageOnHold, color: 'rgba(255,153,0,1)' },
                                    { value: percentageApproved, color: '#fc8787' },
                                    { value: percentageSkipped, color: 'rgba(255,0,0,1)' },
                                ]} />
                            </div>
                            <div id="Expense_lbl_expense_mob">
                                <span>Expense</span>
                            </div>
                            <div id="Unassigned_lbl_client_dash_mob">
                                <span>Draft</span>
                            </div>
                            <svg class="unassigned_grey_dash_mob">
                                <rect id="unassigned_grey_dash_mob" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                            <div id="Assigned_lbl_client_dash_mob">
                                <span>Approved</span>
                            </div>
                            <svg class="yellow_assigned_client_dash_mo">
                                <rect id="yellow_assigned_client_dash_mo" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                            <div id="Work_in_progress_client_dash_m">
                                <span>Overdue</span>
                            </div>
                            <svg class="blue_work_in_progress_dash_mob">
                                <rect id="blue_work_in_progress_dash_mob" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                            <div id="Completed_lbl_client_dash_mob">
                                <span>Paid</span>
                            </div>
                            <svg class="completed_greeen_dash_mob">
                                <rect id="completed_greeen_dash_mob" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                            <div id="Cancelled_lbl_client_dash_mob">
                                <span>Cancelled</span>
                            </div>
                            <svg class="red_cancelled_client_dash_mob">
                                <rect id="red_cancelled_client_dash_mob" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                            <div id="On_Hold_lbl_client_dash_mob">
                                <span>On Hold</span>
                            </div>
                            <svg class="orange_on_hold_dash_mob">
                                <rect id="orange_on_hold_dash_mob" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                </rect>
                            </svg>
                        </div>
                        {
                            ((user && user.userDepartments === "Administration") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors") || (user && user.userDepartments === "Management")) &&
                            <div id="stoc_k_grp_EMoinMobTask_Page_m">
                                <div id="pie_chart_dash_pc">
                                    <DonutChart2
                                        data1={[
                                            { value: percentagexpired, color: "rgba(153,153,153,1)" },
                                            { value: percentageInUse, color: "#fc8787" },
                                            { value: percentageinStock, color: "rgba(106,169,255,1)" },
                                            { value: percentageNearExpiry, color: "rgba(255,0,0,1)" },
                                        ]}
                                    />
                                </div>
                                <div id="Stock_Analytics_lbl_dash_pc">
                                    <span>Stock Analytics</span>
                                </div>
                                <div id="Unassigned_lbl_client_dash_mob_gc">
                                    <span>Near Expiry</span>
                                </div>
                                <svg class="unassigned_grey_dash_mob_gd">
                                    <rect id="unassigned_grey_dash_mob_gd" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                    </rect>
                                </svg>
                                <div id="Cancelled_lbl_client_dash_mob_ge">
                                    <span>In Stock</span>
                                </div>
                                <svg class="red_cancelled_client_dash_mob_gf">
                                    <rect id="red_cancelled_client_dash_mob_gf" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                    </rect>
                                </svg>
                                <div id="On_Hold_lbl_client_dash_mob_gg">
                                    <span>In Use</span>
                                </div>
                                <svg class="orange_on_hold_dash_mob_gh">
                                    <rect id="orange_on_hold_dash_mob_gh" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                    </rect>
                                </svg>
                                <div id="On_Hold_lbl_client_dash_mob_gi">
                                    <span>Expired</span>
                                </div>
                                <svg class="orange_on_hold_dash_mob_gj">
                                    <rect id="orange_on_hold_dash_mob_gj" rx="0" ry="0" x="0" y="0" width="10" height="10">
                                    </rect>
                                </svg>
                            </div>
                        }
                    </div>

                    {
                        ((user && user.userDepartments === "Administration") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors") || (user && user.userDepartments === "Management")) &&
                        <StockDashboard_Mob />
                    }
                    <ExpenceAnalytics_Mob salaryData={salaryData} billData={billData} purchaseData={purchaseData} taxData={taxData} invoiceData={invoiceData} />
                </div>

            </div>


        </>
    )
}
