import React, { useState } from "react";
import { Link,useSearchParams} from "react-router-dom";
import "../../Styles/Pc/forgot.css"

export const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();

	const handleSubmit = (e) => {
		e.preventDefault();
		searchParams.get("key")?set():forgot();
	}
	const handleChange = (e) => {
		setEmail(e.target.value)
	}



    const forgot = () => {

        if (email.length === 0) {
            return alert("Please Enter Email!!");
        }
        const apiUrl = process.env.REACT_APP_API_URL + "/users/forgotpassword";
        var apiParam = {
            method: 'POST',
            headers: {
                to: email
            },
            credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
               
                alert(repos.message || repos);
            
            })
            .catch((err) => alert(JSON.stringify(err)));
    };

    const set = () => {

        if (email.length === 0) {
            return alert("Please Enter Password!!");
        }
        const apiUrl = process.env.REACT_APP_API_URL + "/users/setpassword";
        var apiParam = {
            method: 'POST',
            headers: {
                to: email,
				key: searchParams.get("key")
            },
            credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
               
                alert(repos.message || repos);
            
            })
            .catch((err) => alert(JSON.stringify(err)));
    };

	return (
		<div className="slideInLeft">
			<div id="pc_login__4">
				<img id="n_9962869_6204361" src="" srcset="n_9962869_6204361.png 1x" />

				<img id="Group_1" src="" srcset="Group_1.png 1x" />

				{/* </svg> */}
				<div id="mid_overall">
					<svg class="bg_dummy">
						<rect id="bg_dummy" rx="17" ry="17" x="0" y="0" width="392" height="413">
						</rect>
					</svg>
					<img id="logo" src="" srcset="logo.png 1x" />

					<div id="Forgot_Password2">
						<span>{searchParams.get("key")?"Set":"Forgot"} Password</span>
					</div>
					<button id="btn">
						<svg class="Rectangle_3_r">
							<linearGradient id="Rectangle_3_r" spreadMethod="pad" x1="0.5" x2="0.5" y1="1" y2="-0.936">
								<stop offset="0" stop-color="rgba(252,135,135,1)" stop-opacity="1"></stop>
								<stop offset="1" stop-color="rgba(235,56,61,1)" stop-opacity="1"></stop>
							</linearGradient>
							<rect id="Rectangle_3_r" rx="6" ry="6" x="0" y="0" width="305" height="45">
							</rect>
						</svg>
						<div id="Continue">
							<span onClick={handleSubmit}>Continue</span>
						</div>
					</button>
					<input type={searchParams.get("key")?"password":"text"} placeholder={searchParams.get("key")?"Enter New Password":"Enter Email Address"} class="password" value={email} onChange={handleChange} />

					<svg class="Icon_ionic-md-arrow-round-back" viewBox="5.625 6.33 18.74 17.678">
						<path id="Icon_ionic-md-arrow-round-back" d="M 22.74073600769043 13.46454429626465 L 11.34444713592529 13.46454429626465 L 15.76450157165527 9.236201286315918 C 16.39822196960449 8.570530891418457 16.39822196960449 7.49480676651001 15.76450157165527 6.829136371612549 C 15.13078212738037 6.163464546203613 14.10298538208008 6.163464546203613 13.46394062042236 6.829136371612549 L 6.104283332824707 13.96512889862061 C 5.784760475158691 14.274001121521 5.625 14.69470405578613 5.625 15.15801239013672 L 5.625 15.17931365966797 C 5.625 15.64262199401855 5.784760475158691 16.06332588195801 6.104283332824707 16.3721981048584 L 13.45861530303955 23.50819206237793 C 14.09766006469727 24.17386436462402 15.12545871734619 24.17386436462402 15.75917434692383 23.50819206237793 C 16.39289665222168 22.84252166748047 16.39289665222168 21.76679801940918 15.75917434692383 21.10112380981445 L 11.33911991119385 16.8727855682373 L 22.73540878295898 16.8727855682373 C 23.63539886474609 16.8727855682373 24.36497116088867 16.11125755310059 24.36497116088867 15.16866493225098 C 24.37030029296875 14.2100944519043 23.64072418212891 13.46454429626465 22.74073600769043 13.46454429626465 Z">
						</path>
					</svg>
					<Link to={"/"}>
						<div id="Back">
							<span>Back</span>
						</div>
					</Link>
					<div id="Enter_the_email_address_associ">
						{!searchParams.get("key") && <span>Enter the email address associated with your account and we'll send you a link to reset your password.</span>}
					</div>
				</div>
			</div>
		</div>
	)
}