import React from 'react'
import "../../Styles/Pc/paymentmodecard.css"

export const PaymentModeCard = (props) => {

    const { rec } = props;

    return (
        <div id="paymentMode_card" onClick={() => { props.setPaymentModePopup("Edit"); props.setSelectedPaymentMode(rec) }}>
            <svg class="card_over_all_bg">
                <rect id="card_over_all_bg" rx="10" ry="10" x="0" y="0" width="174" height="191">
                </rect>
            </svg>
            <img id="card_bg_design" src="../User_Main/card_bg_design.png" srcset="../User_Main/card_bg_design.png 1x" />

            {/* <div id="pfp_grp">
                <svg class="pfp_behind_circle">
                    <ellipse id="pfp_behind_circle" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{ fill: rec.active === true ? "#fc8787" : "white" }}>
                    </ellipse>
                </svg>
                <img id="profile_pic_card" src={rec.profilePicUrl ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG + rec.profilePicUrl.split('/')[5] : "../User_Main/profile_pic_card.png"} />

            </div> */}
            <img title="Email" id="mode_phone_img_lbl" src="../User_Main/remark_lbl.png" srcset="../User_Main/remark_lbl.png 1x" />

            <div id="paymentmode_name_lbl">
                    <span>{rec.modeName}</span>
            </div>
            <img title="Mobile Number" id="email_lbl"  src="../User_Main/email_lbl.png" srcset="../User_Main/email_lbl.png 1x" />

            <div id="mode_phone_lbl">
                <span> {rec.accountId} </span>
            </div>
            <img title="Access" id="manager_lbl" src="../User_Main/manager_lbl.png" srcset="../User_Main/manager_lbl.png 1x" />

            <div id="Admin_lbl">
                    <span>{rec.modeType === "bank"?"Bank Account Transfer":rec.modeType === "upi"?"UPI App Transfer":rec.modeType === "card"?"Credit / Debit Card":rec.modeType === "cheque"?"Demand Draft / Cheque":rec.modeType === "cash"?"Cash":"Not Specified"}</span>

            </div>
            <div id="mode_remarks_lbl">
                <span> {rec.remarks} </span>
            </div>
        </div>
    )
}
