import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/tax_rightcomponent_mob.css"
import { UserContext } from "../UserContext";
import { AddTaxAuthority_Popup } from '../Pc/AddTaxAuthority_Popup';
import { Tax_Right_Donutchart } from '../Pc/Tax_Right_Donutchart';

export const Tax_RightComponent_Mob = (props) => {

    const [searchText, setSearchText] = useState("");

    const [taxAuthoritiesData, setTaxAuthoritiesData] = useState([]);
    const [tax, setTax] = useState(UserContext);
    const [addTaxAuthorityPopup, setAddTaxAuthorityPopup] = useState("none");
    const [selectedTaxAuthority, setSelectedTaxAuthority] = useState({});


    useEffect(() => {

        if (tax) {
            GetTaxAuthorities()
        }
    }, [addTaxAuthorityPopup])


    const GetTaxAuthorities = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/taxauthority/gettaxauthorities";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setTaxAuthoritiesData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const showState = (rec) => {
        switch (rec) {
            case 'AP':
                return "Andhra Pradesh"
                break;
            case 'AR':
                return "Arunachal Pradesh"
                break;
            case 'As':
                return "Assam"
                break;
            case 'BR':
                return "Bihar"
                break;
            case 'CT':
                return "Chhattisgarh"
                break;
            case 'DL':
                return "Delhi"
                break;
            case 'GA':
                return "Goa"
                break;
            case 'GJ':
                return "Gujarat"
                break;
            case 'HR':
                return "Haryana"
                break;
            case 'HP':
                return "Himachal Pradesh"
                break;
            case 'JK':
                return "Jammu and Kashmir"
                break;
            case 'JH':
                return "Jharkhand"
                break;
            case 'KA':
                return "Karnataka"
                break;
            case 'KL':
                return "Kerala"
                break;
            case 'MP':
                return "Madhya Pradesh"
                break;
            case 'MH':
                return "Maharashtra"
                break;
            case 'MN':
                return "Manipur"
                break;
            case 'ML':
                return "Meghalaya"
                break;
            case 'MZ':
                return "Mizoram"
                break;
            case 'NL':
                return "Nagaland"
                break;
            case 'OR':
                return "Odisha"
                break;
            case 'PB':
                return "Punjab"
                break;
            case 'RJ':
                return "Rajasthan"
                break;
            case 'SK':
                return "Sikkim"
                break;
            case 'TN':
                return "Tamil Nadu"
                break;
            case 'TG':
                return "Telangana"
                break;
            case 'UP':
                return "Uttar Pradesh"
                break;
            case 'UT':
                return "Uttarakhand"
                break;
            case 'WB':
                return "West Bengal"
                break;
            case 'CN':
                return "Central"
                break;
            default:
                return "Not Defined"
        }
    }

    return (
        <>
            <div id="rigth_side_tax_mob">
                <div className='blur_right_tax' onClick={() => props.componentClose(false)}>
                   
                </div>
                <div id="all_grp_right_side_mob_tax">
                    <svg class="bg_ogf_side_part_mob_tax">
                        <rect id="bg_ogf_side_part_mob_tax" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                    <div className='chart_mob_right_tax'>
                        <Tax_Right_Donutchart />
                    </div>
                    <div id="product_add_mob_tax">
                        <img id="search_tax_right_mob" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />
                        <input class="filter_tax_right_mob" type='text' placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        <div id="product_grp_with_add_mob_tax">
                            <div id="add_btn_tax_authority_mob_tax" onClick={() => setAddTaxAuthorityPopup("Add")}>
                                <svg class="add_bg_Add_Tax_Authority_mob_tax">
                                    <rect id="add_bg_Add_Tax_Authority_mob_tax" rx="8" ry="8" x="0" y="0" width="26" height="26">
                                    </rect>
                                </svg>
                                <svg class="Add_Tax_Authorityplus__mob_tax" viewBox="6.75 6.75 18 18">
                                    <path id="Add_Tax_Authorityplus__mob_tax" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                                    </path>
                                </svg>
                            </div>
                            <div id="tax_authority_lbl_tax_page_mob_tax">
                                <span>Tax Authorities</span>
                            </div>
                        </div>
                        <div id="Scroll_product_mob_tax">
                            {taxAuthoritiesData.filter(rec => rec.taxAuthority.toLowerCase().includes(searchText.toLowerCase()) || rec.registerationNumber.toLowerCase().includes(searchText.toLowerCase()) || showState(rec.state).toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                                return (
                                    <div id="tax_authority_card_1_tax_page_tax" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setAddTaxAuthorityPopup("Edit"); setSelectedTaxAuthority(rec) }}>
                                        <svg class="card_1_bdr_tax_authority_mob_tax">
                                            <rect id="card_1_bdr_tax_authority_mob_tax" rx="10" ry="10" x="0" y="0" width="100%" height="117">
                                            </rect>
                                        </svg>
                                        <div id="Tax_Authority_lbl_tax_page_mob_dx_tax">
                                            <span><marquee behavior="scroll" direction="">{rec.taxAuthority}</marquee></span>
                                        </div>
                                        <div id="mob_tax_authority_tax">
                                            <span>{rec.registerationNumber}</span>
                                        </div>
                                        <div id="State_tax_authority_mob_tax">
                                            <span>{showState(rec.state)}</span>
                                        </div>
                                        <div class="btw_tax_and_reg_mob_tax"></div>
                                        <div class="between_reg_and_state_line_mob_tax"></div>
                                        <img id="taxes_1__img_all_page_right_mo_tax" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                        <img id="reg__img_all_page_right_mob_tax" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                        <img id="location_img_all_page_right_mo_tax" src="../RightSide_Component/location_img_all_page_right.png" srcset="../RightSide_Component/location_img_all_page_right.png 1x" />

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>

            <AddTaxAuthority_Popup display={addTaxAuthorityPopup} data={selectedTaxAuthority} closeAddTaxAuthorityPopup={(refresh) => { refresh === true && props.refreshTaxes(); setAddTaxAuthorityPopup("none") }} />
        </>
    )
}
