import React , {useState , useEffect} from 'react'
import "../../Styles/Pc/overview_container.css"
import { UserContext } from "../UserContext";

export const Overview_Container = (props) => {

  const [purchaseData, setPurchaseData] = useState([]);
  const [purchase, setPurchase] = useState(UserContext);

  useEffect(() => {
    GetPurchase(props.tab)

  }, [])


  const GetPurchase = (tab) => {

    let apiURL = process.env.REACT_APP_API_URL + "/purchase/getpurchases/" + tab;
    let apiParams = {
      method: "GET",

    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setPurchaseData(repos.data)

        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }

  return (
    <>
      <div id="Scroll_stock_overview">
        {purchaseData.map((rec) => {
          return (
            <div id="card1_grp_lbl_dashboard_pc" style={{ position: "relative", display: "inline-flex", marginLeft: "8px", marginTop: "10px" }}>
              <img id="inside_bg_card1lbl_dashboard_p" src="../Dashboard/inside_bg_card1lbl_dashboard_p.png" srcset="../Dashboard/inside_bg_card1lbl_dashboard_p.png 1x" />

              <div id="n_50_card1_lbl_dashboard_pc">
                <span>${rec.itemCost}</span>
              </div>
              <div id="lenovo_lbl_dashboard_pc">
                <span>{rec.product}</span>
              </div>
              <div id="Amount_card1_lbl_dashboard_pc">
                <span>Amount</span>
              </div>
              <div id="n__Days_lbl_dashboard_pc">
                <span>{parseInt((new Date(rec.dueDate) - new Date())/86400000)} Days</span>
              </div>
              <div id="expir_lbl_dashboard_pc">
                <span>Expiring</span>
              </div>
              <div id="name_lbl_dashboard_pc">
                <span>{rec.purchasedBy}</span>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
