import React, { useState , useEffect } from "react";
import "../../Styles/Pc/salary_main.css"


export const Salary_Main = () => {

 



    return (
        <>
            <div id="salary_page">
                <img id="salary_bg_salary" src="over_all_bg_page.png" srcset="over_all_bg_page.png 1x" />


                
               
               
            </div>



        </>
    )
}




