import React, { useState, useEffect , useContext } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/Pc/addtax_popup.css"
import { UserContext } from "../UserContext";

export const AddTax_Popup = (props) => {
  
    const { user, setUser } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [paymentModesData, setPaymentModesData] = useState([]);

    //AddAuthorityTax Fetching
    const [authority, setAuthority] = useState(UserContext);
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [taxAuthoritiesData, setTaxAuthoritiesData] = useState([]);
    const [taxAuthority, setTaxAuthority] = useState("");
    const [taxAuthorityId, setTaxAuthorityId] = useState("");

    const [dueDate, setDueDate] = useState("");
    const [taxAmount, setTaxAmount] = useState("0");
    const [fine, setFine] = useState("0");
    const [interest, setInterest] = useState("0");
    const [remarks, setRemarks] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [status, setStatus] = useState("Draft");
    const [paidDate, setPaidDate] = useState("");
    const [modeId, setModeId] = useState("");
    const [modeType, setModeType] = useState("");
    const [modeName, setModeName] = useState("");
    const [accountId, setAccountId] = useState("");

    const [taxId, setTaxId] = useState("");


    useEffect(() => {
        if (user) {
            GetUser()
        }
    }, [user, props.refresh])

    //fetching tax authority
    useEffect(() => {

        if (authority) {
            GetTaxAuthorities()
            GetPaymentModes();
        }
    }, [authority,props.refresh])


    useEffect(() => {
        if (props.display === "Edit") {
            setTaxId(props.data ? props.data._id : "")
            setTaxAuthorityId(props.data ? props.data.taxAuthorityId : "")
            setTaxAuthority(props.data ? props.data.taxAuthority : "")
            setRegistrationNumber(props.data ? props.data.registrationNumber : "")
            setDueDate(props.data ? new Date(props.data.dueDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.dueDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.dueDate).getDate()).toString().padStart(2, '0') : "")
            setTaxAmount(props.data ? props.data.taxAmount : "0")
            setFine(props.data ? props.data.fine : "0")
            setInterest(props.data ? props.data.interest : "0")
            setRemarks(props.data ? props.data.remarks : "")
            setApprovedBy(props.data ? props.data.approvedBy : "")
            setStatus(props.data ? props.data.status : "")
            setPaidDate(props.data ? new Date(props.data.paidDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.paidDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.paidDate).getDate()).toString().padStart(2, '0') : "")

            let mode = paymentModesData.filter(e=>e.modeType===props.data.paidMode && e.modeName===props.data.paidName && e.accountId===props.data.paidAccountId)[0]
            if(props.data && mode)
            {
                setModeId(mode._id)
            }
        }

    }, [props.data])

    useEffect(() => {
        let mode = paymentModesData.filter(e=>e._id===modeId)[0];
        if(mode)
         {
             setModeType(mode.modeType);
             setModeName(mode.modeName);
             setAccountId(mode.accountId);
         }
         else
         {
             setModeType("")
             setModeName("")
             setAccountId("")
         }
     }, [modeId])

    const clearData = () => {
        setTaxAuthority("")
        setRegistrationNumber("")
        setDueDate("")
        setTaxAmount("0")
        setFine("0")
        setInterest("0")
        setRemarks("")
        setApprovedBy("")
        setStatus("")
        setPaidDate("")
        setModeId("")
        setModeType("")
        setModeName("")
        setAccountId("")
    }


    //fetching user
    const GetUser = async () => {

        let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
        let apiParams = {
            method: "GET",

        };

        await fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setUserData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }
    

    const GetPaymentModes = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/getmodes";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPaymentModesData(repos.data)
                    if (repos.data[0]) {
                        setModeId(repos.data[0]._id);
                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }
 

    const GetTaxAuthorities = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/taxauthority/gettaxauthorities";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setTaxAuthoritiesData(repos.data)
                    if (repos.data[0]) {
                        setTaxAuthority(repos.data[0].taxAuthority)
                        setTaxAuthorityId(repos.data[0]._id)
                        setRegistrationNumber(repos.data[0].registerationNumber)
                        setDefaultDate(repos.data[0])

                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    const setDefaultDate = (rec) => {
        if (rec.frequency === "onetime") {
            const currentDate = new Date();
            const dueDate = currentDate && currentDate.getFullYear().toString() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');
            setDueDate(dueDate);
        } else if (rec.frequency === "monthly") {
            const currentDate = new Date();
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 8) + day;
            setDueDate(dueDate);
        } else {
            const currentDate = new Date();
            const month = rec.month.toString().padStart(2, '0');
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 5) + month + '-' + day;
            setDueDate(dueDate);
        }
    }




    const addRecord = () => {

        if (validateRecord("add"))
            {
        let apiURL = process.env.REACT_APP_API_URL + "/tax/addtax";
        let apiParams = {
            method: "POST",
            headers: {
                taxauthorityid: taxAuthorityId,
                taxauthority: taxAuthoritiesData.filter((rec) => rec._id === taxAuthorityId)[0].taxAuthority,
                registrationnumber: registrationNumber,
                duedate: new Date(dueDate),
                taxamount: taxAmount,
                fine: fine,
                interest: interest,
                remarks: encodeURIComponent(remarks),
                approvedby: approvedBy,
                status: status,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };

        fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
            if (repos.message) {
                props.closeAddTaxPopup(false) ; clearData()
                alert("Tax Added Successfully!!");

            } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));}
        else
        {
            return;
        }
    }



    const updateRecord = () => {

        if (validateRecord("edit"))
            {
        let apiURL = process.env.REACT_APP_API_URL + "/tax/edittaxes";
        let apiParams = {
            method: "POST",
            headers: {
                taxid: taxId,
                taxauthorityid: taxAuthorityId,
                taxauthority: taxAuthoritiesData.filter((rec) => rec._id === taxAuthorityId)[0].taxAuthority,
                registrationnumber: registrationNumber,
                duedate: new Date(dueDate),
                taxamount: taxAmount,
                fine: fine,
                interest: interest,
                remarks: encodeURIComponent(remarks),
                approvedby: approvedBy,
                status: status,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };

        fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
            if (repos.data) {
                props.closeAddTaxPopup(false) ; clearData()
                alert("Tax Updated Successfully!!");

            } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));}
        else
        {
            return;
        }
    }



    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && taxId.trim().length <= 0 )
        {
            validationText+="Tax Id is invalid"+"\n";
        }
        if(taxAuthorityId.trim().length <= 0) 
        {
            validationText+="Please select Tax Authority"+"\n";
        }
        if(registrationNumber.trim().length <= 0) 
        {
            validationText+="Please enter Registration Number or NA if not available"+"\n";
        }
        if(isNaN(Date.parse(dueDate))) 
        {
            validationText+="Please select Due Date"+"\n";
        }
        if(isNaN(taxAmount) || taxAmount<=0) 
        {
            validationText+="Please enter Tax Amount"+"\n";
        }
        if(isNaN(fine)) 
        {
            validationText+="Please enter Fine Amount"+"\n";
        }
        if(isNaN(interest)) 
        {
            validationText+="Please enter Interest Amount"+"\n";
        }
            if(approvedBy.trim().length <= 0) 
                {
                    validationText+="Please select Approved By"+"\n";
                }
        if(status.trim().length <= 0) 
            {
                validationText+="Please select Status"+"\n";
            }
        if(validationText.trim().length > 0)
            {
    alert(validationText);
    return false;
            }
            else
            {
    return true;
            }
        }




    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closeAddTaxPopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="addtax_layout">
                    <div id="tax_pop_up_2">
                        <img id="bg_design_of_pop_up_tax" src="../AddTax_Popup/bg_of_add_tax_auth_pop_up_tax_.png" srcset="../AddTax_Popup/bg_of_add_tax_auth_pop_up_tax_.png 1x" />

                        <div id="tax_auth_lbl_pop_up_tax">
                            <span>Tax Authority</span>
                        </div>
                        <select class="tax_auth_innput_pop_up_tax" tabIndex="1" autoFocus
                            value={taxAuthorityId}
                            onChange={(e) => {
                                let regNum = taxAuthoritiesData && taxAuthoritiesData.filter((item) => item._id === e.target.value).length>0? taxAuthoritiesData.filter((item) => item._id === e.target.value)[0].registerationNumber:"";
                                setRegistrationNumber(regNum);
                                setTaxAuthorityId(e.target.value);
                                regNum.length>0 && setDefaultDate(taxAuthoritiesData.filter((item) => item._id === e.target.value)[0])
                            }}>
                            <rect id="tax_auth_innput_pop_up_tax" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>                            
                            {taxAuthoritiesData.map((rec) => (
                                <option value={rec._id} >{rec.taxAuthority}</option>
                            ))}
                        </select>
                        <div id="interest_paid_pop_up_tax">
                            <span>Interest</span>
                        </div>
                        <input placeholder="Interest" type="number" tabIndex="6" class="interest_paid_inpit_pop_up_tax" value={interest} onChange={(e) => setInterest(e.target.value)} />
                        <div id="tax_amount_paid_lbl_pop_up_tax">
                            <span>Tax Amount</span>
                        </div>
                        <input placeholder="Tax Amount" type="number" tabIndex="4" class="tax_amount_paid_inputpop_up_ta" value={taxAmount} onChange={(e) => setTaxAmount(e.target.value)} />
                        <div id="fine_paid_lbl_pop_up_tax">
                            <span>Fine</span>
                        </div>
                        <input placeholder="Fine" type="number" tabIndex="5" class="fine_paid_input_pop_up_tax" value={fine} onChange={(e) => setFine(e.target.value)} />
                        <div id="remark_tax_pop_up_tax">
                            <span>Remarks</span>
                        </div>
                        <textarea placeholder="Remark" tabIndex="7" class="remark_input_pop_up_tax" value={remarks} onChange={(e) => setRemarks(e.target.value)} >
                        </textarea>
                        <button id="tax_btn_grp_pop_up_tax" >
                            <svg class="passs_bdr_btn_pop_up_tax">
                                <rect id="passs_bdr_btn_pop_up_tax" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                </rect>
                            </svg>
                            <div id="add_lbl_pop_up_tax">
                                <span tabIndex="10" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit" : "Add"}</span>
                            </div>
                        </button>
                        <img onClick={() => {props.closeAddTaxPopup(false) ; clearData()}} id="close_tax_pop_up_tax" src="../AddTax_Popup/close_pop_up_tax_auth.png" srcset="../AddTax_Popup/close_pop_up_tax_auth.png 1x" />

                        <div id="due_date_lbl_pop_up_tax_1">
                            <span>Due Date</span>
                        </div>
                        <input placeholder="Due Date" tabIndex="3" class="due_date_pop_up_tax_date_1" type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                        <div id="add_tax_lbl_pop_up_tax">
                            <span>{props.display === "Edit" ? "Edit Tax" : "Add Tax"}</span>
                        </div>
                        <div id="reg_no_lbl_pop_up_tax">
                            <span>Registeration Number</span>
                        </div>
                        <input placeholder="Registeration Number" tabIndex="2" class="redistration_n0_input_pop_up_t" viewBox="0 0 157.254 40" value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} />
                        <div id="status_pop_up_tax">
                            <span>Status</span>
                        </div>
                        <select name="" className="statusinput_pop_up_tax" tabIndex="9" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" >--- Select ---</option>
                            <option value="Draft" >Draft</option>
                            <option value="Approved" >Approved</option>
                            <option value="On Hold" >On Hold</option>
                            <option value="Paid">Paid</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Skipped">Skipped</option>
                        </select>
                        <div id="approved_by_paid_lbl_pop_up_ta">
                            <span>Approved By</span>
                        </div>
                        <select name="approver" tabIndex="8" className="approved_by_input_pop_up_tax" value={approvedBy} onChange={(e) => setApprovedBy(e.target.value)}>
                            
                        <option value="" >--- Select ---</option>
                            {userData.filter(v =>v.userDepartments === "Accounts" || v.userDepartments === "Directors").map((rec) => (
                                <option value={rec.name} >{rec.name}</option>
                            ))}
                        </select>
                        <div id="paid_through_paid_lbl_pop_up_bi">
                            <span>Paid Through</span>
                        </div>
                        <select tabIndex="13" class="paid_through_input_pop_up_bill" 
                        value={modeId} 
                        onChange={(e) => setModeId(e.target.value)} >
                            <rect id="tax_auth_innput_pop_up_salary" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {paymentModesData.map((rec) => (
                                <option value={rec._id} >{rec.modeType.toUpperCase()+" - "+rec.modeName}</option>
                            ))}
                        </select>
                        <div id="paid_on_paid_lbl_pop_up_bi" >
                            <span>Paid On</span>
                        </div>
                        <input tabIndex="14" type="date" class="paid_on_input_pop_up_bill" value={paidDate} onChange={(e) => setPaidDate(e.target.value)} />
                        <div id="paid_name_paid_lbl_pop_up_bi">
                            <span>Acc. Id / Name</span>
                        </div>
                        <input placeholder="Acc. Id / Name" autoFocus tabIndex="1" class="paid_by_input_pop_up_bill" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
     </div>
                </div>

            </Modal>

        </>
    )
}