import React, { useState, useEffect } from "react";
import "../../Styles/Pc/invoice_right_component.css"
import AddClient_Popup from "./AddClient_Popup";
import { UserContext } from "../UserContext";
import { Invoice_Right_Donutchart } from "./Invoice_Right_Donutchart";

export const Invoice_Right_Component = (props) => {

    const [searchText, setSearchText] = useState("");

    const [client, setClient] = useState(UserContext);
    const [clientData, setClientData] = useState([]);
    const [addClientPopup, setAddClientPopup] = useState("none");
    const [selectedClient, setSelectedClient] = useState({});


    //fetching  client
    useEffect(() => {

        if (client) {
            GetClient()
        }
    }, [addClientPopup])


    const GetClient = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/client/getclients";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setClientData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div>
                <div id="add_btn_invoice_right" onClick={() => setAddClientPopup("Add")} >
                    <svg class="add_bg_Add_invoice_right">
                        <rect id="add_bg_Add_invoice_right" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_invoice_right" viewBox="6.75 6.75 18 18">
                        <path id="Add_invoice_right" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="scroll_left_component_invoice_right">
                    {clientData.filter(rec => rec.name.toLowerCase().includes(searchText.toLowerCase()) || rec.gstIn.toLowerCase().includes(searchText.toLowerCase()) || rec.state.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                        return (
                            <div id="tax_authority_card_1_invoice_right" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setAddClientPopup("Edit"); setSelectedClient(rec) }}>
                                <svg class="card_1_bdr_invoice_right">
                                    <rect id="card_1_bdr_invoice_right" rx="10" ry="10" x="0" y="0" width="197" height="117">
                                    </rect>
                                </svg>
                                <div id="Tax_Authority_lbl_invoice_right">
                                    <span><marquee behavior="scroll" direction="">{rec.name}</marquee></span>
                                </div>
                                <div id="Registration_Number_invoice_right">
                                    <span>{rec.gstIn}</span>
                                </div>
                                <div id="State_tax_authority_invoice_right">
                                    <span>{rec.state}</span>
                                </div>
                                <svg class="btw_tax_and_reg_invoice_right" viewBox="0 0 184 1">
                                    <path id="btw_tax_and_reg_invoice_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <svg class="between_reg_and_state_line_invoice_right" viewBox="0 0 184 1">
                                    <path id="between_reg_and_state_line_invoice_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <img id="taxes_1__img_all_invoice_right" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                <img id="reg__img_all_invoice_right" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                <img id="location_img_all_invoice_right" src="../RightSide_Component/location_img_all_page_right.png" srcset="../RightSide_Component/location_img_all_page_right.png 1x" />

                            </div>
                        )
                    })}
                </div>
                <input class="filter_invoice_right" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <img id="search_invoice_right" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />

                <div id="lbl_tax_page_pc_invoice_right">
                    <span>Clients</span>
                </div>
            </div>

            <AddClient_Popup display={addClientPopup} data={selectedClient} closePopup={(refresh) => { refresh === true && props.refreshInvoices(); setAddClientPopup("none") }} />
            <div id="invoice_chart" >
                <Invoice_Right_Donutchart />
            </div>

        </>
    )
}