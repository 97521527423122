import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/invoice_rightcomponent_mob.css"
import { UserContext } from "../UserContext";
import AddClient_Popup from '../Pc/AddClient_Popup';
import { Invoice_Right_Donutchart } from '../Pc/Invoice_Right_Donutchart';

export const Invoice_RightComponent_Mob = (props) => {

    const [searchText, setSearchText] = useState("");

    const [client, setClient] = useState(UserContext);
    const [clientData, setClientData] = useState([]);
    const [addClientPopup, setAddClientPopup] = useState("none");
    const [selectedClient, setSelectedClient] = useState({});


    //fetching  client
    useEffect(() => {

        if (client) {
            GetClient()
        }
    }, [addClientPopup])


    const GetClient = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/client/getclients";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setClientData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div id="rigth_side_invoice_mob">
                <div className='blur_right_invoice' onClick={() => props.componentClose(false)}>
                   
                </div>
                <div id="all_grp_right_side_mob_invoice">
                    <svg class="bg_ogf_side_part_mob_invoice">
                        <rect id="bg_ogf_side_part_mob_invoice" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                    <div className='chart_mob_right_invoice'>
                        <Invoice_Right_Donutchart/>
                    </div>
                    <div id="product_add_mob_invoice">
                        <img id="search_invoice_right_mob" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />
                        <input class="filter_invoice_right_mob" type='text' placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        <div id="product_grp_with_add_mob_invoice">
                            <div id="add_btn_tax_authority_mob_invoice" onClick={() => setAddClientPopup("Add")} >
                                <svg class="add_bg_Add_Tax_Authority_mob_invoice">
                                    <rect id="add_bg_Add_Tax_Authority_mob_invoice" rx="8" ry="8" x="0" y="0" width="26" height="26">
                                    </rect>
                                </svg>
                                <svg class="Add_Tax_Authorityplus__mob_invoice" viewBox="6.75 6.75 18 18">
                                    <path id="Add_Tax_Authorityplus__mob_invoice" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                                    </path>
                                </svg>
                            </div>
                            <div id="tax_authority_lbl_tax_page_mob_invoice">
                                <span>Clients</span>
                            </div>
                        </div>
                        <div id="Scroll_product_mob_invoice">
                            {clientData.filter(rec => rec.name.toLowerCase().includes(searchText.toLowerCase()) || rec.gstIn.toLowerCase().includes(searchText.toLowerCase()) || rec.state.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                                return (
                                    <div id="tax_authority_card_1_tax_page_invoice" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setAddClientPopup("Edit"); setSelectedClient(rec) }}>
                                        <svg class="card_1_bdr_tax_authority_mob_invoice">
                                            <rect id="card_1_bdr_tax_authority_mob_invoice" rx="10" ry="10" x="0" y="0" width="100%" height="117">
                                            </rect>
                                        </svg>
                                        <div id="Tax_Authority_lbl_tax_page_mob_dx_invoice">
                                            <span><marquee behavior="scroll" direction="">{rec.name}</marquee></span>
                                        </div>
                                        <div id="mob_tax_authority_invoice">
                                            <span>{rec.gstIn}</span>
                                        </div>
                                        <div id="State_tax_authority_mob_invoice">
                                            <span>{rec.state}</span>
                                        </div>
                                        <div class="btw_tax_and_reg_mob_invoice"></div>
                                        <div class="between_reg_and_state_line_mob_invoice"></div>
                                        <img id="taxes_1__img_all_page_right_mo_invoice" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                        <img id="reg__img_all_page_right_mob_invoice" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                        <img id="location_img_all_page_right_mo_invoice" src="../RightSide_Component/location_img_all_page_right.png" srcset="../RightSide_Component/location_img_all_page_right.png 1x" />

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>

            <AddClient_Popup display={addClientPopup} data={selectedClient} closePopup={(refresh) => { refresh === true && props.refreshInvoices(); setAddClientPopup("none") }} />

        </>
    )
}
