import React from "react";
import "../../Styles/Pc/company_logo.css"

export const Company_Logo = () => {
    return (
        <>
            <div id="finance_manager_top_right">
                
                <div id="name_Group_42">
                    <div id="logo_Finance_Manager">
                        <span>finOL</span>
                    </div>
                 
                </div>
            </div>
        </>
    )
}