import React, { useState, useEffect } from "react";
import "../../Styles/Pc/bills_right_side_component.css"
import { Vendors_Popup } from "./Vendors_Popup";
import { UserContext } from "../UserContext";
import { Bill_Right_Donutchart_ } from "./Bill_Right_Donutchart_";

export const Bills_Right_Side_Component = (props) => {


    const [vendorData, setVendorData] = useState([]);
    const [vendor, setVendor] = useState(UserContext);
    const [vendorsPopup, setVendorsPopup] = useState("none");
    const [selectedVendor, setSelectedVendor] = useState({});

    const [searchText, setSearchText] = useState("");


    //fetching Vendor
    useEffect(() => {

        if (vendor) {
            GetVendors()
        }
    }, [vendorsPopup])


    const GetVendors = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/vendor/getvendors";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setVendorData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div >
                <div id="add_btn_bill_right" onClick={() => { setVendorsPopup("Add") }} >
                    <svg class="add_bg_Add_bill_right">
                        <rect id="add_bg_Add_bill_right" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_Tax_bill_right" viewBox="6.75 6.75 18 18">
                        <path id="Add_Tax_bill_right" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="scroll_left_component_bill_right">
                    {vendorData.filter(rec => rec.vendorName.toLowerCase().includes(searchText.toLowerCase()) || rec.accountId.toLowerCase().includes(searchText.toLowerCase()) || rec.frequency.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                        return (
                            <div id="tax_authority_card_1_bill_right" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setVendorsPopup("Edit"); setSelectedVendor(rec) }}>
                                <svg class="card_1_bdr_bill_right">
                                    <rect id="card_1_bdr_bill_right" rx="10" ry="10" x="0" y="0" width="197" height="117">
                                    </rect>
                                </svg>
                                <div id="Tax_Authority_lbl_bill_right">
                                    <span><marquee behavior="scroll" direction="">{rec.vendorName}</marquee></span>
                                </div>
                                <div id="Registration_Number_bill_right">
                                    <span>{rec.accountId}</span>
                                </div>
                                <div id="State_tax_authority_bill_right">
                                    <span>{rec.frequency}</span>
                                </div>
                                <svg class="btw_tax_and_reg_bill_right" viewBox="0 0 184 1">
                                    <path id="btw_tax_and_reg_bill_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <svg class="between_reg_and_state_line_bill_right" viewBox="0 0 184 1">
                                    <path id="between_reg_and_state_line_bill_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <img id="taxes_1__img_all_bill_right" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                <img id="reg__img_all_bill_right" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                <img id="location_img_all_bill_right" src="../RightSide_Component/frequency.png" srcset="../RightSide_Component/frequency.png 1x" />

                            </div>
                        )
                    })}
                </div>
                <input class="filter_tax_bill_right" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <img id="search_bill_right" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />

                <div id="lbl_page_pc_bill_right">
                    <span>Vendors</span>
                </div>
            </div>



            <Vendors_Popup display={vendorsPopup} data={selectedVendor} closeVendorsPopup={(refresh) => { refresh === true && props.refreshBills(); setVendorsPopup("none") }} />
            <div id="bill_chart">
                <Bill_Right_Donutchart_ />
            </div>

        </>
    )
}
