import React, { useState } from "react";
import "../../Styles/Pc/tax_main.css"
import { AddTax_Popup } from "./AddTax_Popup";


export const Tax_Main = () => {

    const [addTaxPopup, setAddTaxPopup] = useState(false);
    

    return (
        <>

            <div id="tax_page">
                <img id="Add_Tax_Authority_bg_overall" src="../Tax_Main/Add_Tax_Authority_bg_overall.png" srcset="../Tax_Main/Add_Tax_Authority_bg_overall.png 1x" />

                
            </div>



        </>
    )
}
