import React from 'react'
import "../../Styles/Mob/monthly_mob.css"
import { useLocation } from 'react-router-dom';
import { SalariesContainer_Mob } from './SalariesContainer_Mob'
import { BillContainer_Mob } from './BillContainer_Mob';
import { TaxContainer_Mob } from './TaxContainer_Mob';
import { InvoiceContainer_Mob } from './InvoiceContainer_Mob';

export const Monthly_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries_mob':
                return <SalariesContainer_Mob tab={"Monthly"} refresh={props.refresh} />;
            case '/bills_mob':
                return <BillContainer_Mob tab={"Monthly"} refresh={props.refresh} />;
            case '/taxes_mob':
                return <TaxContainer_Mob tab={"Monthly"} refresh={props.refresh}/>;
            case '/invoices_mob':
                return <InvoiceContainer_Mob tab={"Monthly"} refresh={props.refresh} />;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="tax_page_mob_monthly">

                <div id="UOTMQSA_monthly_task_mob">
                    <div id="overdue_monthly_task_mob" onClick={() => props.changeTabMob("OverDueMob")}>
                        <span>Over Due</span>
                    </div>
                    <div id="Upcoming_monthly_task_mob" onClick={() => props.changeTabMob("UpcomingMob")}>
                        <span>Upcoming</span>
                    </div>
                    <div id="monthly_monthly_task_mob">
                        <span>Monthly</span>
                    </div>
                    <div id="quaterly_monthly_task_mob" onClick={() => props.changeTabMob("QuaterlyMob")}>
                        <span>Quaterly</span>
                    </div>
                    <div id="semi_monthly_task_mob" onClick={() => props.changeTabMob("SemiAnuallyMob")}>
                        <span>Semi Annual</span>
                    </div>
                    <div id="annual_lbl_monthly_task_mob" onClick={() => props.changeTabMob("AnuallyMob")}>
                        <span>Annual</span>
                    </div>
                    <svg class="undesrline_monthly_mob" viewBox="0 0 69 1">
                        <path id="undesrline_monthly_mob" d="M 0 0 L 69 0">
                        </path>
                    </svg>
                </div>
                <svg class="ot_monthly">
                    <rect id="ot_monthly" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>
            </div>

            {
                renderComponents()
            }

        </>
    )
}
