import React from "react";
import "../../Styles/Pc/purchase_inuse.css"
import { Purchases_Container } from "./Purchases_Container";
import { useLocation } from 'react-router-dom';

export const Purchase_Inuse = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {           
            case '/purchases':
                return <Purchases_Container tab={"InUse"} refresh={props.refresh}/>;
            default:
                return '';
        }
    }


    return (
        <>
           <div id="new_layout_inuse">
				<div id="top_lbls_purchase_grid_grp_inuse">
					<svg class="new_layout_down_card_1_inuse">
						<rect id="new_layout_down_card_1_inuse" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
						</rect>
					</svg>
					<div id="heading_grp_new_lay_inuse">
						<div id="near_expiry_lbl_purchase_g_inuse" onClick={() => props.purchaseSelectedTab("Nearexpiry")}>
							<span>Near Expiry</span>
						</div>
						<div id="inuse__lbl_purchase_grid_inuse">
							<span>In Use</span>
						</div>
						<div id="instock_lbl_purchase_grid_inuse" onClick={() => props.purchaseSelectedTab("Instock")}>
							<span>In Stock</span>
						</div>
						<div id="expired_lbl_purchase_grid_inuse" onClick={() => props.purchaseSelectedTab("Expired")}>
							<span>Expired</span>
						</div>
						<svg class="undesrline_inuse" viewBox="0 0 69 1">
							<path id="undesrline_inuse" d="M 0 0 L 69 0">
							</path>
						</svg>
					</div>
				</div>


				{
					renderComponents()
				}

			</div>
        </>
    )
}