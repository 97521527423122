import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/Pc/addclient_popup.css"
import { Switch } from "antd";

const AddClient_Popup = (props) => {

    const [pickInput, setPickInput] = useState("");


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [gstIn, setGstIn] = useState("");
    const [pan, setPan] = useState("");
    const [frequency, setFrequency] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pin, setPin] = useState("");
    const [information, setInformation] = useState("");
    const [active, setActive] = useState(true);



    //edit client
    const [clientId, setClientId] = useState("");

    useEffect(() => {
        if (props.display === "Edit") {
            setClientId(props.data ? props.data._id : "")
            setName(props.data ? props.data.name : "")
            setEmail(props.data ? props.data.email : "")
            setMobileNumber(props.data ? props.data.mobileNumber : "")
            setGstIn(props.data ? props.data.gstIn : "")
            setPan(props.data ? props.data.pan : "")
            setFrequency(props.data ? props.data.frequency : "")
            setDay(props.data ? props.data.day : "")
            setMonth(props.data ? props.data.month : "")
            setAddress(props.data ? props.data.address : "")
            setCity(props.data ? props.data.city : "")
            setState(props.data ? props.data.state : "")
            setPin(props.data ? props.data.pin : "")
            setInformation(props.data ? props.data.information : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.data])


    const clearData = () => {
        setName("")
        setEmail("")
        setMobileNumber("")
        setGstIn("")
        setPan("")
        setFrequency("")
        setDay("")
        setMonth("")
        setAddress("")
        setCity("")
        setState("")
        setPin("")
        setInformation("")
        setActive(false)
    }




    const addRecord =  () => {
        if (validateRecord("add"))
            {
        let apiURL = process.env.REACT_APP_API_URL + "/client/addclient";
        let apiParams = {
            method: "POST",
            headers: {
                name: name,
                email: email,
                mobilenumber: mobileNumber,
                gstin: gstIn,
                pan: pan,
                frequency: frequency,
                day: day,
                month: month,
                address: encodeURIComponent(address),
                city: city,
                state: state,
                pin: pin,
                information: encodeURIComponent(information),
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup(true) ; clearData()
                    alert("Client Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }


    const updateRecord = () => {
        if (validateRecord("edit"))
        {
        let apiURL = process.env.REACT_APP_API_URL + "/client/editclient";
        let apiParams = {
            method: "POST",
            headers: {
                clientid: clientId,
                name: name,
                email: email,
                mobilenumber: mobileNumber,
                gstin: gstIn,
                pan: pan,
                frequency: frequency,
                day: day,
                month: month,
                address: encodeURIComponent(address),
                city: city,
                state: state,
                pin: pin,
                information: encodeURIComponent(information),
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup(true) ; clearData()

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
        }
        else
        {
            return;
        }
    }

    

    const validateRecord = (actionType) => {
    let validationText = "";
    if(actionType === "edit" && clientId.trim().length <= 0 )
    {
        validationText+="Client Id is invalid"+"\n";
    }
    if(name.trim().length <= 0) 
    {
        validationText+="Please enter Name"+"\n";
    }
    if(email.trim().length <= 0 || email.split('@').length !== 2 || email.split('@')[email.split('@').length-1].split('.')<2) 
    {
        validationText+="Please enter valid Email"+"\n";
    }
    if(!parseInt(mobileNumber.replace(' ','').substring(mobileNumber.length-10))) 
    {
        validationText+="Please enter valid Mobile Number"+"\n";
    }
    if(gstIn.trim().length <= 0) 
    {
        validationText+="Please enter GST Number or NA if not available"+"\n";
    }
    if(pan.trim().length <= 0) 
    {
        validationText+="Please enter PAN Number or NA if not available"+"\n";
    }
    if(address.trim().length <= 0) 
    {
        validationText+="Please enter Address"+"\n";
    }
    if(city.trim().length <= 0) 
    {
        validationText+="Please enter City"+"\n";
    }
    if(state.trim().length <= 0) 
    {
        validationText+="Please enter State"+"\n";
    }
    if(pin.trim().length <= 0) 
    {
        validationText+="Please enter PIN code"+"\n";
    }
    if(validationText.trim().length > 0)
        {
alert(validationText);
return false;
        }
        else
        {
return true;
        }
    }


    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="pop_layout">
                    <div id="new_client_popup-_invoice">
                        <img id="pop_up_bg_deisgn" src="pop_up_bg_deisgn.png" srcset="pop_up_bg_deisgn.png 1x" />

                        <svg onClick={() =>{props.closePopup(false) ; clearData()}} class="Icon_ionic-md-pop_up_invoice" viewBox="6.75 6.75 18 18">
                            <path id="Icon_ionic-md-pop_up_invoice" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                        <button id="btn_grp_client_pop_up">
                            <svg class="pop_up_invoice_btn">
                                <rect id="pop_up_invoice_btn" rx="6" ry="6" x="0" y="0" width="167" height="44">
                                </rect>
                            </svg>
                            <div id="Add_Client_pop_up_invoice">
                                <span tabIndex="15" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit Client" : "Add Client"}</span>
                            </div>
                        </button>
                        <div id="add_client_lbl_pfp_client_pop_">
                            <span>{props.display === "Edit" ? "Edit Client" : "Add Client"}</span>
                        </div>
                        <div id="over_all_scroll_client">
                            <div id="name_grp_prof_client_pop_up">
                                <div id="name_lbl_pfp_client_pop_up">
                                    <span>Name</span>
                                </div>
                                <input placeholder="Name" autoFocus tabIndex="1" class="name_input_pfp_client_pop_up" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div id="Address_grp_prof_client_pop_up">
                                <div id="address_lbl_pfp_client_pop_up">
                                    <span>Address</span>
                                </div>
                                <textarea placeholder="Address" tabIndex="9" class="address_input_pfp_client_pop_u" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div id="city_grp_client_pop_up">
                                <div id="state_lbl_pfp_client_pop_up">
                                    <span>City</span>
                                </div>
                                <input placeholder="City" tabIndex="10" class="state_input_pfp_client_pop_up" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div id="state_grp_client_pop_up">
                                <div id="state_lbl_pfp_client_pop_up_dh">
                                    <span>State</span>
                                </div>
                                <input placeholder="State" tabIndex="11" class="state_input_pfp_client_pop_up_di" value={state} onChange={(e) => setState(e.target.value)} />
                            </div>
                            <div id="Pin_grp_prof_client_pop_up">
                                <div id="Pin_lbl_pfp_client_pop_up">
                                    <span>Pin</span>
                                </div>
                                <input placeholder="Pin" tabIndex="12" class="Pin_input_pfp_client_pop_up" value={pin} onChange={(e) => setPin(e.target.value)} />
                            </div>
                            <div id="PAN_prof_client_pop_up">
                                <div id="PAN_lbl_pfp_client_pop_up">
                                    <span>PAN</span>
                                </div>
                                <input placeholder=" PAN" tabIndex="5" class="PAN_input_pfp_client_pop_up" value={pan} onChange={(e) => setPan(e.target.value)} />
                            </div>
                            <div id="GSTIN_grp_client_pop_up">
                                <div id="GSTIN_lbl_pfp_client_pop_up">
                                    <span>GSTIN</span>
                                </div>
                                <input placeholder="GSTIN" tabIndex="4" class="gstin_input_pfp_client_pop_up" value={gstIn} onChange={(e) => setGstIn(e.target.value)} />
                            </div>
                            <div id="information_grp_client_pop_up">
                                <div id="company_lbl_client_pop_uppop_u">
                                    <span>Information</span>
                                </div>
                                <textarea placeholder="Information" tabIndex="13" class="company_inputclient_pop_up_pop" value={information} onChange={(e) => setInformation(e.target.value)} />
                            </div>
                            <div id="active_btn_client_pop_up">
                                <Switch  tabIndex="14"
                                    checked={active}
                                    checkedChildren="Active"
                                    unCheckedChildren="InActive"
                                    value={active}
                                    onChange={(e) => setActive(e)}
                                />
                            </div>
                            <div id="email_grp_prof_client_pop_up">
                                <div id="email_lbl_pfp_client_pop_up">
                                    <span>Email</span>
                                </div>
                                <input placeholder="Email" tabIndex="2" class="email_input_pfp_client_pop_up" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div id="mob_nuimber_grp_prof_client_po">
                                <div id="mob_nu_lbl_pfp_client_pop_up">
                                    <span>Mobile Number</span>
                                </div>
                                <input placeholder="Mobile Number" tabIndex="3" class="MOb_nu_input_pfp_client_pop_up" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                            </div>
                            <div id="freq_lbl_pfp_client_pop_up_">
                                <span>Frequency</span>
                            </div>
                            <select class="freq_input_pfp_client_pop_up_f" tabIndex="6" value={frequency} onChange={(e) => { setPickInput(e.target.value); setFrequency(e.target.value) }}>
                                <option value="0">Select</option>
                                <option value="onetime" >One-Time</option>
                                <option value="monthly">Monthly</option>
                                <option value="quaterly">Quarterly</option>
                                <option value="semiannual">Semi Annual</option>
                                <option value="annual">Annual</option>
                            </select>
                            {pickInput === "onetime" ? "" : <div>
                                <div id="due_on_lbl_pop_up_client">
                                    <span>Day</span>
                                </div>
                                <select class="due_on_input_pop_up_day_client" tabIndex="7" value={day} onChange={(e) => setDay(e.target.value)} >
                                    <rect id="due_on_input_pop_up_day_client" rx="0" ry="0" x="0" y="0" width="66.846" height="33">
                                    </rect>
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                            </div>}
                            {((pickInput === "onetime") || (pickInput === "monthly")) ? "" : <div>
                                <select class="due_on_input_pop_up_client_fre" tabIndex="8" value={month} onChange={(e) => setMonth(e.target.value)}>
                                    <rect id="due_on_input_pop_up_client_fre" rx="0" ry="0" x="0" y="0" width="66.846" height="33">
                                    </rect>
                                    <option value="">Select</option>
                                    <option value="0">Jan</option>
                                    <option value="1">Feb</option>
                                    <option value="2">Mar</option>
                                    <option value="3">Apr</option>
                                    <option value="4">May</option>
                                    <option value="5">Jun</option>
                                    <option value="6">Jul</option>
                                    <option value="7">Aug</option>
                                    <option value="8">Sep</option>
                                    <option value="9">Oct</option>
                                    <option value="10">Nov</option>
                                    <option value="11">Dec</option>
                                </select>
                                <div id="due_on_lbl_pop_up_client_frw">
                                    <span>Month</span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </Modal>

        </>
    )
}
export default AddClient_Popup
