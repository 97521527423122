import React from 'react'
import "../../Styles/Mob/overviewinuse_mob.css"
import OverviewContainer_Mob from './OverviewContainer_Mob'
import { useLocation } from 'react-router-dom';

export const OverviewInUse_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/mydashboard_mob':
                return <OverviewContainer_Mob tab={"InUse"} />;
            default:
                return <OverviewContainer_Mob tab={"InUse"} />;
        }
    }

    return (
        <>
            <div id="stock_mpob_inuse">
                <div id="stock_grp_mob_inuse">
                    <svg class="stock_overview_bdr_lbl_mob_inuse">
                        <rect id="stock_overview_bdr_lbl_mob_inuse" rx="10" ry="10" x="0" y="0" width="100%" height="260">
                        </rect>
                    </svg>
                    <div id="Stock_Overview_lbl_mob_inuse">
                        <span>Stock Overview</span>
                    </div>
                    {
                        renderComponents()
                    }
                </div>
                <div id="dashboard_mob_inuse_grp">
                    <svg class="over_all_secter_bg_mob_inuse">
                        <rect id="over_all_secter_bg_mob_inuse" rx="16" ry="16" x="0" y="0" width="344" height="32">
                        </rect>
                    </svg>
                    <svg class="mark_about_hanger_mob_inuse">
                        <rect id="mark_about_hanger_mob_inuse" rx="16" ry="16" x="0" y="0" width="80" height="32">
                        </rect>
                    </svg>
                    <div id="near_expiry_lbl_purchase_mob_inuse" onClick={() => props.overviewSelectedMobTab("NearExpiry_Mob")}>
                        <span>Near Expiry</span>
                    </div>
                    <div id="in_use__lbl_purchase_mob_inuse">
                        <span>In Use</span>
                    </div>
                    <div id="instock_lbl_purchase_mob_inuse" onClick={() => props.overviewSelectedMobTab("InStock_Mob")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_lbl_purchase_mob_inuse" onClick={() => props.overviewSelectedMobTab("Expired_Mob")}>
                        <span>Expired</span>
                    </div>
                </div>
            </div>


        </>
    )
}
