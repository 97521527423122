import React, { useState, useEffect , useContext } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/Pc/addsalary_popup.css"
import { UserContext } from "../UserContext";

export const AddSalary_Popup = (props) => {
    
    const { user, setUser } = useContext(UserContext);

    const [payments, setPayments] = useState([]);
    const [deductions, setDeductions] = useState([]);
    
    const [userData, setUserData] = useState([]);
    
    const [paymentModesData, setPaymentModesData] = useState([]);

    //fetching staff
    const [staff, setStaff] = useState(UserContext);
    const [staffId, setStaffId] = useState("");
    const [staffData, setStaffData] = useState([]);
    const [staffName, setStaffName] = useState("");
    const [staffNo, setStaffNo] = useState("");

    //add salary
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [finalAmount, setFinalAmount] = useState(0);
    const [status, setStatus] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [paidDate, setPaidDate] = useState("");
    const [modeId, setModeId] = useState("");
    const [modeType, setModeType] = useState("");
    const [modeName, setModeName] = useState("");
    const [accountId, setAccountId] = useState("");


    //edit salary
    const [salaryId, setSalaryId] = useState("");

    useEffect(() => {
        if (user) {
            GetUser()
        }
    }, [user, props.refresh])


    useEffect(() => {
        if (props.display === "Edit") {
            setSalaryId(props.data ? props.data._id : "")
            setStaffNo(props.data ? props.data.staffNo : "")
            setStaffId(props.data ? props.data.staffId : "")
            setStaffName(props.data ? props.data.staffName : "")
            setFromDate(props.data ? new Date(props.data.fromDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.fromDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.fromDate).getDate()).toString().padStart(2, '0') : "")
            setToDate(props.data ? new Date(props.data.toDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.toDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.toDate).getDate()).toString().padStart(2, '0') : "")
            setPayments(props.data.toPay ? JSON.parse(props.data.toPay) || [] : [])
            setDeductions(props.data.toDeduct ? JSON.parse(props.data.toDeduct) || [] : [])
            setFinalAmount(props.data ? props.data.finalAmount : 0)
            setStatus(props.data ? props.data.status : "")
            setApprovedBy(props.data ? props.data.approvedBy : "")
            setDueDate(props.data ? new Date(props.data.dueDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.dueDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.dueDate).getDate()).toString().padStart(2, '0') : "")
            setPaidDate(props.data ? new Date(props.data.paidDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.paidDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.paidDate).getDate()).toString().padStart(2, '0') : "")

            let mode = paymentModesData.filter(e=>e.modeType===props.data.paidMode && e.modeName===props.data.paidName && e.accountId===props.data.paidAccountId)[0]
            if(props.data && mode)
            {
                setModeId(mode._id)
            }
        }
    }, [props.data])

   


    // fetching staff 
    useEffect(() => {
        if (staff) {
            GetStaff();
            GetPaymentModes();
        }
    }, [staff,props.refresh])

    
    useEffect(() => {
       let mode = paymentModesData.filter(e=>e._id===modeId)[0];
       if(mode)
        {
            setModeType(mode.modeType);
            setModeName(mode.modeName);
            setAccountId(mode.accountId);
        }
        else
        {
            setModeType("")
            setModeName("")
            setAccountId("")
        }
    }, [modeId])


    const GetStaff = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/staff/getstaffs";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setStaffData(repos.data)
                    if (repos.data[0]) {
                        setStaffName(repos.data[0].staff);
                        setStaffNo(repos.data[0]._id);
                        setStaffId(repos.data[0].staffId);
                        setDefaultDate(repos.data[0])

                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const GetPaymentModes = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/getmodes";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPaymentModesData(repos.data)
                    if (repos.data[0]) {
                        setModeId(repos.data[0]._id);
                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const setDefaultDate = (rec) => {
        if (rec.frequency === "onetime") {
            const currentDate = new Date();
            const dueDate = currentDate && currentDate.getFullYear().toString() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');
            setDueDate(dueDate);
        } else if (rec.frequency === "monthly") {
            const currentDate = new Date();
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 8) + day;
            setDueDate(dueDate);
        } else {
            const currentDate = new Date();
            const month = rec.month.toString().padStart(2, '0');
            const day = rec.day.toString().padStart(2, '0');
            const dueDate = currentDate.toISOString().slice(0, 5) + month + '-' + day;
            setDueDate(dueDate);
        }
    }

//fetching user
    const GetUser = async () => {

        let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
        let apiParams = {
            method: "GET",

        };

        await fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setUserData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    const addRecord = () => {
        if (validateRecord("add"))
        {
        let apiURL = process.env.REACT_APP_API_URL + "/salary/addsalary";
        let apiParams = {
            method: "POST",
            headers: {
                staffno: staffNo,
                staffName: staffData.filter((rec) => rec._id === staffNo)[0].staffName,
                staffid: staffId,
                fromdate: fromDate,
                todate: toDate,
                topay: JSON.stringify(payments),
                todeduct: JSON.stringify(deductions),
                finalamount: finalAmount,
                status: status,
                approvedby: approvedBy,
                duedate: dueDate,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closeAddSalaryPopup(false) ; clearData()
                    alert("Salary Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }



    const updateRecord = () => {
        if (validateRecord("edit"))
        {
        let apiURL = process.env.REACT_APP_API_URL + "/salary/editsalary";
        let apiParams = {
            method: "POST",
            headers: {
                salaryId: salaryId,
                staffno: staffNo,
                staffName: staffData.filter((rec) => rec._id === staffNo)[0].staffName,
                staffid: staffId,
                fromdate: fromDate,
                todate: toDate,
                topay: JSON.stringify(payments),
                todeduct: JSON.stringify(deductions),
                finalamount: finalAmount,
                status: status,
                approvedby: approvedBy,
                duedate: dueDate,
                paiddate:paidDate,
                paidmode:modeType,
                paidname:modeName,
                paidaccountid:accountId
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closeAddSalaryPopup(false) ; clearData()
                    alert("Salary Updated Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }

    const validateRecord = (actionType) => {

        let validationText = "";
        if(actionType === "edit" && salaryId.trim().length <= 0 )
        {
            validationText+="Salary Id is invalid"+"\n";
        }
        if(staffNo.trim().length <= 0) 
        {
            validationText+="Please enter Employee Id"+"\n";
        }
        if(staffId.trim().length <= 0) 
            {
                validationText+="Please select Name"+"\n";
            }
        if(isNaN(Date.parse(fromDate))) 
        {
            validationText+="Please select From Date"+"\n";
        }
        if(isNaN(Date.parse(toDate))) 
        {
            validationText+="Please select To Date"+"\n";
        }
        if(status.trim().length <= 0) 
            {
                validationText+="Please select Status"+"\n";
            }
            if(approvedBy.trim().length <= 0) 
                {
                    validationText+="Please select Approved By"+"\n";
                }
        if(isNaN(Date.parse(dueDate))) 
        {
            validationText+="Please select Due Date"+"\n";
        }
        if(validationText.trim().length > 0)
            {
    alert(validationText);
    return false;
            }
            else
            {
    return true;
            }
        }
    

    const clearData = ()=>{
        setStaffId("")
        setStaffName("")
        setFromDate("")
        setToDate("")
        setPayments([])
        setDeductions([])
        setFinalAmount(0)
        setStatus("")
        setApprovedBy("")
        setDueDate("")
        setPaidDate("")
        setModeId("")
        setModeType("")
        setModeName("")
        setAccountId("")
    }




    const handleTextChange = (text, index) => {
        var newArray = deductions;
        newArray[index].name = text;

        setDeductions([...newArray])
    }
    const handleAmountChange = (amount, index) => {
        var newArray = deductions;
        newArray[index].amount = amount;

        setDeductions([...newArray])
        setFinalAmount(deductions.reduce((accumulator, currentValue) => {
            return accumulator - parseInt(currentValue.amount)
        },
            payments.reduce((accumulator, currentValue) => {
                return accumulator + parseInt(currentValue.amount1)
            }, 0)))
    }
    const handleDelete = (i) => {
        const deleteVal = [...deductions]
        deleteVal.splice(i, 1)
        setDeductions(deleteVal)
    }


    const handleTextChange1 = (text1, index1) => {
        var newArray1 = payments;
        newArray1[index1].name1 = text1;

        setPayments([...newArray1])
    }
    const handleAmountChange1 = (amount1, index1) => {
        var newArray1 = payments;
        newArray1[index1].amount1 = amount1;

        setPayments([...newArray1])
        setFinalAmount(deductions.reduce((accumulator, currentValue) => {
            return accumulator - parseInt(currentValue.amount)
        },
            payments.reduce((accumulator, currentValue) => {
                return accumulator + parseInt(currentValue.amount1)
            }, 0)))
    }
    const handleDelete1 = (i1) => {
        const deleteVal1 = [...payments]
        deleteVal1.splice(i1, 1)
        setPayments(deleteVal1)
    }



    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closeAddSalaryPopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >



                <div className="addsalary_layout">
                    <div id="pop_up_salay">
                        <img id="pop_up_bg_pop__salary_bg" src="../AddSalary_Popup/pop_up_bg_pop__salary_bg.png" srcset="../AddSalary_Popup/pop_up_bg_pop__salary_bg.png 1x" style={{ height: (payments.length < 3 ? 375 + (20 * (payments.length + 1)) : 435) + (deductions.length < 3 ? 335 + (20 * (deductions.length + 1)) : 420) + 'px' }} />

                        <div id="namepop__salary">
                            <span>Employee ID</span>
                        </div>
                        <input placeholder="Employee ID" autoFocus tabIndex="1" class="name_input_ppop__salary" value={staffId} onChange={(e) => setStaffId(e.target.value)} />

                        <div id="Pin_lblpop__salary" style={{ top: ((payments.length < 3 ? 171 + (22 * (payments.length + 1)) : 263) + (deductions.length < 3 ? 171 + (22 * (deductions.length + 1)) : 302)) + "px" }}>
                            <span>Final Amount</span>
                        </div>
                        <input tabIndex="11" placeholder="Final Amount" class="Pin_ipop__salary" style={{ top: (payments.length < 3 ? 182 + (22 * (payments.length + 1)) : 275) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 315) + "px" }}
                            value={deductions.reduce((accumulator, currentValue) => {
                                return accumulator - parseInt(currentValue.amount)
                            },
                                payments.reduce((accumulator, currentValue) => {
                                    return accumulator + parseInt(currentValue.amount1)
                                }, 0))} />

                        <div id="GSTIN_pop__salary">
                            <span>Name</span>
                        </div>
                        <select class="gstinpop__salary" tabIndex="2"
                            value={staffNo}
                            onChange={(e) => {
                                let stfId = staffData.filter((item) => item._id === e.target.value)[0].staffId;
                                setStaffId(stfId);
                                setStaffNo(e.target.value);
                                props.display === "Add" && setDefaultDate(staffData.filter((item) => item._id === e.target.value)[0])
                            }}
                        >
                            <rect id="tax_auth_innput_pop_up_salary" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {staffData.map((rec) => (
                                <option value={rec._id} >{rec.staffName}</option>
                            ))}
                        </select>

                        <svg onClick={() => {props.closeAddSalaryPopup(false) ; clearData()}} class="Icon_ionic-mpop__salary" viewBox="6.75 6.75 18 18">
                            <path id="Icon_ionic-mpop__salary" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                        <button id="btn_grp_pop_pop__salary" style={{ top: (payments.length < 3 ? 370 + (20 * (payments.length + 1)) : 435) + (deductions.length < 3 ? 300 + (20 * (deductions.length + 1)) : 400) + 'px' }} >
                            <svg class="pop_up_invoice_btn">
                                <rect id="pop_up_invoice_btn" rx="6" ry="6" x="0" y="0" width="167" height="44">
                                </rect>
                            </svg>
                            <div id="Add_Client_pop_up_invoice_salary">
                                <span tabIndex="16" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit Salary" : "Add Salary"}</span>
                            </div>
                        </button>
                        <div id="add_clienpop__salary">
                            <span>{props.display === "Edit" ? "Edit Salary" : "Add Salary"}</span>
                        </div>
                        <div id="project_pop__salary">
                            <span>From Date</span>
                        </div>
                        <input type="date" tabIndex="3" class="project__mob_puppop__salaryf" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />

                        <div id="project__mob_lbpop__salary">
                            <span>To Date</span>
                        </div>
                        <input type="date" tabIndex="4" class="project_mob_pop__salary" value={toDate} onChange={(e) => setToDate(e.target.value)} />

                        <div id="Pin_lbl_pop__salary" style={{ top: (payments.length < 3 ? 171 + (22 * (payments.length + 1)) : 263) + (deductions.length < 3 ? 171 + (22 * (deductions.length + 1)) : 302) + 'px' }}>
                            <span>Status</span>
                        </div>
                        <select tabIndex="12" class="Pin_input_pop__salary" style={{ top: (payments.length < 3 ? 182 + (22 * (payments.length + 1)) : 274) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 314) + 'px' }} value={status} onChange={(e) => setStatus(e.target.value)} >
                            <option value="" >--- Select ---</option>
                            <option value="Draft" >Draft</option>
                            <option value="Approved" >Approved</option>
                            <option value="On Hold" >On Hold</option>
                            <option value="Paid">Paid</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Skipped">Skipped</option>
                        </select>
                        <svg class="Rectangle_491_pop__salary">
                            <rect id="Rectangle_491_pop__salary" rx="5" ry="5" x="0" y="0" width="295" height={payments.length < 3 ? 50 + (40 * payments.length) : 165}>
                            </rect>
                        </svg>
                        <svg class="Rectangle_50" style={{ top: payments.length < 3 ? 283 + (20 * (payments.length + 1)) + 'px' : "393px" }}>
                            <rect id="Rectangle_50" rx="5" ry="5" x="0" y="0" width="295" height={deductions.length < 3 ? 50 + (40 * deductions.length) : 165}>
                            </rect>
                        </svg>
                        <div id="Group_40" style={{ top: payments.length < 3 ? 295 + (20 * (payments.length + 1)) + 'px' : "406px" }}>
                            <div id="address_lbl_pfp">
                                <span>To Deduct ({deductions.reduce((accumulator, currentValue) => {
                                    return accumulator + parseInt(currentValue.amount)
                                }, 0)})</span>
                            </div>
                            <div style={{ height: (40 * deductions.length) + "px", overflowY: "scroll", maxHeight: "115px", top: "30px", position: "relative" }}>
                                {
                                    <ol>
                                        {deductions.map((element, i) => {
                                            return (
                                                <div>
                                                    <input tabIndex="9" onChange={(e) => handleTextChange(e.target.value, i)} placeholder="To Deduct" class="address_input_pfp" value={element.name} type="text" />
                                                    <input tabIndex="10" onChange={(e) => handleAmountChange(e.target.value, i)} placeholder="₹" class="address_input_pfp_bx" value={element.amount} type="number" />
                                                    <img className="minus_1" onClick={() => handleDelete(i)} src="../AddSalary_Popup/minus_1.png" srcset="../AddSalary_Popup/minus_1.png 1x" />

                                                </div>
                                            )
                                        })

                                        }
                                    </ol>
                                }
                            </div>

                            <img tabIndex="8" onClick={() => setDeductions([...deductions, { name: "", amount: 0 }])} id="add-button" src="../AddSalary_Popup/add-button.png" srcset="../AddSalary_Popup/add-button.png 1x" />

                        </div>
                        <div id="Group_39">
                            <div id="office_lbl_pfp_pop_up_invoice">
                                <span>To Pay ({payments.reduce((accumulator, currentValue) => {
                                    return accumulator + parseInt(currentValue.amount1)
                                }, 0)})  </span>
                            </div>
                            <div style={{ height: (40 * payments.length) + "px", overflowY: "scroll", maxHeight: "115px", top: "30px", position: "relative" }}>
                                {
                                    <ol>
                                        {payments.map((element1, i1) => {
                                            return (
                                                <div>
                                                    <input tabIndex="6" onChange={(e) => handleTextChange1(e.target.value, i1)} placeholder="To Pay" class="office_input_pfp_pop_up_invoic" value={element1.name1} type="text" />
                                                    <input tabIndex="7" onChange={(e) => handleAmountChange1(e.target.value, i1)} placeholder="₹" class="address_input_pfp_b" value={element1.amount1} type="number" />
                                                    <img onClick={() => handleDelete1(i1)} className="minus_1_b" src="../AddSalary_Popup/minus_1.png" srcset="../AddSalary_Popup/minus_1.png 1x" />
                                                </div>
                                            )
                                        })
                                        }
                                    </ol>
                                }
                            </div>

                            <img tabIndex="5" onClick={() => setPayments([...payments, { name1: "", amount1: 0 }])} id="add-button_b" src="../AddSalary_Popup/add-button.png" srcset="../AddSalary_Popup/add-button.png 1x" />

                        </div>
                        <div id="approved_by_pop__salary" style={{ top: (payments.length < 3 ? 235 + (22 * (payments.length + 1)) : 318) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 314) + 'px' }}>
                            <span>Approved By</span>
                        </div>
                        <select tabIndex="13" class="approved_by__mob_puppop__salar" style={{ top: (payments.length < 3 ? 258 + (22 * (payments.length + 1)) : 340) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 314) + 'px' }} value={approvedBy} onChange={(e) => setApprovedBy(e.target.value)} >
                            <rect id="tax_auth_innput_pop_up_salary" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {userData.filter(v =>v.userDepartments === "Human Resource" || v.userDepartments === "Directors").map((rec) => (
                                <option value={rec.name} >{rec.name}</option>
                            ))}
                        </select>
                        <div id="overdue_by_pop__salary" style={{ top: (payments.length < 3 ? 235 + (22 * (payments.length + 1)) : 318) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 314) + 'px' }}>
                            <span>Due Date</span>
                        </div>
                        <input tabIndex="14" type="date" class="overdue_by__mob_puppop__salary" style={{ top: (payments.length < 3 ? 258 + (22 * (payments.length + 1)) : 340) + (deductions.length < 3 ? 181 + (22 * (deductions.length + 1)) : 314) + 'px' }} value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                        <div id="paid_through_pop__salary" style={{ top: (payments.length < 3 ? 290 + (22 * (payments.length + 1)) : 350) + (deductions.length < 3 ? 210 + (22 * (deductions.length + 1)) : 355) + 'px' }}>
                            <span>Paid Through</span>
                        </div>
                        <select tabIndex="13" class="paid_through__mob_puppop__salar" style={{ top: (payments.length < 3 ? 300 + (22 * (payments.length + 1)) : 360) + (deductions.length < 3 ? 220 + (22 * (deductions.length + 1)) : 365) + 'px' }} 
                        value={modeId} 
                        onChange={(e) => setModeId(e.target.value)} >
                            <rect id="tax_auth_innput_pop_up_salary" rx="0" ry="0" x="0" y="0" width="157.22" height="40">
                            </rect>
                            <option value="" >--- Select ---</option>
                            {paymentModesData.map((rec) => (
                                <option value={rec._id} >{rec.modeType.toUpperCase()+" - "+rec.modeName}</option>
                            ))}
                        </select>
                        <div id="overdue_by_pop__salary" style={{ top: (payments.length < 3 ? 330 + (22 * (payments.length + 1)) : 395) + (deductions.length < 3 ? 260 + (22 * (deductions.length + 1)) : 380) + 'px' }} >
                            <span>Paid On</span>
                        </div>
                        <input tabIndex="14" type="date" class="overdue_by__mob_puppop__salary" style={{ top: (payments.length < 3 ? 340 + (22 * (payments.length + 1)) : 405) + (deductions.length < 3 ? 270 + (22 * (deductions.length + 1)) : 390) + 'px' }} value={paidDate} onChange={(e) => setPaidDate(e.target.value)} />
                        <div id="approved_by_pop__salary" style={{ top: (payments.length < 3 ? 330 + (22 * (payments.length + 1)) : 395) + (deductions.length < 3 ? 260 + (22 * (deductions.length + 1)) : 380) + 'px' }} >
                            <span>Acc. Id / Name</span>
                        </div>
                        <input placeholder="Acc. Id / Name" autoFocus tabIndex="1" class="accname_input_ppop__salary" value={accountId} onChange={(e) => setAccountId(e.target.value)}  style={{ top: (payments.length < 3 ? 340 + (22 * (payments.length + 1)) : 405) + (deductions.length < 3 ? 270 + (22 * (deductions.length + 1)) : 390) + 'px' }} />

                  </div>
                </div>

            </Modal>
        </>
    )
}

