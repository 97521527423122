import React from 'react'
import "../../Styles/Pc/usercard.css"

export const UserCard = (props) => {

    const { rec } = props;

    return (
        <div id="user_card" onClick={() => { props.setUserPopup("Edit"); props.setSelectedUser(rec) }}>
            <svg class="card_over_all_bg">
                <rect id="card_over_all_bg" rx="10" ry="10" x="0" y="0" width="174" height="191">
                </rect>
            </svg>
            <img id="card_bg_design" src="../User_Main/card_bg_design.png" srcset="../User_Main/card_bg_design.png 1x" />

            {/* <div id="pfp_grp">
                <svg class="pfp_behind_circle">
                    <ellipse id="pfp_behind_circle" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{ fill: rec.active === true ? "#fc8787" : "white" }}>
                    </ellipse>
                </svg>
                <img id="profile_pic_card" src={rec.profilePicUrl ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG + rec.profilePicUrl.split('/')[5] : "../User_Main/profile_pic_card.png"} />

            </div> */}
            <img title="Email" id="user_email_img_lbl" src="../User_Main/email_lbl.png" srcset="../User_Main/email_lbl.png 1x" />

            <div id="user_email_lbl">
                    <span>{rec.email}</span>
            </div>
            <img title="Mobile Number" id="user_phone_img_lbl" src="../User_Main/iphone-5_lbl.png" srcset="../User_Main/iphone-5_lbl.png 1x" />

            <div id="user_phone_lbl">
                <span> {rec.mobile} </span>
            </div>
            <img title="Access" id="manager_lbl" src="../User_Main/manager_lbl.png" srcset="../User_Main/manager_lbl.png 1x" />

            <div id="Admin_lbl">
                    <span>{rec.userDepartments}</span>
            </div>
            <img title="User Role" id="user_access_img_lbl" src="../User_Main/remark_lbl.png" srcset="../User_Main/remark_lbl.png 1x" />

            <div id="user_access_lbl">
                    <span>{rec.userAccess}</span>
            </div>
            <div id="user_name_lbl">
                <span> {rec.name} </span>
            </div>
        </div>
    )
}
