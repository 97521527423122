import React, { useState , useEffect } from "react";
import "../../Styles/Pc/vendors_popup.css"
import Modal from '@mui/material/Modal';
import { Switch } from "antd";

export const Vendors_Popup = (props) => {

    const [vendorFrequency , setVendorFrequency] = useState();


    const [vendorName, setVendorName] = useState("");
    const [accountId, setAccountId] = useState("");
    const [gstIn, setGstIn] = useState("");
    const [frequency, setFrequency] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [remarks, setRemarks] = useState("");
    const [active, setActive] = useState(true);



    //edit vendor
    const [vendorId, setVendorId] = useState("");

    useEffect(() => {
        if (props.display === "Edit") {
            setVendorId(props.data ? props.data._id : "")
            setVendorName(props.data ? props.data.vendorName : "")
            setAccountId(props.data ? props.data.accountId : "")
            setGstIn(props.data ? props.data.gstIn : "")
            setFrequency(props.data ? props.data.frequency : "")
            setDay(props.data ? props.data.day : "")
            setMonth(props.data ? props.data.month : "")
            setRemarks(props.data ? props.data.remarks : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.data])



    const clearData = ()=>{
        setVendorName("")
        setAccountId("")
        setGstIn("")
        setFrequency("")
        setDay("")
        setMonth("")
        setRemarks("")
        setActive(false)
    }





    const addRecord = () => {
        if (validateRecord("add"))
            {

        let apiURL = process.env.REACT_APP_API_URL + "/vendor/addvendor";
        let apiParams = {
            method: "POST",
            headers: {
                vendorname: vendorName,
                accountid: accountId,
                gstin: gstIn,
                frequency: frequency,
                day: day,
                month: month,
                remarks: encodeURIComponent(remarks),
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closeVendorsPopup(true) ; clearData()
                    alert("Vendor Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }



    const updateRecord = () => {

        if (validateRecord("edit"))
            {
        let apiURL = process.env.REACT_APP_API_URL + "/vendor/editvendor";
        let apiParams = {
            method: "POST",
            headers: {
                vendorid: vendorId,
                vendorname: vendorName,
                accountid: accountId,
                gstin: gstIn,
                frequency: frequency,
                day: day,
                month: month,
                remarks: encodeURIComponent(remarks),
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closeVendorsPopup(true) ; clearData()

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }
    
    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && vendorId.trim().length <= 0 )
        {
            validationText+="Vendor Id is invalid"+"\n";
        }
        if(vendorName.trim().length <= 0) 
        {
            validationText+="Please enter Vendor Name"+"\n";
        }
        if(accountId.trim().length <= 0) 
        {
            validationText+="Please enter Account Id"+"\n";
        }
        if(gstIn.trim().length <= 0) 
        {
            validationText+="Please enter GST Number or NA if not available"+"\n";
        }
        if(frequency.trim().length <= 0) 
        {
            validationText+="Please select Frequency"+"\n";
        }
        if(validationText.trim().length > 0)
            {
    alert(validationText);
    return false;
            }
            else
            {
    return true;
            }
        }
    
    

    return (
        <>


            <Modal
                open={props.display !== "none"}
                onClose={() => props.closeVendorsPopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="vendors_layout">
                    <div id="add_vendor_pop_up">
                        <div id="over_all_grp_add_vendor">
                            <svg class="Rectangle_36">
                                <rect id="Rectangle_36" rx="10" ry="10" x="0" y="0" width="230" height="580">
                                </rect>
                            </svg>
                            <div id="vendor_name__grp_prof">
                                <div id="vendor_name__lbl">
                                    <span>Vendors name</span>
                                </div>
                                <input placeholder="Vendor Name" tabIndex="1" autoFocus class="vendor_name__input_" value={vendorName} onChange={(e) => setVendorName(e.target.value)} />
                            </div>
                            <div id="BA_grp_prof">
                                <div id="BA_lbl_pfp">
                                    <span>Account ID</span>
                                </div>
                                <input placeholder="Account ID" tabIndex="2" class="BA_input_pfp" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
                            </div>
                            <div id="due_date_grp">
                                <div id="due_date_lbl_pfp">
                                    <span>GSTIN</span>
                                </div>
                                <input placeholder="GSTIN" tabIndex="3" class="due_date_input_pfp" value={gstIn} onChange={(e) => setGstIn(e.target.value)} />
                            </div>
                            <button id="btn_grp" >
                                <svg class="bg_btn">
                                    <rect id="bg_btn" rx="6" ry="6" x="0" y="0" width="123" height="39">
                                    </rect>
                                </svg>
                                <div id="Add_Vendor_btn">
                                    <span tabIndex="9" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit Vendor" : "Add Vendor"}</span>
                                </div>
                            </button>
                            <svg class="Path_3" viewBox="28 -12.651 230 40.793">
                                <path id="Path_3" d="M 27.99999809265137 12.650146484375 L 27.99999809265137 -6.30859375 C 27.99999809265137 -6.30859375 29.17879295349121 -12.65087890625 37.91382217407227 -12.65087890625 C 46.64884948730469 -12.65087890625 201.3184509277344 -12.65087890625 201.3184509277344 -12.65087890625 L 248.5481109619141 -12.65087890625 C 248.5481109619141 -12.65087890625 257.8034362792969 -11.38037109375 257.9395141601562 -3.409912109375 C 258.0755920410156 4.560546875 257.9395141601562 12.650146484375 257.9395141601562 12.650146484375 L 141.3947448730469 28.14208984375 L 27.99999809265137 12.650146484375 Z">
                                </path>
                            </svg>
                            <div id="Add_Vendor_lbl">
                                <span>{props.display === "Edit" ? "Edit Vendor" : "Add Vendor"}</span>
                            </div>
                            <img onClick={() => { props.closeVendorsPopup(false) ; clearData() }} id="close_pup_op_staff" src="../Vendor_Popup/close_pup_op_staff.png" srcset="../Vendor_Popup/close_pup_op_staff.png 1x" />

                            <div id="due_date_grp_br">
                                <div id="due_date_lbl_pfp_bs">
                                    <span>Frequency</span>
                                </div>
                                <select type="date" tabIndex="4" class="due_date_input_pfp_bt" value={frequency} onChange={(e) => { setVendorFrequency(e.target.value); setFrequency(e.target.value) }} >
                                    <option value="0">Select</option>
                                    <option value="One-Time">One-Time</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Semi-Annua">Semi-Annual</option>
                                    <option value="Annual">Annual</option>
                                </select>
                            </div>
                            
                            <div id="due_date_grp_bx">
                                <div id="due_date_lbl_pfp_by">
                                    <span>Remarks</span>
                                </div>
                                <textarea placeholder="Remarks" tabIndex="7" type="date" class="due_date_input_pfp_bz" value={remarks} onChange={(e) => setRemarks(e.target.value)} >
                                </textarea>
                            </div>
                        </div>
                        <img title="Electricity" id="electricty_img" src="../Vendor_Popup/electricty_img.png" srcset="../Vendor_Popup/electricty_img.png 1x" />

                        <img title="Water Bill" id="water_supply_img" src="../Vendor_Popup/water_supply_img.png" srcset="../Vendor_Popup/water_supply_img.png 1x" />

                        <img title="Wi-Fi Bill" id="wifi_img" src="../Vendor_Popup/wifi_img.png" srcset="../Vendor_Popup/wifi_img.png 1x" />

                        <img title="Phone Bill" id="iphone-5_imgg" src="../Vendor_Popup/iphone-5_imgg.png" srcset="../Vendor_Popup/iphone-5_imgg.png 1x" />

                        <img title="Grocery Bill" id="Grocery_img" src="../Vendor_Popup/Grocery_img.png" srcset="../Vendor_Popup/Grocery_img.png 1x" />

                        <img title="Add Image" id="plus_add" src="../Vendor_Popup/plus_add.png" srcset="../Vendor_Popup/plus_add.png 1x" />

                        <div id="active_grp">
                            <Switch tabIndex="8"
                                checked={active}
                                checkedChildren="Active"
                                unCheckedChildren="InActive"
                                value={active}
                                    onChange={(e) => setActive(e)}
                            />
                        </div>
                        {vendorFrequency==="One-Time" ? "" : <div>
                            <div id="day_lbl_vendoirs">
                                <span>Day</span>
                            </div>
                            <select class="day_input_vendors" tabIndex="5" value={day} onChange={(e) => setDay(e.target.value)}>
                                <rect id="day_input_vendors" rx="0" ry="0" x="0" y="0" width="94.035" height="34.52">
                                </rect>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>}
                        {((vendorFrequency==="One-Time") || (vendorFrequency==="Monthly")) ? "" : <div>
                            <select class="month_input_vendors" tabIndex="6" value={month} onChange={(e) => setMonth(e.target.value)}>
                                <rect id="month_input_vendors" rx="0" ry="0" x="0" y="0" width="94.035" height="34.52">
                                </rect>
                                <option value="">Select</option>
                                <option value="0">Jan</option>
                                <option value="1">Feb</option>
                                <option value="2">Mar</option>
                                <option value="3">Apr</option>
                                <option value="4">May</option>
                                <option value="5">Jun</option>
                                <option value="6">Jul</option>
                                <option value="7">Aug</option>
                                <option value="8">Sep</option>
                                <option value="9">Oct</option>
                                <option value="10">Nov</option>
                                <option value="11">Dec</option>
                            </select>
                            <div id="month_lbl_vendors">
                                <span>Month</span>
                            </div>
                        </div>}
                    </div>
                </div>

            </Modal>


        </>
    )
}
