import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

export const DonutChart2 = (props) => {

  const totalValue = props.data1.reduce((sum, { value }) => sum + value, 0);

  const donutChartData = props.data1.map(({  value, color }) => ({
    
    value,
    color,
    percentage: isNaN((value / totalValue) * 100) ? 0 : ((value / totalValue) * 100).toFixed(0),
  }));

  return (
    <div>
      <PieChart
        data={donutChartData}
        label={({ dataEntry }) => ` ${dataEntry.percentage}%`}
        
        labelStyle={{
         
          fontSize: '7px',
          fontFamily: 'sans-serif',
        }}
        lineWidth={60}
        labelPosition={70}
        animate
        animationDuration={500}
      />
    </div>
  );
};

