import React, { useEffect, useState } from "react";
import "../../Styles/Pc/invoiceMain.css"
import { AddInvoice_Popup } from "./AddInvoice_Popup";
// import { Card_Popup } from "./Card_Popup";

export const InvoiceMain = (props) => {

    // const [cardPopup, setCardPopup] = useState(false)


    return (

        <>

            <div id="small_nav_invoice">
                <img id="n_2188678_4907157" src="over_all_bg_page.png" srcset="over_all_bg_page.png 1x" />

                

            </div>

            
            {/* <Card_Popup openCardPopup={cardPopup} closeCardPopup={(value) => setCardPopup(value)} /> */}

        </>

    )
}




