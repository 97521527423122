import React from "react";
import "../../Styles/Pc/monthly.css"
import { Invoices_Container } from "./Invoices_Container";
import { Salaries_Container } from "./Salaries_Container";
import { Bills_Container } from "./Bills_Container";
import { Tax_Container } from "./Tax_Container";
import { useLocation } from 'react-router-dom';

export const Monthly = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries':
                return <Salaries_Container tab={"Monthly"} refresh={props.refresh} />;
            // case '/purchases':
            //     return <Purchases_Container tab={"Monthly"}/>;
            case '/bills':
                return <Bills_Container tab={"Monthly"} refresh={props.refresh} />;
            case '/taxes':
                return <Tax_Container tab={"Monthly"} refresh={props.refresh} />;
            case '/invoices':
                return <Invoices_Container tab={"Monthly"} refresh={props.refresh} />;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="new_layout_monthly">
                <div id="top_lbls_purchase_grid_grp_monthly">
                    <svg class="new_layout_down_card_1_monthly">
                        <rect id="new_layout_down_card_1_monthly" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
                        </rect>
                    </svg>
                    <div id="heading_grp_new_lay_monthly">
                        <div id="upcoming_expiry_lbl_purchase_g_monthly"  onClick={() => props.changeTab("OverDue")}>
                            <span>Over Due</span>
                        </div>
                        <div id="onetime__lbl_purchase_grid_monthly" onClick={() => props.changeTab("Upcoming")}>
                            <span>Upcoming</span>
                        </div>
                        <div id="monthly_lbl_purchase_grid_monthly">
                            <span>Monthly</span>
                        </div>
                        <div id="quaterly_lbl_purchase_grid_monthly" onClick={() => props.changeTab("Quaterly")}>
                            <span>Quaterly</span>
                        </div>
                        <div id="seni_annual__lbl_purchase_grid_monthly" onClick={() => props.changeTab("SemiAnually")}>
                            <span>Semi Annual </span>
                        </div>
                        <div id="annual_lbl_purchase_grid_monthly" onClick={() => props.changeTab("Anually")}>
                            <span>Annual</span>
                        </div>
                        <svg class="undesrline_monthly" viewBox="0 0 69 1">
                            <path id="undesrline_monthly" d="M 0 0 L 69 0">
                            </path>
                        </svg>
                    </div>
                </div>


                {
                    renderComponents()
                }

            </div>

        </>
    )
}
