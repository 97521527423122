import React from 'react'
import "../../Styles/Mob/company_logo_mob.css"

export const Company_Logo_Mob = () => {
    return (
        <>
            <div id="finance_manager_lbl_grp">
                <div id="Finance_Manager_one_time_task_">
                    <span>finOL</span>
                </div>
                <img id="logo_one_time_task_mob" src="logo.png" srcset="logo.png 1x" />

            </div>
        </>
    )
}
