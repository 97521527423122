import React, { useState } from "react";
import "../../Styles/Pc/purchase_main.css"
import { AddPurchase_Popup } from "./AddPurchase_Popup";
import { Purchase_Instock } from "./Purchase_Instock";
import { Purchase_Inuse } from "./Purchase_Inuse";
import { Purchase_Nearexpiry } from "./Purchase_Nearexpiry";
import { Purchase_Expired } from "./Purchase_Expired";

export const Purchase_Main = (props) => {

    const [purchaseSelectedTab, setPurchaseSelectedTab] = useState("Nearexpiry");


    return (
        <>
            <div id="small_nav_purchase">
                <img id="purchases_page_bg" src="over_all_bg_page.png" srcset="over_all_bg_page.png 1x" />


            </div>


            {purchaseSelectedTab === "Nearexpiry" ? <Purchase_Nearexpiry purchaseSelectedTab={(e) => setPurchaseSelectedTab(e)} refresh={props.refresh} /> : ""}
            {purchaseSelectedTab === "Inuse" ? <Purchase_Inuse purchaseSelectedTab={(e) => setPurchaseSelectedTab(e)} refresh={props.refresh} /> : ""}
            {purchaseSelectedTab === "Instock" ? <Purchase_Instock purchaseSelectedTab={(e) => setPurchaseSelectedTab(e)} refresh={props.refresh} /> : ""}
            {purchaseSelectedTab === "Expired" ? <Purchase_Expired purchaseSelectedTab={(e) => setPurchaseSelectedTab(e)} refresh={props.refresh} /> : ""}


        </>
    )
}