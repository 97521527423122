import React, { useState, useEffect } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import { UserContext } from "../UserContext";

export const Salary_Right_Donutchart = () => {

  //Salary total
  const [salaryChartData, setSalaryChartData] = useState([]);
  const [salaryChart, setChartSalary] = useState(UserContext);
  useEffect((salary) => {
    GetChartSalary()

  }, [salaryChart])


  const GetChartSalary = () => {

    let apiURL = process.env.REACT_APP_API_URL + "/salary/getchartsalaries";
    let apiParams = {
      method: "GET",

    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setSalaryChartData(repos.data)

        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }

  const totalSalaryExpence = salaryChartData.reduce((a, v) => a = a + v.finalAmount, 0)

  const statusPaid = 'Paid';
  const filteredSalaryDataPaid = salaryChartData.filter(v => v.status === statusPaid);
  const totalExpenceStatusPaid = filteredSalaryDataPaid.reduce((a, v) => a + v.finalAmount, 0)
  const percentagePaid = isNaN((totalExpenceStatusPaid / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusPaid / totalSalaryExpence) * 100);

  const statusDraft = 'Draft';
  const filteredSalaryDataDraft = salaryChartData.filter(v => v.status === statusDraft);
  const totalExpenceStatusDraft = filteredSalaryDataDraft.reduce((a, v) => a + v.finalAmount, 0)
  const percentageDraft = isNaN((totalExpenceStatusDraft / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusDraft / totalSalaryExpence) * 100);

  const statusOnHold = 'On Hold';
  const filteredSalaryDataOnHold = salaryChartData.filter(v => v.status === statusOnHold);
  const totalExpenceStatusOnHold = filteredSalaryDataOnHold.reduce((a, v) => a + v.finalAmount, 0)
  const percentageOnHold = isNaN((totalExpenceStatusOnHold / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusOnHold / totalSalaryExpence) * 100);

  const statusApproved = 'Approved';
  const filteredSalaryDataApproved = salaryChartData.filter(v => v.status === statusApproved);
  const totalExpenceStatusApproved = filteredSalaryDataApproved.reduce((a, v) => a + v.finalAmount, 0)
  const percentageApproved = isNaN((totalExpenceStatusApproved / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusApproved / totalSalaryExpence) * 100);

  const statusCancelled = 'Cancelled';
  const filteredSalaryDataCancelled = salaryChartData.filter(v => v.status === statusCancelled);
  const totalExpenceStatusCancelled = filteredSalaryDataCancelled.reduce((a, v) => a + v.finalAmount, 0)
  const percentageCancelled = isNaN((totalExpenceStatusCancelled / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusCancelled / totalSalaryExpence) * 100);

  const statusSkipped = 'Skipped';
  const filteredSalaryDataSkipped = salaryChartData.filter(v => v.status === statusSkipped);
  const totalExpenceStatusSkipped = filteredSalaryDataSkipped.reduce((a, v) => a + v.finalAmount, 0)
  const percentageSkipped = isNaN((totalExpenceStatusSkipped / totalSalaryExpence) * 100) ? 0 : ((totalExpenceStatusSkipped / totalSalaryExpence) * 100);


  const unpaidTotal = totalExpenceStatusApproved + totalExpenceStatusDraft + totalExpenceStatusOnHold



  const data = [
    { value: percentageCancelled, color: 'rgba(153,153,153,1)' },
    { value: percentageDraft, color: '#c088d6' },
    { value: percentagePaid, color: 'rgba(106,169,255,1)' },
    { value: percentageOnHold, color: 'rgba(255,153,0,1)' },
    { value: percentageApproved, color: '#fc8787' },
    { value: percentageSkipped, color: 'rgba(255,0,0,1)' },
  ]

  const totalChartValue = data.reduce((sum, { value }) => sum + value, 0);

  const donutSalaryChartData = data.map(({ value, color }) => ({

    value,
    color,
    percentage: isNaN((value / totalChartValue) * 100) ? 0 : ((value / totalChartValue) * 100).toFixed(0),
  }));

  const centerContent = (
    <g>
      <text
        x="50%"
        y="31%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '15px',
          fontFamily: 'sans-serif',
          fill: 'rgb(224, 52, 52)',
        }}
      >
        Unpaid
      </text>
      <text
        x="50%"
        y="42%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          fill: 'rgb(224, 52, 52)',
        }}
      >
        {unpaidTotal}
      </text>
      <text
        x="50%"
        y="61%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '15px',
          fontFamily: 'sans-serif',
          fill: 'rgb(55, 55, 232)',
        }}
      >
        Paid
      </text>
      <text
        x="50%"
        y="71%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          fill: 'rgb(55, 55, 232)',
        }}
      >
        {totalExpenceStatusPaid}
      </text>
    </g>
  );

  // const svgWidth = 170;
  // const svgHeight = 170;

  return (
    <div>
      {/* <svg width={svgWidth} height={svgHeight}> */}
      <svg>
        <PieChart
          data={donutSalaryChartData}
          label={({ dataEntry }) => ` ${dataEntry.percentage}%`}
          labelStyle={{
            fontSize: '7px',
            fontFamily: 'sans-serif',
          }}
          lineWidth={33}
          labelPosition={83}
          animate
        />
        {centerContent}
      </svg>

    </div>
  );
};
