import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../Styles/Pc/nav.css"
import { UserContext } from '../UserContext';
import { Active_Page } from '../Pc/Active_Page'

export const SmallNav = (props) => {

	const { user, setUser } = useContext(UserContext);

	const { activePage, setActivePage } = useContext(Active_Page);

	const handleIconClick = (page) => {
		setActivePage(page);
	};

	const handlelogout = () => {
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	}

	return (
		<div>

			<div className="slideInRight">
				<div id="small_nav_finance">
					<div id="Group_2">
						<svg onClick={() => props.toggleNav("Enav")} class="side_nav_icon_border" >
							<rect id="side_nav_icon_border" rx="28" ry="28" x="0" y="0" width="112" height="calc(100vh - 100px)">
							</rect>
						</svg>
						<div id="over_all_grp" className="slideInRight" >

							<img onClick={() => props.toggleNav("Enav")} id="side_logo_icon" title={user && user.name}
								src={user.imageId && user.imageId.length > 10
									? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
									user.imageId
									:
									"../NavBar/side_logo_icon.png"
								} />

							<Link to={"/mydashboard"} onClick={() => handleIconClick("Dashboard")} title="Dashboard">
								<div id="dashboard_grp" className={activePage === "Dashboard" ? "active_page" : ""} >
									<img id="dashboard_icon" src="../NavBar/dashboard.png" srcset="../NavBar/dashboard.png 1x" />
								</div>
							</Link>
							{
								(user && (user.userDepartments === "Management") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
								<Link to={"/invoices"} onClick={() => handleIconClick("Invoice")} title="Invoices">
									<div id="logout_grp" className={activePage === "Invoice" ? "active_page" : ""}>
										<img id="logout_icon_I" src="../NavBar/invoice.png" srcset="../NavBar/invoice.png 1x" />
									</div>
								</Link>}
							{
								(user && (user.userDepartments === "Human Resource") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
								<Link to={"/salaries"} onClick={() => handleIconClick("Salary")} title="Salary">
									<div id="salary_grp" className={activePage === "Salary" ? "active_page" : ""}>
										<img id="salary_Icon_I" src="../NavBar/Salaries.png" srcset="../NavBar/Salaries.png 1x" />
									</div>
								</Link>
							}
							{
								(user && (user.userDepartments === "Accounts") || (user.userDepartments === "Administration") || (user.userDepartments === "Directors")) &&
								<Link to={"/bills"} onClick={() => handleIconClick("Bill")} title="Bills">
									<div id="connection_grp" className={activePage === "Bill" ? "active_page" : ""} >
										<img id="my_connections_icon_I" src="../NavBar/bill.png" srcset="../NavBar/bill.png 1x" />
									</div>
								</Link>}
							{
								(user && (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
								<Link to={"/taxes"} onClick={() => handleIconClick("Tax")} title="Taxes">
									<div id="offering_grp" className={activePage === "Tax" ? "active_page" : ""}>
										<img id="offerings_icon_I" src="../NavBar/taxes.png" srcset="../NavBar/taxes.png 1x" />
									</div>
								</Link>}
							{
								(user && (user.userDepartments === "Administration") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors") || (user.userDepartments === "Management")) &&
								<Link to={"/purchases"} onClick={() => handleIconClick("Purchase")} title="Purchase" >
									<div id="com_grp" className={activePage === "Purchase" ? "active_page" : ""}>
										<img id="community_icon_I" src="../NavBar/purchases.png" srcset="../NavBar/purchases.png 1x" />
									</div>
								</Link>}
							<Link to={"/settings"} onClick={() => handleIconClick("Setup")} title="Settings">
								<div id="setup_grp" className={activePage === "Setup" ? "active_page" : ""}>
									<img className="setup_icon" src="../NavBar/management.png" alt="" />
								</div>
							</Link>
							<div id="logout1_grp" onClick={() => handlelogout()} title="Logout">
								<img className="logout_icon_I_" src="../NavBar/logout.png" srcset="../NavBar/logout.png 1x" />
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	)
}