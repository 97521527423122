import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/eabills_mob.css"
import { UserContext } from "../UserContext";

export const EaBills_Mob = (props) => {

    const [billData, setBillData] = useState([]);
    const [bill, setBill] = useState(UserContext);

    useEffect(() => {
        if (props.billData) {
            setBillData(props.billData)
        }
    }, [props.billData])

    return (
        <>
            <div id="Scroll_eexpense_card_bill_mob">
                {billData.map((rec) => {
                    return (
                        <div id="expense_anakytics_grp_bill_mob" style={{ position: "relative", display: "inline-flex", marginTop: "10px", marginLeft: "5px" }}>
                            <svg class="card_1_bdr_expense_analytics_bill_mob">
                                <rect id="card_1_bdr_expense_analytics_bill_mob" rx="7" ry="7" x="0" y="0" width="165" height="44">
                                </rect>
                            </svg>
                            <div id="Mohammed_Affan_lbl_bill_mob">
                                <span>{rec.vendorName}</span>
                            </div>
                            <div id="n_000_lbl_bill_mob">
                                <span>₹ {rec.billAmount}</span>
                            </div>
                            <svg class="status_expense_bill_mob">
                                <ellipse id="status_expense_bill_mob" rx="5" ry="5" cx="5" cy="5" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                </ellipse>
                            </svg>
                            <div id="n_000_lbl_dash_pc_ef_bill_mob">
                                <span>Due: {rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
