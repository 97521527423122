import React from 'react'
import "../../Styles/Pc/overview_inuse.css"
import { Overview_Container } from './Overview_Container'
import { useLocation } from 'react-router-dom';

export const Overview_InUse = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/mydashboard':
                return <Overview_Container tab={"InUse"} />;
            default:
                return <Overview_Container tab={"InUse"} />;
        }
    }

    return (
        <>
            <div>
                <div id="tabs_heading_dashboard_pv_grp_iu">
                    <svg class="over_all_secter_bg_dash_pc_iu">
                        <rect id="over_all_secter_bg_dash_pc_iu" rx="16" ry="16" x="0" y="0" width="384" height="32">
                        </rect>
                    </svg>
                    <svg class="mark_about_hanger_dash_pc_inuse">
                        <rect id="mark_about_hanger_dash_pc_inuse" rx="16" ry="16" x="0" y="0" width="103" height="32">
                        </rect>
                    </svg>
                    <div id="near_expiry_lbl_purchase_grid_iu" onClick={() => props.overviewSelectedTab("NearExpiry")}>
                        <span>Near Expiry</span>
                    </div>
                    <div id="in_use__lbl_purchase_grid_dash_iu">
                        <span>In Use</span>
                    </div>
                    <div id="instock_lbl_purchase_grid_dash_iu" onClick={() => props.overviewSelectedTab("InStock")}>
                        <span>In Stock</span>
                    </div>
                    <div id="expired_lbl_purchase_grid_dash_iu" onClick={() => props.overviewSelectedTab("Expired")}>
                        <span>Expired</span>
                    </div>
                </div>

                <svg class="stock_overview_bdr_lbl_dashboa_iu">
                    <rect id="stock_overview_bdr_lbl_dashboa_iu" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>

                <div id="Stock_Overview_lbl_dashboard_p_iu">
                    <span>Stock Overview</span>
                </div>

                {
                    renderComponents()
                }
            </div>
        </>
    )
}
