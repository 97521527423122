import React, { useState } from 'react'
import { OverDue_Mob } from './OverDue_Mob'
import { Upcoming_Mob } from './Upcoming_Mob'
import { Monthly_Mob } from './Monthly_Mob'
import { Quaterly_Mob } from './Quaterly_Mob'
import { SemiAnually_Mob } from './SemiAnually_Mob'
import { Anually_Mob } from './Anually_Mob'

export const MainComponent_Mob = (props) => {

    const [selectedTabMob , setSelectedTabMob] = useState("OverDueMob")

  return (
    <>
        {selectedTabMob === "OverDueMob" ? <OverDue_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
        {selectedTabMob === "UpcomingMob" ? <Upcoming_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
        {selectedTabMob === "MonthlyMob" ? <Monthly_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
        {selectedTabMob === "QuaterlyMob" ? <Quaterly_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
        {selectedTabMob === "SemiAnuallyMob" ? <SemiAnually_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
        {selectedTabMob === "AnuallyMob" ? <Anually_Mob changeTabMob={(e)=>setSelectedTabMob(e)} refresh={props.refresh} /> : ""}
    </>
  )
}
