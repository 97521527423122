import React, { useState, useEffect } from "react";
import "../../Styles/Pc/staff_popup.css"
import Modal from '@mui/material/Modal';
import { Switch } from "antd";

export const Staff_Popup = (props) => {

    const [pickInput, setPickInput] = useState("");

    const [staffName, setStaffName] = useState("");
    const [staffId, setStaffId] = useState("");
    const [dob, setDob] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [staffType, setStaffType] = useState("");
    const [frequency, setFrequency] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [active, setActive] = useState(true);



    //edit staff
    const [editStaffId, setEditStaffId] = useState("");

    useEffect(() => {
        if (props.display === "Edit") {
            setEditStaffId(props.data ? props.data._id : "")
            setStaffName(props.data ? props.data.staffName : "")
            setStaffId(props.data ? props.data.staffId : "")
            setDob(props.data ? new Date(props.data.dob).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.dob).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.dob).getDate()).toString().padStart(2, '0') : "")
            setJoiningDate(props.data ? new Date(props.data.joiningDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.joiningDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.joiningDate).getDate()).toString().padStart(2, '0') : "")
            setPanNumber(props.data ? props.data.panNumber : "")
            setStaffType(props.data ? props.data.staffType : "")
            setFrequency(props.data ? props.data.frequency : "")
            setDay(props.data ? props.data.day : "")
            setMonth(props.data ? props.data.month : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.data])


    const clearData = ()=>{
        setStaffName("")
        setStaffId("")
        setDob("")
        setJoiningDate("")
        setPanNumber("")
        setStaffType("")
        setFrequency("")
        setDay("")
        setMonth("")
        setActive(false)
    }




    const addRecord = () => {

        if (validateRecord("add"))
        {
        let apiURL = process.env.REACT_APP_API_URL + "/staff/addstaff";
        let apiParams = {
            method: "POST",
            headers: {
                staffname: staffName,
                staffid: staffId,
                dob: dob,
                joiningdate: joiningDate,
                pannumber: panNumber,
                stafftype: staffType,
                frequency: frequency,
                day: day,
                month: month,
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup(true) ; clearData()
                    alert("Staff Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }



    const updateRecord = () => {

        if (validateRecord("update"))
        {
        let apiURL = process.env.REACT_APP_API_URL + "/staff/editstaff";
        let apiParams = {
            method: "POST",
            headers: {
                editstaffid: editStaffId,
                staffname: staffName,
                staffid: staffId,
                dob: dob,
                joiningdate: joiningDate,
                pannumber: panNumber,
                stafftype: staffType,
                frequency: frequency,
                day: day,
                month: month,
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup(true) ; clearData()
                    

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }

    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && editStaffId.trim().length <= 0 )
        {
            validationText+="Staff Id is invalid"+"\n";
        }
        if(staffName.trim().length <= 0) 
        {
            validationText+="Please enter Staff Name"+"\n";
        }
        if(staffId.trim().length <= 0) 
        {
            validationText+="Please enter Staff Id"+"\n";
        }
        if(isNaN(Date.parse(dob))) 
        {
            validationText+="Please select Date of Birth"+"\n";
        }
        if(isNaN(Date.parse(joiningDate))) 
        {
            validationText+="Please select Joining Date"+"\n";
        }
        if(panNumber.trim().length <= 0) 
        {
            validationText+="Please enter PAN Number or NA if not available"+"\n";
        }
        if(staffType.trim().length <= 0) 
        {
            validationText+="Please select Staff Type"+"\n";
        }
        if(frequency.trim().length <= 0) 
        {
            validationText+="Please select Frequency"+"\n";
        }
        if(validationText.trim().length > 0)
        {
            alert(validationText);
            return false;
        }
        else
        {
            return true;
        }
    }
    

    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="staff_layout">
                    <div id="pop_up_add_staff">
                        <img id="Add_staff_popup_bg_over_all" src="../Staff_Popup/Add_staff_popup_bg_over_all.png" srcset="../Staff_Popup/Add_staff_popup_bg_over_all.png 1x" />


                        <div id="staff_id_lbl_Add_staff_popup">
                            <span>Staff ID</span>
                        </div>
                        <input placeholder="Staff ID" tabIndex="2" class="staff_ID_Add_staff_popup" value={staffId} onChange={(e) => setStaffId(e.target.value)} />
                        <div id="staff_name_lbl_Add_staff_popup">
                            <span>Staff Name</span>
                        </div>
                        <input placeholder="Staff Name" tabIndex="1" autoFocus class="staff_name_Add_staff_popup" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
                        <svg onClick={() => {props.closePopup(false) ; clearData()}} class="Icon_ionic-mpop__Add_staff_pop" viewBox="6.75 6.75 18 18">
                            <path id="Icon_ionic-mpop__Add_staff_pop" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                        <button id="btn_grp_pop_pop__Add_staff_pop" >
                            <svg class="Add_staff_popup_btn">
                                <rect id="Add_staff_popup_btn" rx="6" ry="6" x="0" y="0" width="167" height="44">
                                </rect>
                            </svg>
                            <div id="Add_staff_popup_invoice">
                                <span tabIndex="11" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit Staff" : "Add Staff"}</span>
                            </div>
                        </button>
                        <div id="add_staff_Add_staff_popup">
                            <span>{props.display === "Edit" ? "Edit Staff" : "Add Staff"}</span>
                        </div>
                        <div id="joining_date_lbl_Add_staff_pop">
                            <span>Joining Date</span>
                        </div>
                        <input type="date" tabIndex="4" class="joining_date_Add_staff_popup" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
                        <div id="DOB_lbl_Add_staff_popup">
                            <span>DOB</span>
                        </div>
                        <input type="date" tabIndex="3" class="DOB_input_Add_staff_popup" value={dob} onChange={(e) => setDob(e.target.value)} />
                        <div id="Pan_Input_Add_staff_popup">
                            <span>PAN No.</span>
                        </div>
                        <input placeholder="PAN Number" tabIndex="5" class="Pan_input_Add_staff_popup" value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                        <div class="active_box_Add_staff_popup">
                            <Switch tabIndex="10"
                                checked={active}
                                checkedChildren="Active"
                                unCheckedChildren="InActive"
                                value={active}
                                onChange={(e) => setActive(e)}
                            />
                        </div>
                        <select class="staff_type_input_Add_staff_pop" tabIndex="6" value={staffType} onChange={(e) => setStaffType(e.target.value)} >
                            <option value="">Select</option>
                            <option value="Employee" >Employee</option>
                            <option value="Consultant">Consultant</option>
                        </select>
                        <div id="staff_type_lbl_Add_staff_popup">
                            <span>Staff Type</span>
                        </div>
                        <div id="fre_lbl_pop_up_frequecy">
                            <span>Frequency</span>
                        </div>
                        <select placeholder="Frequency" tabIndex="7" class="freq_input_pop_up_staff" value={frequency} onChange={(e) => { setPickInput(e.target.value); setFrequency(e.target.value) }}>
                            <option value="0">Select</option>
                            <option value="onetime" >One-Time</option>
                            <option value="monthly">Monthly</option>
                            <option value="quaterly">Quarterly</option>
                            <option value="semiannual">Semi Annual</option>
                            <option value="annual">Annual</option>
                        </select>
                        {pickInput === "onetime" ? "" : <div>
                            <div id="due_on_lbl_pop_up_freq">
                                <span>Day</span>
                            </div>
                            <select class="due_on_input_pop_up_day" tabIndex="8" value={day} onChange={(e) => setDay(e.target.value)}>
                                <rect id="due_on_input_pop_up_day" rx="0" ry="0" x="0" y="0" width="66.846" height="40">
                                </rect>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>}
                        {((pickInput === "onetime") || (pickInput === "monthly")) ? "" : <div>
                            <select class="due_on_input_pop_up_staff" tabIndex="9" value={month} onChange={(e) => setMonth(e.target.value)}>
                                <rect id="due_on_input_pop_up_staff" rx="0" ry="0" x="0" y="0" width="66.846" height="40">
                                </rect>
                                <option value="">Select</option>
                                <option value="0">Jan</option>
                                <option value="1">Feb</option>
                                <option value="2">Mar</option>
                                <option value="3">Apr</option>
                                <option value="4">May</option>
                                <option value="5">Jun</option>
                                <option value="6">Jul</option>
                                <option value="7">Aug</option>
                                <option value="8">Sep</option>
                                <option value="9">Oct</option>
                                <option value="10">Nov</option>
                                <option value="11">Dec</option>
                            </select>
                            <div id="due_on_lbl_pop_up_staff">
                                <span>Month</span>
                            </div>
                        </div>}
                    </div>
                </div>

            </Modal>

        </>
    )
}


