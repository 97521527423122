import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/taxcontainer_mob.css"
import { UserContext } from "../UserContext";
import { AddTax_Popup } from '../Pc/AddTax_Popup';

export const TaxContainer_Mob = (props) => {

    const [taxData, setTaxData] = useState([]);
    const [tax, setTax] = useState(UserContext);
    const [addTaxPopup, setAddTaxPopup] = useState("none");
    const [selectedTax, setSelectedTax] = useState({});

    useEffect(() => {
        GetTax(props.tab)

    }, [addTaxPopup])


    const GetTax = (tab) => {

        let apiURL = process.env.REACT_APP_API_URL + "/tax/gettaxes/" + tab;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setTaxData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <>
            <div id="tax_page_mob">

                <div id="over_all_product_page_tax">
                    <div id="Scroll_salary_mob_tax">
                        {taxData.map((rec) => {
                            return (
                                <div id="card_1_tax_page_mob" style={{ position: "relative", display: "inline-flex", margin: "05px", marginTop: "10px" }} onClick={() => { setAddTaxPopup("Edit"); setSelectedTax(rec) }}>
                                    <div id="com_req_grp_card_1_tax">
                                        <svg class="com_req_card_1_tax">
                                            <rect id="com_req_card_1_tax" rx="10" ry="10" x="0" y="0" width="156" height="150">
                                            </rect>
                                        </svg>
                                        <svg class="Rectangle_63_tax" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                            <rect id="Rectangle_63_tax" rx="9" ry="9" x="0" y="0" width="156" height="30">
                                            </rect>
                                        </svg>
                                        <div id="name_tax_upcoming_page">
                                            <span>{rec.taxAuthority}</span>
                                        </div>
                                        <img id="due-date_tax" src="due-date.png" srcset="due-date.png 1x" />

                                        <div id="from_date_tax_upcoming_page">
                                            <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                        </div>
                                        <img id="salary_tax" src="salary.png" srcset="salary.png 1x" />

                                        <div id="to_date_upcoming_page_tax">
                                            <span>{rec.taxAmount}</span>
                                        </div>
                                        <div id="to_date_upcoming_page_dl_tax">
                                            <span>Due in {parseInt((new Date(rec.dueDate) - new Date()) / 86400000)} Days</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div id="tax_btn_grp_mob">
                        <div id="add_btn_tax_mob" onClick={() => setAddTaxPopup("Add")}>
                            <svg class="add_bg_Add_tax_mob">
                                <rect id="add_bg_Add_tax_mob" rx="8" ry="8" x="0" y="0" width="26" height="26">
                                </rect>
                            </svg>
                            <svg class="Add_Tax_tax_mob" viewBox="6.75 6.75 18 18">
                                <path id="Add_Tax_tax_mob" d="M 6.75 16.94998168945312 L 14.55002021789551 16.94998168945312 L 14.55002021789551 24.75000190734863 L 16.94998168945312 24.75000190734863 L 16.94998168945312 16.94998168945312 L 24.75000190734863 16.94998168945312 L 24.75000190734863 14.55002021789551 L 16.94998359680176 14.55002021789551 L 16.94998359680176 6.75 L 14.55002021789551 6.75 L 14.55002021789551 14.55001831054688 L 6.75 14.55001831054688 L 6.75 16.94998168945312 Z">
                                </path>
                            </svg>
                        </div>
                        <div id="tax_mob_lbl_tax_page_pc">
                            <span>Taxes</span>
                        </div>
                    </div>

                    <AddTax_Popup display={addTaxPopup} data={selectedTax} closeAddTaxPopup={() => setAddTaxPopup("none")} refresh={props.refresh} />

                </div>
            </div>
        </>
    )
}
