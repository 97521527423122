import React, { useEffect, useState, useContext } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/Pc/user_popup.css"
import { Switch } from "antd";
import { Image_Loader } from "./Image_Loader";
import { UserContext } from "../UserContext";

export const AddPaymentMode_Popup = (props) => {

    const { user, setUser } = useContext(UserContext);

    const [modeId, setModeId] = useState("");
    const [modeType, setModeType] = useState("");
    const [modeName, setModeName] = useState("");
    const [accountId, setAccountId] = useState("");
    const [remarks, setRemarks] = useState("");
    const [active, setActive] = useState(true);
        const [profilePic, setProfilePic] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [uploadProfile, setUploadProfile] = useState("");

    const [loading, setLoading] = useState(false);


    useEffect(() => {

    }, [profilePic , user])



    useEffect(() => {
        if (props.display === "Edit") {
            setModeId(props.data ? props.data._id : "")
            setModeType(props.data ? props.data.modeType : "")
            setModeName(props.data ? props.data.modeName : "")
            setAccountId(props.data ? props.data.accountId : "")
            setRemarks(props.data ? props.data.remarks : "")
            setProfileImage(props.data ? props.data.profilePicUrl : "")
            setActive(props.data ? props.data.active : "")
        }
    }, [props.data])


    const clearData = () => {
        setModeType("")
        setModeName("")
        setAccountId("")
        setRemarks("")
        setProfileImage("")
        setProfilePic("")
        setUploadProfile("")
        setActive(true)
    }

    const handleClose = () => {
        clearData();
        props.closePaymentModePopup(false);
    }



    const addRecord = () => {
        if (validateRecord("add"))
            {
        setLoading(true);
        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/addmode";
        let apiParams = {
            method: "POST",
            headers: {
                modetype: modeType,
                modename: modeName,
                accountid: accountId,
                remarks: encodeURIComponent(remarks),
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    if (uploadProfile) {
                        uploadProfile && UploadToGoogleDrive(repos.data, uploadProfile);
                    } else {
                        setLoading(false);
                        alert("Action Updated Successfully!!");
                        setLoading(false);
                        handleClose();
                    }

                } else alert(repos.message || repos);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false); // Stop loader
                alert(err);
            });}
            else
            {
                setLoading(false);
                return;
            }
    }


    const updateRecord = () => {
        if (validateRecord("edit"))
            {
        setLoading(true);
        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/editmode";
        let apiParams = {
            method: "POST",
            headers: {
                modeid: modeId,
                modetype: modeType,
                modename: modeName,
                accountid: accountId,
                remarks: encodeURIComponent(remarks),
                active: active
            },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    if (uploadProfile) {
                        uploadProfile && UploadToGoogleDrive(repos.data._id, uploadProfile);
                    } else {
                        setLoading(false);
                        handleClose();
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => {
                setLoading(false); // Stop loader
                alert(err);
            });}
            else
            {
                return;
            }
    }


    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && modeId.trim().length <= 0 )
        {
            validationText+="Payment Mode Id is invalid"+"\n";
        } 
        if(modeType.trim().length <= 0) 
            {
                validationText+="Please select Payment Mode Type"+"\n";
            }
        if(modeName.trim().length <= 0) 
            {
                validationText+="Please enter Name of Entity"+"\n";
            }
        if(validationText.trim().length > 0)
            {
    alert(validationText);
    return false;
            }
            else
            {
    return true;
            }
        }


    const UploadToGoogleDrive = (id, e) => {
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = async (e) => { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                await fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {
                        SaveProfilePic(data, id)
                    }).catch(e => console.log(e)) // Or Error in console
            }
        }
    }
    const SaveProfilePic = async (data, id) => {

        const apiurl = process.env.REACT_APP_API_URL + "/users/adduserprofilepic/" + id;
        let apiParams = {
            method: "POST",
            headers: {
                profilepicurl: data.url,
            }
        }

        await fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setProfilePic(data.url); // Update profile picture state

                    setLoading(false);
                    alert("Action Updated Successfully!!");
                    handleClose()

                }
            })
            .catch((e) => console.log(e));
    }



    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProfilePic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            // console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            //UploadToGoogleDrive(e);
            setUploadProfile(e)
        }


    }


    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closePaymentModePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="user_popup_layout">

                 
                    <div id="setup_pop_up">
                        <img id="pop_up_user_bg" src="../User_Popup/pop_up_user_bg.png" srcset="../User_Popup/pop_up_user_bg.png 1x" />

                        {/* <svg class="pfp_behind_circle_popup">
                            <ellipse id="pfp_behind_circle_popup" rx="50" ry="50" cx="50" cy="50" style={{ fill: active === true ? "#fc8787" : "white" }}>
                            </ellipse>
                        </svg>
                        {loading ? <div className='loader_layout'><Image_Loader /></div> :
                            <label htmlFor="imageProfilepc">
                                <input
                                    tabIndex={9}
                                    id="imageProfilepc"
                                    type="file"
                                    name="imageProfilepc"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => profilePicUpload(e)}
                                />

                                {profilePic.length === 0 ? (
                                    <img
                                        id="profile_pfp_img"
                                        width="100"
                                        height="100"
                                        src={
                                            profileImage && profileImage.length
                                                ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
                                                profileImage.split("/")[5]
                                                : "../User_Popup/profile_pfp_img.png"
                                        }
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                        alt="Profile Picture"
                                    />
                                ) : (
                                    <img
                                        id="profile_pfp_img"
                                        width="100"
                                        height="100"
                                        src={profilePic ? profilePic : "../User_Popup/profile_pfp_img.png"}
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                        alt="Profile Picture"
                                    />
                                )}

                            </label>} */}

                        <div id="state_grp_prof">
                            <div id="state_lbl_pfp">
                                <span>Payment Mode Type</span>
                            </div>
                            <select className="state_input_pfp" tabIndex="4" value={modeType} onChange={(e) => setModeType(e.target.value)} >
                                <option value="">--Select--</option>
                                <option value="bank">Bank Account Transfer</option>
                                <option value="upi">UPI App Transfer</option>
                                <option value="card">Credit / Debit Card</option>
                                <option value="cheque">Demand Draft / Cheque</option>
                                <option value="cash">Cash</option>
                            </select>
                        </div>

                        <div id="company_grp">
                            <div id="company_lbl_pfp">
                                <span>Name of Entity</span>
                            </div>
                            <input placeholder="Name of Entity" style={{width:"397px"}} tabIndex="1" autoFocus class="company_input_pfp" value={modeName} onChange={(e) => setModeName(e.target.value)} />
                        </div>
                        {/* <div id="city_grp_prof">
                            <div id="city_lbl_pfp">
                                <span>Email</span>
                            </div>
                            <input placeholder="Email" tabIndex="3" class="city_input_pfp" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div> */}
                        <div id="office_grp">
                            <div id="office_lbl_pfp">
                                <span>Account Id</span>
                            </div>
                            <input placeholder="Unique Id" style={{width:"397px"}} tabIndex="2" class="office_input_pfp" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
                        </div>
                        <div id="latitiude_grp">
                            <div id="latitude_lbl">
                                <span>Remarks</span>
                            </div>
                            <textarea placeholder="Remarks" tabIndex="6" class="latitude_input" value={remarks} onChange={(e) => setRemarks(e.target.value)} >
                                <rect id="latitude_input" rx="0" ry="0" x="0" y="0" width="397.576" height="84">
                                </rect>
                            </textarea>
                        </div>
                        <div id="active_lbl_popup"  >
                            <Switch
                                checked={active}
                                checkedChildren="Active"
                                unCheckedChildren="InActive"
                                value={active}
                                onChange={(e) => setActive(e)}
                            />
                        </div>
                        {((user &&user.userAccess === "Full Access") || (user &&user.userAccess === "Edit")) &&
                            <button id="change_pass_btn_grp" tabIndex="7" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>
                                <svg class="passs_bdr_btn">
                                    <rect id="passs_bdr_btn" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                    </rect>
                                </svg>
                                <div id="add_lbl">
                                    <span>{props.display === "Edit" ? "Edit" : "Add"}</span>
                                </div>
                            </button>}
                        <img tabIndex="8" onClick={() => { props.closePaymentModePopup(false); clearData() }} id="close_userpopup" src="../User_Popup/close.png" srcset="../User_Popup/close.png 1x" />

                        {/* <div id="office_grp_bz">
                            <div id="office_lbl_pfp_b">
                                <span>Access</span>
                            </div>
                            <select name="access" tabIndex="5" id="" className="office_input_pfp_b" value={userAccess} onChange={(e) => setUserAccess(e.target.value)} >
                                <option value="0">--Select--</option>
                                <option value="Read Only">Read Only</option>
                                <option value="Edit">Edit</option>
                                <option value="Full Access">Full Access</option>
                            </select>
                        </div> */}
                    </div>
                </div>

            </Modal>

        </>
    )
}