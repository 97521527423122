import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../../Styles/Pc/Login.css"
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { getElementAtEvent } from "react-chartjs-2";

export const Login = () => {
    
    var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('');

    let handleSubmit = (e) => {
        console.log(email);
        console.log(password);
        e.preventDefault();
    }

    let handleEmail = (e) => {
        SetEmail(e.target.value)
    }

    let handlePass = (e) => {
        SetPassword(e.target.value)
    }





    const redirect = () => {

        if (email.length === 0) {
            return alert("Please Enter Email!!");
        }
        if (password.length === 0) {
            return alert("Please Enter Password!!");
        }
        const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
        var apiParam = {
            method: 'POST',
            headers: {
                email: email,
                password: password
            },
            credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    localStorage.setItem("loggedintoken", repos.data);
                    localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
                    setUser(JSON.parse(atob(repos.data.split(".")[1])))
                    navigate("/mydashboard");
                } else 
            {
                alert(JSON.stringify(repos));
            };
            })
            .catch((err) => alert(JSON.stringify(err)));
    };



    return (
        <div>
            <div id="pc_login__2">
                <img id="n_9962869_6204361" src="" srcset="login_try.jpg 1x" />

                <img id="Group_1" src="" srcset="Group_1.png 1x" />

                {/* </svg> */}
                <div id="mid_overall">
                    <svg class="bg_dummy">
                        <rect id="bg_dummy" rx="17" ry="17" x="0" y="0" width="392" height="413">
                        </rect>
                    </svg>
                    <img id="logo" src="" srcset="logo.png 1x" />

                    <div id="Expense_Manager">
                        <span>finOL</span>
                    </div>
                    <button id="btn" onClick={() => redirect()} tabIndex="3" >
                        <svg class="Rectangle_3_p">
                            <linearGradient id="Rectangle_3_p" spreadMethod="pad" x1="0.5" x2="0.5" y1="1" y2="-0.936">
                                <stop offset="0" stop-color="rgba(252,135,135,1)" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="rgba(235,56,61,1)" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="Rectangle_3_p" rx="6" ry="6" x="0" y="0" width="305" height="45">
                            </rect>
                        </svg>
                        <div id="Sign_in">
                            <span >Sign in</span>
                        </div>
                    </button>
                    <input placeholder="Email" class="username" value={email} onChange={handleEmail} type="email" tabIndex="1" autoFocus />

                    <input placeholder="Password" class="password" value={password} onChange={handlePass} type="password" tabIndex="2" />

                    <Link to={"/forgotpassword"}  tabIndex="4" >
                        <div id="Forgot_Password1">
                            <span >Forgot Password</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    )
}