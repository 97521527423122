import React, { useContext } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { UserContext } from "../UserContext";

export const DonutChart1 = (props) => {

  const { user, setUser } = useContext(UserContext);

  const totalValue = props.data.reduce((sum, { value }) => sum + value, 0);

  const donutChartData = props.data.map(({ value, color }) => ({

    value,
    color,
    percentage: isNaN((value / totalValue) * 100) ? 0 : ((value / totalValue) * 100).toFixed(0),
  }));

  return (
    <>
      <div>
        <PieChart
          data={donutChartData}
          label={({ dataEntry }) => ` ${dataEntry.percentage}%`}

          labelStyle={{

            fontSize: '7px',
            fontFamily: 'sans-serif',
          }}
          lineWidth={60}
          labelPosition={70}
          animate
          animationDuration={500}
        />
      </div>
    </>
  );
};

