import React, { useState, useContext } from 'react'
import "../../Styles/Mob/login_mob.css"
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

export const Login_Mob = () => {

    var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('');

    let handleSubmit = (e) => {
        console.log(email);
        console.log(password);
        e.preventDefault();
    }

    let handleEmail = (e) => {
        SetEmail(e.target.value)
    }

    let handlePass = (e) => {
        SetPassword(e.target.value)
    }





    const redirect = () => {

        if (email.length === 0) {
            return alert("Please Enter Email!!");
        }
        if (password.length === 0) {
            return alert("Please Enter Password!!");
        }
        const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
        var apiParam = {
            method: 'POST',
            headers: {
                email: email,
                password: password
            },
            credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    localStorage.setItem("loggedintoken", repos.data);
                    localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
                    setUser(JSON.parse(atob(repos.data.split(".")[1])))
                    navigate("/mydashboard_mob");
                } else alert(JSON.stringify(repos));
            })
            .catch((err) => alert(JSON.stringify(err)));
    };


    return (
        <>
            <div id="mob_login__1">
                <div id="Finance_Manager_lbl_">
                    <span>finOL</span>
                </div>
            <img id="n_6388521_7199773" src="" srcset="login_try.jpg 1x" />

                <div id="mid_overall_grp_mob">
                    <svg class="sign_in_brn_dj"  onClick={() => redirect()}>
                        <linearGradient id="sign_in_brn_dj" spreadMethod="pad" x1="0.5" x2="0.5" y1="1" y2="-0.936">
                            <stop offset="0" stop-color="#22cf22" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#39f" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="sign_in_brn_dj" rx="6" ry="6" x="0" y="0" width="100%" height="45">
                        </rect>
                    </svg>
                    <div id="Sign_in_lbl"  onClick={() => redirect()}>
                        <span>Sign in</span>
                    </div>
                    <input placeholder="Email" class="username_box_input"  value={email} onChange={handleEmail} type="email" />
                    <input placeholder="Password" class="password_box_input"  value={password} onChange={handlePass} type="password" />
                    <div id="Forgot_Password_lbl_mob">
                        <span>Forgot Password</span>
                    </div>
                </div>

                
            </div>

        </>
    )
}


