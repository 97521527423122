import React, { useState, useEffect } from "react";
import "../../Styles/Pc/purchase_right_component.css"
import { Merchants_Popup } from "./merchants_Popup";
import { UserContext } from "../UserContext";
import { Purchase_Right_Donutchart } from "./Purchase_Right_Donutchart";

export const Purchase_Right_Component = (props) => {


    //merchant Fetching
    const [merchant, setMerchant] = useState(UserContext);
    const [merchantData, setMerchantData] = useState([]);
    const [merchantPopup, setMerchantPopup] = useState("none");
    const [selectedMerchant, setSelectedMerchant] = useState({})

    const [searchText, setSearchText] = useState("");

    //fetching  merchant
    useEffect(() => {

        if (merchant) {
            GetMerchants()
        }
    }, [merchantPopup])


    const GetMerchants = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/merchant/getmerchants";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setMerchantData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    return (
        <>
            <div>
                <div id="add_btn_purchase_right" onClick={() => setMerchantPopup("Add")} >
                    <svg class="add_bg_Add_purchase_right">
                        <rect id="add_bg_Add_purchase_right" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_purchase_right" viewBox="6.75 6.75 18 18">
                        <path id="Add_purchase_right" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="scroll_left_component_pruchase_right">
                    {merchantData.filter(rec => rec.name.toLowerCase().includes(searchText.toLowerCase()) || rec.gstIn.toLowerCase().includes(searchText.toLowerCase()) || rec.state.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                        return (
                            <div id="tax_authority_card_1_purchase_right" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setMerchantPopup("Edit"); setSelectedMerchant(rec) }}>
                                <svg class="card_1_bdr_purchase_right">
                                    <rect id="card_1_bdr_purchase_right" rx="10" ry="10" x="0" y="0" width="197" height="117">
                                    </rect>
                                </svg>
                                <div id="Tax_Authority_lbl_purchase_right">
                                    <span><marquee behavior="scroll" direction="">{rec.name}</marquee></span>
                                </div>
                                <div id="Registration_Number_purchase_right">
                                    <span>{rec.gstIn}</span>
                                </div>
                                <div id="State_tax_authority_purchase_right">
                                    <span>{rec.state}</span>
                                </div>
                                <svg class="btw_tax_and_reg_purchase_right" viewBox="0 0 184 1">
                                    <path id="btw_tax_and_reg_purchase_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <svg class="between_reg_and_state_line_purchase_right" viewBox="0 0 184 1">
                                    <path id="between_reg_and_state_line_purchase_right" d="M 0 0 L 184 0">
                                    </path>
                                </svg>
                                <img id="taxes_1__img_all_purchase_right" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                <img id="reg__img_all_purchase_right" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                <img id="location_img_all_purchase_right" src="../RightSide_Component/location_img_all_page_right.png" srcset="../RightSide_Component/location_img_all_page_right.png 1x" />

                            </div>
                        )
                    })}
                </div>
                <input class="filter_purchase_right" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <img id="search_purchase_right" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />

                <div id="lbl_tax_page_pc_purchase_right">
                    <span>Merchants</span>
                </div>
            </div>

            <Merchants_Popup display={merchantPopup} data={selectedMerchant} closePopup={(refresh) => { refresh === true && props.refreshPurchases(); setMerchantPopup("none") }} />
            <div id="purchase_chart">
                <Purchase_Right_Donutchart />
            </div>

        </>
    )
}