import React , {useState} from "react";
import { Users } from "./Users";
import { PaymentModes } from "./PaymentModes";

export const Settings_Component = (props) => {

    const [settingsSelectedTab , setSettingsSelectedTab] = useState("Users");

    return(
        <>
            { settingsSelectedTab === "Users" ? <Users changeTab={(e)=>setSettingsSelectedTab(e)} refresh={props.refresh}/> :
             settingsSelectedTab === "PaymentModes" ? <PaymentModes changeTab={(e)=>setSettingsSelectedTab(e)} refresh={props.refresh}/> : "" }
            {/* { taxSelectedTab === "Upcoming" ? <Upcoming changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Monthly" ? <Monthly changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Quaterly" ? <Quaterly changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "SemiAnually" ? <SemiAnually changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" }
            { taxSelectedTab === "Anually" ? <Anually changeTab={(e)=>setTaxSelectedTab(e)} refresh={props.refresh} /> : "" } */}

        </>
    )
}