import React from 'react'
import "../../Styles/Mob/settings_main_mob.css"

export const Settings_Main_Mob = () => {
    return (
        <>
            <div id="only_top_settings">
                <img id="overall_bg_design_mob_settings" src="overall_bg_design_mob.png" srcset="overall_bg_design_mob.png 1x" />

                
            </div>
        </>
    )
}
