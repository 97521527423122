import React from 'react'
import "../../Styles/Mob/semianually_mob.css"
import { useLocation } from 'react-router-dom';
import { SalariesContainer_Mob } from './SalariesContainer_Mob';
import { BillContainer_Mob } from './BillContainer_Mob';
import { TaxContainer_Mob } from './TaxContainer_Mob';
import { InvoiceContainer_Mob } from './InvoiceContainer_Mob';

export const SemiAnually_Mob = (props) => {

    let location = useLocation()
    const renderComponents = () => {
        switch (location.pathname) {
            case '/salaries_mob':
                return <SalariesContainer_Mob tab={"SemiAnually"} refresh={props.refresh} />;
            case '/bills_mob':
                return  <BillContainer_Mob tab={"SemiAnually"} refresh={props.refresh} />;
            case '/taxes_mob':
                return <TaxContainer_Mob tab={"SemiAnually"} refresh={props.refresh}/>;
            case '/invoices_mob':
                return  <InvoiceContainer_Mob tab={"SemiAnually"} refresh={props.refresh}/>;
            default:
                return '';
        }
    }

    return (
        <>
            <div id="tax_page_mob_semi">

                <div id="UOTMQSA_semi_task_mob">
                    <div id="overdue_semi_task_mob" onClick={() => props.changeTabMob("OverDueMob")}>
                        <span>Over Due</span>
                    </div>
                    <div id="Upcoming_semi_task_mob" onClick={() => props.changeTabMob("UpcomingMob")}>
                        <span>Upcoming</span>
                    </div>
                    <div id="monthly_semi_task_mob" onClick={() => props.changeTabMob("MonthlyMob")}>
                        <span>Monthly</span>
                    </div>
                    <div id="quaterly_semi_task_mob" onClick={() => props.changeTabMob("QuaterlyMob")}>
                        <span>Quaterly</span>
                    </div>
                    <div id="semi_semi_task_mob">
                        <span>Semi Annual</span>
                    </div>
                    <div id="annual_lbl_semi_task_mob" onClick={() => props.changeTabMob("AnuallyMob")}>
                        <span>Annual</span>
                    </div>
                    <svg class="undesrline_semi_mob" viewBox="0 0 1 89">
                        <path id="undesrline_semi_mob" d="M 0 0 L 0 89">
                        </path>
                    </svg>
                </div>

                <svg class="ot_semi">
                    <rect id="ot_semi" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
                    </rect>
                </svg>
            </div>

            {
                renderComponents()
            }

        </>
    )
}
