import React , {useState , useEffect} from 'react'
import "../../Styles/Pc/eataxes.css"
import { UserContext } from "../UserContext";

export const EaTaxes = (props) => {

    const [taxData, setTaxData] = useState([]);
    const [tax, setTax] = useState(UserContext);

    useEffect(() => {
        if (props.taxData) {
            setTaxData(props.taxData)
        }
    }, [props.taxData])




    return (
        <>
            <div id="Scroll_Group_3_tax">
                {taxData.map((rec) => {
                    return (
                        <div id="expense_anakytics_grp_dash_pc" style={{ position: "relative", display: "inline-flex", marginTop: "10px", marginLeft: "5px" }}>
                        <svg class="card_1_bdr_expense_analytics">
                            <rect id="card_1_bdr_expense_analytics" rx="7" ry="7" x="0" y="0" width="342" height="56">
                            </rect>
                        </svg>
                        <div id="Mohammed_Affan_lbl_dash_pc_tax">
                            <span>{rec.taxAuthority}</span>
                        </div>
                        <div id="n_000_lbl_dash_pc_tax">
                            <span>₹ {rec.taxAmount}</span>
                        </div>
                        <div id="pay_grp_dash_pc"  style={{ backgroundColor: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                            <svg class="Rectangle_86_f">
                                <rect id="Rectangle_86_f" rx="12" ry="12" x="0" y="0" width="71" height="24">
                                </rect>
                            </svg>
                            <div id="Pay">
                                <span>{rec.status}</span>
                            </div>
                        </div>
                        <svg class="status_expense_">
                            <ellipse id="status_expense_" rx="6" ry="6" cx="6" cy="6" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                            </ellipse>
                        </svg>
                        <div id="n_000_lbl_dash_pc_f">
                            <span>Due: {rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                        </div>
                    </div>
                    )
                })}
            </div>
        </>
    )
}



