import React, { createContext, useState, useEffect } from 'react'


export const Active_Page = createContext();

export const ActivePageProvider = ({ children }) => {

  const storedActivePage = localStorage.getItem("activePage");
  const [activePage, setActivePage] = useState(storedActivePage || "Dashboard");

  useEffect(() => {
    localStorage.setItem("activePage", activePage);
  }, [activePage]);

  return (
    <Active_Page.Provider value={{ activePage, setActivePage }}>
      {children}
    </Active_Page.Provider>
  )
}
