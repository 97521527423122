import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Mob/expenceanalytics_mob.css"
import { EaSalary_Mob } from './EaSalary_Mob'
import { UserContext } from "../UserContext";
import { EaBills_Mob } from './EaBills_Mob';
import { EaTaxes_Mob } from './EaTaxes_Mob';
import { EaPurchase_Mob } from './EaPurchase_Mob';
import { EaInvoice_Mob } from './EaInvoice_Mob';
import { AddSalary_Popup } from '../Pc/AddSalary_Popup';
import { AddBills_Popup } from '../Pc/AddBills_Popup';
import { AddTax_Popup } from '../Pc/AddTax_Popup';
import { AddPurchase_Popup } from '../Pc/AddPurchase_Popup';
import { AddInvoice_Popup } from '../Pc/AddInvoice_Popup';

export const ExpenceAnalytics_Mob = (props) => {


    const { user, setUser } = useContext(UserContext);

    const [addSalaryPopup, setAddSalaryPopup] = useState("none");
    const [showBillsPopup, setShowBillsPopup] = useState("none");
    const [addTaxPopup, setAddTaxPopup] = useState("none");
    const [addPurchasePopup, setAddPurchasePopup] = useState("none");
    const [showInvoicePopup, setShowInvoicePopup] = useState("none");

    
    const tabValue = () => {
        if (user && user.userDepartments === "Human Resource") {
            return "EaSalary"
        }
        else if (user && user.userDepartments === "Administration") {
            return "EaBills"
        }
        else if (user && user.userDepartments === "Accounts") {
            return "EaSalary"
        }

        else if (user && user.userDepartments === "Management") {
            return "EaPurchase"
        }
        else if (user && user.userDepartments === "Directors") {
            return "EaSalary"
        }
    }

    const [EaSelectedMobTab, setEaSelectedMobTab] = useState(tabValue());

    //Salary total
    const [salaryData, setSalaryData] = useState([]);
    const [salary, setSalary] = useState(UserContext);

    useEffect(() => {
        if (props.salaryData) {
            setSalaryData(props.salaryData)
        }
    }, [props.salaryData])





    //Bills Total
    const [billData, setBillData] = useState([]);
    const [bill, setBill] = useState(UserContext);

    useEffect(() => {
        if (props.billData) {
            setBillData(props.billData)
        }
    }, [props.billData])




    //Taxes Total
    const [taxData, setTaxData] = useState([]);
    const [tax, setTax] = useState(UserContext);

    useEffect(() => {
        if (props.taxData) {
            setTaxData(props.taxData)
        }
    }, [props.taxData])



    const popups = () => {
        switch (EaSelectedMobTab) {
            case "EaSalary":
                return setAddSalaryPopup("Add")
                break;
            case "EaBills":
                return setShowBillsPopup("Add")
                break;
            case "EaTaxes":
                return setAddTaxPopup("Add")
                break;
            case "EaPurchase":
                return setAddPurchasePopup("Add")
                break;
            case "EaInvoice":
                return setShowInvoicePopup("Add")
                break;

            default:
                break;
        }
    }


    //Purchases total
    const [purchaseData, setPurchaseData] = useState([]);
    const [purchase, setPurchase] = useState(UserContext);

    useEffect(() => {
        if (props.purchaseData) {
            setPurchaseData(props.purchaseData)
        }
    }, [props.purchaseData])




    //Invoices total
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoice, setInvoice] = useState(UserContext);

    useEffect(() => {
        if (props.invoiceData) {
            setInvoiceData(props.invoiceData)
        }
    }, [props.invoiceData])

    return (
        <>
            <div id="expense_mob">
                <div id="overall_grp_expense_mob">
                    <svg class="expense_analytics_over_all_bdr_mob">
                        <rect id="expense_analytics_over_all_bdr_mob" rx="10" ry="10" x="0" y="0" width="100%" height="246">
                        </rect>
                    </svg>
                    <div id="Expense_Analytics_lbl_dash_mob">
                        <span>Expense Analytics</span>
                    </div>
                    <div id="detail_SBTPI">
                        {
                            ((user && user.userDepartments === "Human Resource") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors")) &&
                            <div id="sla_expense_mob" onClick={() => setEaSelectedMobTab("EaSalary")}>
                                <svg class="salaries_dexpense_mob">
                                    <rect id="salaries_dexpense_mob" rx="3" ry="3" x="0" y="0" width="65" height="6">
                                    </rect>
                                </svg>
                                <div id="Salaries_lbl_expense_mob">
                                    <span>Salaries</span>
                                </div>
                                <svg class="salariesexpense_mob">
                                    <ellipse id="salariesexpense_mob" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="n_100000_salary_expense_mob">
                                    <span>₹ {salaryData.reduce((a, v) => a = a + v.finalAmount, 0).toLocaleString()}</span>
                                </div>
                            </div>
                        }
                        {
                            ((user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Administration") || (user && user.userDepartments === "Directors")) &&
                            <div id="bills_expense_mob" onClick={() => setEaSelectedMobTab("EaBills")}>
                                <svg class="bills_bar_expense_mob">
                                    <rect id="bills_bar_expense_mob" rx="3" ry="3" x="0" y="0" width="65" height="6">
                                    </rect>
                                </svg>
                                <div id="Bills_lbl_expense_mob">
                                    <span>Bills</span>
                                </div>
                                <svg class="bills_circle_expense_mob">
                                    <ellipse id="bills_circle_expense_mob" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="n_10000_bills_expense_mob">
                                    <span>₹ {billData.reduce((a, v) => a = a + v.billAmount, 0).toLocaleString()}</span>
                                </div>
                            </div>
                        }
                        {
                            ((user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors")) &&
                            <div id="taxes_expense_mob" onClick={() => setEaSelectedMobTab("EaTaxes")}>
                                <svg class="taxes_bar_expense_mob">
                                    <rect id="taxes_bar_expense_mob" rx="3" ry="3" x="0" y="0" width="65" height="6">
                                    </rect>
                                </svg>
                                <div id="Taxes_lvbl_expense_mob">
                                    <span>Taxes</span>
                                </div>
                                <svg class="taxes_bdr_expense_mob">
                                    <ellipse id="taxes_bdr_expense_mob" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="n_1000000_taxes_expense_mob">
                                    <span>₹ {taxData.reduce((a, v) => a = a + v.taxAmount, 0).toLocaleString()}</span>
                                </div>
                            </div>
                        }
                        {
                            ((user && user.userDepartments === "Administration") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors") || (user && user.userDepartments === "Management")) &&
                            <div id="purchase_expense_mob" onClick={() => setEaSelectedMobTab("EaPurchase")}>
                                <svg class="purchase_bdr_expense_mob">
                                    <rect id="purchase_bdr_expense_mob" rx="3" ry="3" x="0" y="0" width="65" height="6">
                                    </rect>
                                </svg>
                                <div id="Purchases_lv_expense_mob">
                                    <span>Purchases</span>
                                </div>
                                <svg class="purchase_circe_expense_mob">
                                    <ellipse id="purchase_circe_expense_mob" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="n_10000_purchase_expense_mob">
                                    <span>₹ {purchaseData.reduce((a, v) => a = a + v.itemCost, 0).toLocaleString()}</span>
                                </div>
                            </div>
                        }

                        {
                            ((user && user.userDepartments === "Management") || (user && user.userDepartments === "Accounts") || (user && user.userDepartments === "Directors")) &&
                            <div id="invoice_expense_mob" onClick={() => setEaSelectedMobTab("EaInvoice")}>
                                <svg class="invoice_bdr_progress_bar_expen">
                                    <rect id="invoice_bdr_progress_bar_expen" rx="3" ry="3" x="0" y="0" width="65" height="6">
                                    </rect>
                                </svg>
                                <div id="Invoices_lbl_expense_mob">
                                    <span>Invoices</span>
                                </div>
                                <svg class="invoice_color_circleexpense_mo">
                                    <ellipse id="invoice_color_circleexpense_mo" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                                <div id="n_10000_invoice_lbl_expense_mo">
                                    <span>₹ {invoiceData.reduce((a, v) => a = a + v.finalAmount, 0).toLocaleString()}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <button id="add_btn_all_mob" onClick={() => popups()} >
                        <svg class="add_bg_all_mob">
                            <rect id="add_bg_all_mob" rx="8" ry="8" x="0" y="0" width="22" height="22">
                            </rect>
                        </svg>
                        <svg class="Add_all_mob" viewBox="6.75 6.75 18 18">
                            <path id="Add_all_mob" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                    </button>

                    {EaSelectedMobTab === "EaSalary" ? <><EaSalary_Mob salaryData={props.salaryData} /> <AddSalary_Popup display={addSalaryPopup} closeAddSalaryPopup={() => setAddSalaryPopup("none")} refresh={props.refresh} /></> : ""}
                    {EaSelectedMobTab === "EaBills" ? <><EaBills_Mob billData={props.billData} /> <AddBills_Popup display={showBillsPopup} closePopup={() => setShowBillsPopup("none")} refresh={props.refresh} /></> : ""}
                    {EaSelectedMobTab === "EaTaxes" ? <><EaTaxes_Mob taxData={props.taxData} /> <AddTax_Popup display={addTaxPopup} closeAddTaxPopup={() => setAddTaxPopup("none")} refresh={props.refresh} /></> : ""}
                    {EaSelectedMobTab === "EaPurchase" ? <><EaPurchase_Mob purchaseData={props.purchaseData} /> <AddPurchase_Popup display={addPurchasePopup} closeAddPurchasePopup={() => setAddPurchasePopup("none")} refresh={props.refresh} /></> : ""}
                    {EaSelectedMobTab === "EaInvoice" ? <><EaInvoice_Mob invoiceData={props.invoiceData} /> <AddInvoice_Popup display={showInvoicePopup} closePopup={() => setShowInvoicePopup("none")} refresh={props.refresh} /></> : ""}
                </div>
            </div>
        </>
    )
}
