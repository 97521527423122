import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/Pc/addtaxauthority_popup.css"
import { Switch } from "antd";

export const AddTaxAuthority_Popup = (props) => {

    const [pickInput, setPickInput] = useState("");

    const [taxAuthority, setTaxAuthority] = useState("");
    const [registerationNumber, setRegisterationNumber] = useState("");
    const [frequency, setFrequency] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [state, setState] = useState("");
    const [paymentUrl, setPaymentUrl] = useState("");
    const [upi, setUpi] = useState("");
    const [active, setActive] = useState(true);



    //edit tax authority
    const [taxAuthorityId, setTaxAuthorityId] = useState("");

    useEffect(() => {
        if (props.display === "Edit") {
            setTaxAuthorityId(props.data ? props.data._id : "")
            setTaxAuthority(props.data ? props.data.taxAuthority : "")
            setRegisterationNumber(props.data ? props.data.registerationNumber : "")
            setFrequency(props.data ? props.data.frequency : "")
            setDay(props.data ? props.data.day : "")
            setMonth(props.data ? props.data.month : "")
            setState(props.data ? props.data.state : "")
            setPaymentUrl(props.data ? props.data.paymentUrl : "")
            setUpi(props.data ? props.data.upi : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.data])



    const clearData = () => {
        setTaxAuthority("")
        setRegisterationNumber("")
        setFrequency("")
        setDay("")
        setMonth("")
        setState("")
        setPaymentUrl("")
        setUpi("")
        setActive(false)
    }



    const addRecord = () => {
        if (validateRecord("add"))
        {

        let apiURL = process.env.REACT_APP_API_URL + "/taxauthority/addtaxauthority";
        let apiParams = {
            method: "POST",
            headers: {
                taxauthority: taxAuthority,
                registerationnumber: registerationNumber,
                frequency: frequency,
                day: day,
                month: month,
                state: state,
                paymenturl: paymentUrl,
                upi: upi,
                active: active
            },
        };


        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closeAddTaxAuthorityPopup(true) ; clearData()
                    alert("TaxAuthority Updated Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }



    const updateRecord = () => {
        if (validateRecord("edit"))
        {

        let apiURL = process.env.REACT_APP_API_URL + "/taxauthority/edittaxauthority";
        let apiParams = {
            method: "POST",
            headers: {
                taxAuthorityId: taxAuthorityId,
                taxauthority: taxAuthority,
                registerationnumber: registerationNumber,
                frequency: frequency,
                day: day,
                month: month,
                state: state,
                paymenturl: paymentUrl,
                upi: upi,
                active: active
            },
        };


        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closeAddTaxAuthorityPopup(true) ; clearData()

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));}
            else
            {
                return;
            }
    }


    const validateRecord = (actionType) => {
        let validationText = "";
        if(actionType === "edit" && taxAuthorityId.trim().length <= 0 )
        {
            validationText+="Tax Authority Id is invalid"+"\n";
        }
        if(taxAuthority.trim().length <= 0) 
        {
            validationText+="Please enter Tax Authority"+"\n";
        }
        if(registerationNumber.trim().length <= 0) 
        {
            validationText+="Please enter Registeration Number or NA if not available"+"\n";
        }
        if(frequency.trim().length <= 0) 
        {
            validationText+="Please select Frequency"+"\n";
        }
        if(state.trim().length <= 0) 
        {
            validationText+="Please select State"+"\n";
        }
        if(paymentUrl.trim().length <= 0) 
        {
            validationText+="Please enter Payment Url or NA if not available"+"\n";
        }
        if(upi.trim().length <= 0) 
        {
            validationText+="Please enter UPI or NA if not available"+"\n";
        }
        if(validationText.trim().length > 0)
        {
            alert(validationText);
            return false;
        }
        else
        {
            return true;
        }
    }
    


    return (
        <>

            <Modal
                open={props.display !== "none"}
                onClose={() => props.closeAddTaxAuthorityPopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="addtaxauthority_layout" >
                    <div id="tax_pop_up_1">
                        <div id="over_all_tax_auth_pop_up_grp">
                            <img id="bg_of_add_tax_auth_pop_up_tax_" src="../AddTax_Popup/bg_of_add_tax_auth_pop_up_tax_.png" srcset="../AddTax_Popup/bg_of_add_tax_auth_pop_up_tax_.png 1x" />

                            <div id="tax_auth_lbl_pop_up_tax_auth">
                                <span>Tax Authority</span>
                            </div>
                            <input placeholder="Tax Authority" tabIndex="1" autoFocus class="tax_auth_input_pop_up_tax_auth" value={taxAuthority} onChange={(e) => setTaxAuthority(e.target.value)} />
                            {pickInput === "onetime" ? "" : <div>
                                <div id="due_on_lbl_pop_up_tax_auth">
                                    <span>Day</span>
                                </div>
                                <select class="due_on_input_pop_up_tax_auth" tabIndex="4" value={day} onChange={(e) => setDay(e.target.value)} >
                                    <rect id="due_on_input_pop_up_tax_auth" rx="0" ry="0" x="0" y="0" width="77.687" height="40">
                                    </rect>
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                            </div>}
                            <div id="fre_lbl_pop_up_tax_auth">
                                <span>Frequency</span>
                            </div>
                            <select placeholder="Frequency" tabIndex="3" class="freq_input_pop_up_tax_auth" value={frequency} onChange={(e) => { setPickInput(e.target.value); setFrequency(e.target.value) }}>
                                <option value="">Select</option>
                                <option value="onetime" >One-Time</option>
                                <option value="monthly">Monthly</option>
                                <option value="quaterly">Quarterly</option>
                                <option value="semiannual">Semi Annual</option>
                                <option value="annual">Annual</option>
                            </select>
                            <div id="state_lbl_pop_up_tax_auth">
                                <span>State</span>
                            </div>
                            <select class="state_drop_pop_up_tax_auth" tabIndex="6" value={state} onChange={(e) => setState(e.target.value)} >
                                <rect id="state_drop_pop_up_tax_auth" rx="0" ry="0" x="0" y="0" width="331.261" height="40">
                                </rect>
                                <option value="">Select State</option>
                                <option value="AP">Andhra Pradesh</option>
                                <option value="AR">Arunachal Pradesh</option>
                                <option value="AS">Assam</option>
                                <option value="BR">Bihar</option>
                                <option value="CT">Chhattisgarh</option>
                                <option value="DL">Delhi</option>
                                <option value="GA">Goa</option>
                                <option value="GJ">Gujarat</option>
                                <option value="HR">Haryana</option>
                                <option value="HP">Himachal Pradesh</option>
                                <option value="JK">Jammu and Kashmir</option>
                                <option value="JH">Jharkhand</option>
                                <option value="KA">Karnataka</option>
                                <option value="KL">Kerala</option>
                                <option value="MP">Madhya Pradesh</option>
                                <option value="MH">Maharashtra</option>
                                <option value="MN">Manipur</option>
                                <option value="ML">Meghalaya</option>
                                <option value="MZ">Mizoram</option>
                                <option value="NL">Nagaland</option>
                                <option value="OR">Odisha</option>
                                <option value="PB">Punjab</option>
                                <option value="RJ">Rajasthan</option>
                                <option value="SK">Sikkim</option>
                                <option value="TN">Tamil Nadu</option>
                                <option value="TG">Telangana</option>
                                <option value="UP">Uttar Pradesh</option>
                                <option value="UT">Uttarakhand</option>
                                <option value="WB">West Bengal</option>
                                <option value="CN">Central</option>
                            </select>
                            <div id="active_grp_pop_up_tax_auth">
                                <Switch tabIndex="9"
                                    checked={active}
                                    checkedChildren="Active"
                                    unCheckedChildren="InActive"
                                    value={active}
                                    onChange={(e) => setActive(e)}
                                />
                            </div>
                            <button id="pop_up_tax_auth_btn_grp"  >
                                <svg class="passs_bdr_btn_pop_up_tax_auth">
                                    <rect id="passs_bdr_btn_pop_up_tax_auth" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                    </rect>
                                </svg>
                                <div id="add_lbl_pop_up_tax_auth" >
                                    <span tabIndex="10" onClick={() => { props.display === "Edit" ? updateRecord() : addRecord() }}>{props.display === "Edit" ? "Edit" : "Add"}</span>
                                </div>
                            </button>
                            <img onClick={() => {props.closeAddTaxAuthorityPopup(false) ; clearData()}} id="close_pop_up_tax_auth" src="../AddTax_Popup/close_pop_up_tax_auth.png" srcset="../AddTax_Popup/close_pop_up_tax_auth.png 1x" />

                            <div id="reg_no_lbl_pop_up_tax_auth">
                                <span>Registration Number</span>
                            </div>
                            <input placeholder="Registration Number" tabIndex="2" class="redistration_n0_input_pop_up_t" value={registerationNumber} onChange={(e) => setRegisterationNumber(e.target.value)} />
                            <div id="payent_lbl_pop_up_tax_auth">
                                <span>Payment URL</span>
                            </div>
                            <input placeholder="Payment URL" tabIndex="7" class="ayment_url_input_pop_up_tax_au" value={paymentUrl} onChange={(e) => setPaymentUrl(e.target.value)} />
                            <div id="add_tax_authority_heading_lbl_">
                                <span>{props.display === "Edit" ? "Edit Tax Authority" : "Add Tax Authority"}</span>
                            </div>
                            {((pickInput === "onetime") || (pickInput === "monthly")) ? "" : <div>
                                <select class="due_on_input_pop_up_tax_auth_b" tabIndex="5" value={month} onChange={(e) => setMonth(e.target.value)} >
                                    <rect id="due_on_input_pop_up_tax_auth_b" rx="0" ry="0" x="0" y="0" width="77.687" height="40">
                                    </rect>
                                    <option value="">Select</option>
                                    <option value="0">Jan</option>
                                    <option value="1">Feb</option>
                                    <option value="2">Mar</option>
                                    <option value="3">Apr</option>
                                    <option value="4">May</option>
                                    <option value="5">Jun</option>
                                    <option value="6">Jul</option>
                                    <option value="7">Aug</option>
                                    <option value="8">Sep</option>
                                    <option value="9">Oct</option>
                                    <option value="10">Nov</option>
                                    <option value="11">Dec</option>
                                </select>
                                <div id="due_on_lbl_pop_up_tax_auth_b">
                                    <span>Month</span>
                                </div>
                            </div>}
                            <div id="UPI_lbl_pop_up_tax_auth">
                                <span>UPI </span><span style={{ fontSize: 13 }}>Optional</span><span></span>
                            </div>
                            <input placeholder="Payment URL" tabIndex="8" class="UPIinput_pop_up_tax_auth" value={upi} onChange={(e) => setUpi(e.target.value)} />
                        </div>
                    </div>
                </div>

            </Modal>

        </>
    )
}