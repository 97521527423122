import React, { useEffect, useState, useContext } from "react";
import "../../Styles/Pc/user_main.css"
import { UserContext } from '../UserContext';
import { AddPaymentMode_Popup } from "./AddPaymentMode_Popup";
import { PaymentModeCard } from "./PaymentModeCard";



export const PaymentModes_Main = () => {

    const { user, setUser } = useContext(UserContext);
    const [paymentModePopup, setPaymentModePopup] = useState("none");
    const [paymentModeData, setPaymentModeData] = useState([]);
    const [selectedPaymentMode, setSelectedPaymentMode] = useState({});
    const [filterData, setFilterData] = useState([]);



    useEffect(() => {

        if (user) {
            GetPaymentModes()
        }
    }, [paymentModePopup, user])


    useEffect(() => {
        const data = paymentModeData
        if (user && user.userDepartments === "Human Resource") {
            const filter = data.filter((rw) => rw.userDepartments === (user && user.userDepartments))
            setFilterData(filter)
        }
        else if (user && user.userDepartments === "Administration") {
            const filter = data.filter((rw) => rw.userDepartments === (user && user.userDepartments))
            setFilterData(filter)
        }
        else if (user && user.userDepartments === "Accounts") {
            const filter = data.filter((rw) => rw.userDepartments === (user && user.userDepartments))
            setFilterData(filter)
        }
        else if (user && user.userDepartments === "Management") {
            const filter = data.filter((rw) => rw.userDepartments === (user && user.userDepartments))
            setFilterData(filter)
        }
        else if (user && user.userDepartments === "Directors") {
            setFilterData(data)
        }
    }, [user, paymentModeData])




    const GetPaymentModes = async () => {

        let apiURL = process.env.REACT_APP_API_URL + "/paymentmode/getmodes";
        let apiParams = {
            method: "GET",

        };

        await fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setPaymentModeData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    return (
        <>


            <div id="setup_page">
                <img id="set_up__bg" src="../User_Main/salary_bg.png" srcset="../User_Main/salary_bg.png 1x" />
                
                <div id="setup_scroll_pc">
                    {(user && user.userAccess === "Full Access") &&
                       <div id="paymentMode_card" onClick={() => setPaymentModePopup("Add")} >
                       <svg class="Rectangle_51_setup_">
                           <rect id="Rectangle_51_setup_" rx="10" ry="10" x="0" y="0" width="174" height="191">
                           </rect>
                       </svg>
                       <img id="add_1_setup_" src="../User_Main/add_1.png" srcset="../User_Main/add_1.png 1x" />

                       <div id="Remark_lbl_setup_">
                           <span>Add Payment</span>
                       </div>
                   </div>
                    }
                    {(filterData || []).map((rec) => (
                       <PaymentModeCard rec={rec} setPaymentModePopup={setPaymentModePopup} setSelectedPaymentMode={setSelectedPaymentMode} />
                       
                    ))}
                </div>
            </div>

            <AddPaymentMode_Popup display={paymentModePopup} data={selectedPaymentMode} closePaymentModePopup={() => { setPaymentModePopup("none"); GetPaymentModes(); }} />

        </>
    )
}

