import React, { useState, useContext, useMemo, useEffect } from "react";
import { ForgotPassword } from "./Components/Pc/ForgotPassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Bill } from "./Components/Pc/Bill";
import { InvoiceMain } from "./Components/Pc/InvoiceMain";
import { UserContext } from "./Components/UserContext";
import { Salary_Main } from "./Components/Pc/Salary_Main";
import { Settings_Main } from "./Components/Pc/Settings_Main";
import { Tax_Main } from "./Components/Pc/Tax_Main";
import { Purchase_Main } from "./Components/Pc/Purchase_Main";
import { Dashbord } from "./Components/Pc/Dashbord";
import { Login_Mob } from "./Components/Mob/Login_Mob";
import { Salary_Main_Mob } from "./Components/Mob/Salary_Main_Mob";
import { Purchase_Main_Mob } from "./Components/Mob/Purchase_Main_Mob";
import { Bills_Main_Mob } from "./Components/Mob/Bills_Main_Mob";
import { Tax_Main_Mob } from "./Components/Mob/Tax_Main_Mob";
import { Invoice_Main_Mob } from "./Components/Mob/Invoice_Main_Mob";
import { Layout_Main_Mob } from "./Components/Mob/Layout_Main_Mob";
import { Settings_Main_Mob } from "./Components/Mob/Settings_Main_Mob";
import { Dashboard_Mob } from "./Components/Mob/Dashboard_Mob";
import { LayoutMain } from "./Components/Pc/LayoutMain";
import { Login } from "./Components/Pc/Login";




function App() {

    const [refreshPurchases, setRefreshPurchases] = useState("");


    const [user, setUser] = useState(null);
    const value = useMemo(
        () => ({
            user,
            setUser,
        }),
        [
            user,
            setUser,
        ]
    );

    useEffect(() => {
        
     {console.log(process.env.REACT_APP_API_URL)}
        let storedUser = localStorage.getItem("loggedInUser");
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []); 

    useEffect(() => {
        console.log(user)
    }, [user])

    return (
        <>
            <UserContext.Provider value={value}>
                <Router>
                    {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) ?
                        <>
                            {user ? <Layout_Main_Mob refreshPurchases={() => { setRefreshPurchases(!refreshPurchases) }} /> : ""}
                            {user ?
                                <Routes>
                                    <Route exact path="/salaries_mob" element={<Salary_Main_Mob />} />
                                    <Route exact path="/purchases_mob" element={<Purchase_Main_Mob refresh={refreshPurchases} />} />
                                    <Route exact path="/bills_mob" element={<Bills_Main_Mob />} />
                                    <Route exact path="/taxes_mob" element={<Tax_Main_Mob />} />
                                    <Route exact path="/invoices_mob" element={<Invoice_Main_Mob />} />
                                    <Route exact path="/seettings_mob" element={<Settings_Main_Mob />} />
                                    <Route exact path="/mydashboard_mob" element={<Dashboard_Mob />} />

                                </Routes> : ""}
                            <Routes>
                                <Route exact path="/" element={user ? <Dashboard_Mob /> : <Login_Mob />} />
                            </Routes>
                        </>
                        :
                        <>
                            {user ? <LayoutMain refreshPurchases={() => { setRefreshPurchases(!refreshPurchases) }} /> : ""}
                            {user ?
                                <Routes>

                                    <Route exact path="/bills" element={<Bill />} />
                                    <Route exact path="/invoices" element={<InvoiceMain />} />
                                    <Route exact path="/salaries" element={<Salary_Main />} />
                                    <Route exact path="/settings" element={<Settings_Main />} />
                                    <Route exact path="/taxes" element={<Tax_Main />} />
                                    <Route exact path="/purchases" element={<Purchase_Main refresh={refreshPurchases} />} />
                                    <Route exact path="/mydashboard" element={<Dashbord />} />

                                </Routes> : ""}
                            <Routes>
                                <Route exact path="/" element={user ? <Dashbord /> : <Login />} />
                                <Route exact path="/forgotpassword" element={<ForgotPassword />} />
                            </Routes>
                        </>}
                </Router>

            </UserContext.Provider>
        </>
    )
}

export default App