import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/purchasecontainer_mob.css"
import { UserContext } from "../UserContext";
import { AddPurchase_Popup } from '../Pc/AddPurchase_Popup';


export const PurchaseContainer_Mob = (props) => {

	const [purchaseData, setPurchaseData] = useState([]);
	const [purchase, setPurchase] = useState(UserContext);
	const [addPurchasePopup, setAddPurchasePopup] = useState("none");
	const [selectedPurchase, setSelectedPurchase] = useState({});

    useEffect(() => {
		GetPurchase(props.tab)

	}, [addPurchasePopup])


	const GetPurchase = (tab) => {

		let apiURL = process.env.REACT_APP_API_URL + "/purchase/getpurchases/" + tab;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					setPurchaseData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));
	}


  return (
    <>
            <div id="purchase_page">

                <div id="over_all_purchase_page">
                    <div id="Scroll_purchase_mob">
                    {purchaseData.map((rec) => {
                        return (
                            <div id="card_1_purchase_page_mob" style={{ position: "relative", display: "inline-flex", margin: "05px", marginTop: "10px" }} onClick={() => { setAddPurchasePopup("Edit"); setSelectedPurchase(rec) }}>
                            <div id="com_req_grp_card_1_purchase">
                                <svg class="com_req_card_1_purchase">
                                    <rect id="com_req_card_1_purchase" rx="10" ry="10" x="0" y="0" width="156" height="150">
                                    </rect>
                                </svg>
                                <svg class="Rectangle_63_purchase" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                    <rect id="Rectangle_63_purchase" rx="9" ry="9" x="0" y="0" width="156" height="30">
                                    </rect>
                                </svg>
                                <div id="name_purchase_upcoming_page">
                                    <span>{rec.product}</span>
                                </div>
                                <img id="due-date_purchase" src="due-date.png" srcset="due-date.png 1x" />

                                <div id="from_date_purchase_upcoming_page">
                                    <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                </div>
                                <img id="salary_purchase" src="salary.png" srcset="salary.png 1x" />

                                <div id="to_date_upcoming_page_purchase">
                                    <span>{rec.itemCost}</span>
                                </div>
                                <div id="to_date_upcoming_page_dl_purchase">
                                    <span>Due in {parseInt((new Date(rec.dueDate) - new Date()) / 86400000)} Days</span>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                    </div>
                    <div id="purchase_btn_grp_mob">
                        <div id="add_btn_purchase_"  onClick={() => setAddPurchasePopup("Add")}>
                            <svg class="add_bg_Add_purchase_">
                                <rect id="add_bg_Add_purchase_" rx="8" ry="8" x="0" y="0" width="26" height="26">
                                </rect>
                            </svg>
                            <svg class="Add_purchase_mob" viewBox="6.75 6.75 18 18">
                                <path id="Add_purchase_mob" d="M 6.75 16.94998168945312 L 14.55002021789551 16.94998168945312 L 14.55002021789551 24.75000190734863 L 16.94998168945312 24.75000190734863 L 16.94998168945312 16.94998168945312 L 24.75000190734863 16.94998168945312 L 24.75000190734863 14.55002021789551 L 16.94998359680176 14.55002021789551 L 16.94998359680176 6.75 L 14.55002021789551 6.75 L 14.55002021789551 14.55001831054688 L 6.75 14.55001831054688 L 6.75 16.94998168945312 Z">
                                </path>
                            </svg>
                        </div>
                        <div id="purchase_mob_lbl_tax_page_pc">
                            <span>Purchases</span>
                        </div>
                    </div>

                    <AddPurchase_Popup display={addPurchasePopup} data={selectedPurchase} closeAddPurchasePopup={() => setAddPurchasePopup("none")} refresh={props.refresh}/>

                </div>
            </div>
        </>
  )
}
