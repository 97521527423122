import React, { useEffect, useState, useContext } from 'react'
import "../../Styles/Pc/resetpassword_popup.css"
import Modal from '@mui/material/Modal';
import { UserContext } from "../UserContext";

export const ResetPassword_Popup = (props) => {
    
    const { user, setUser } = useContext(UserContext);

    const [oldPassword , setOldPassword] = useState("");
    const [newPassword , setNewPassword] = useState("");
    const [confirmNewPassword , setConfirmNewPassword] = useState("");


    const resetPassword = () => {
        if(newPassword !== confirmNewPassword){
            return alert("Password Does Not Match ")
        }
         let apiURL = process.env.REACT_APP_API_URL + "/users/updateusers/"+ user._id;
         let apiParams = {
             method: "POST",
             headers: {
                oldpassword:oldPassword,
                newpassword:newPassword
             },
         };
         fetch(apiURL, apiParams)
             .then((res) => (res.status === 200 ? res.json() : res.text()))
             .then((repos) => {
                 if (repos.data) {
                     alert("Password Updated Successfully!!");
                     props.closePopup(false)
 
 
                 } else alert(repos.message || repos);
             })
             .catch((err) => alert(err));
 
     }

    return (
        <>

            <Modal
                open={props.display}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className='reset_layout'>
                    <div id="chamge_pass_finance">
                        <div id="overall_grp_change_pass">
                            <img id="top_bg_change_pass" src="../Change_Password/top_bg_change_pass.png" srcset="../Change_Password/top_bg_change_pass.png 1x" />

                            <img onClick={() => props.closePopup(false)} id="close_pup_op_change_passs" src="../Change_Password/close_pup_op_change_passs.png" srcset="../Change_Password/close_pup_op_change_passs.png 1x" />

                            <div id="Change_Password_lbl_">
                                <span>Change Password</span>
                            </div>
                            <input placeholder="Current Passowrd" type="password" class="old_pass_input" value={oldPassword} onChange={(e)=>setOldPassword(e.target.value)} />
                            <input placeholder="New Passowrd" type="password" class="new_pass_input" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} />
                            <input placeholder="Confirm New Password" type="password" class="confirm_new_pass_input" value={confirmNewPassword} onChange={(e)=>setConfirmNewPassword(e.target.value)} />
                            <button id="btn_change_password" onClick={()=> resetPassword()} >
                                <svg class="change_btn_">
                                    <rect id="change_btn_" rx="5" ry="5" x="0" y="0" width="131" height="39">
                                    </rect>
                                </svg>
                                <div id="Change_lbl_">
                                    <span>Change</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}
