import React from "react";
import "../../Styles/Pc/finance_footer.css"

export const Finance_Footer = () => {
    return (
        <>
            <div id="Footer_app_candi_pc">
                <div id="footer_app_pc_candi">
                    <svg class="footer_vacan">
                        <rect id="footer_vacan" rx="0" ry="0" x="0" y="0" width="100%" height="64">
                        </rect>
                    </svg>
                    <a href="https://operate.live" target="_blank">
                        <div id="All_rights_reserved__2022_Oper">
                            <span>All rights reserved © 2022 Operate Live (OPC) Private Limited.</span>
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/company/operate-live/mycompany/" target="_blank">
                        <img id="linkedin_vacan" src="../Footer/linkedin_vacan.png" srcset="../Footer/linkedin_vacan.png 1x" />

                    </a>
                    <a href="https://api.whatsapp.com/send?phone=919902247787" target="_blank">
                        <img id="whatapp_btn_img_vacan" src="../Footer/whatapp_btn_img_vacan.png" srcset="../Footer/whatapp_btn_img_vacan.png 1x" />

                    </a>
                        <a href="https://jobs.bizibees.in/terms" target="_blank">
                            <div id="Terms_and_Conditions_lbl_vac">
                                <span>Terms and Conditions</span>
                            </div>
                        </a>
                        <a href="https://jobs.bizibees.in/privacy" target="_blank">
                            <div id="Private_Policy_lbl_vac">
                                <span>Private Policy</span>
                            </div>
                        </a>
                        <svg class="Line_2_vacan" viewBox="0 0 1 24">
                            <path id="Line_2_vacan" d="M 0 0 L 0 24">
                            </path>
                        </svg>
                </div>
            </div> 
        </>
    )
}