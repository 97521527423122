import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/bill_rightcomponent_mob.css"
import { UserContext } from "../UserContext";
import { Vendors_Popup } from '../Pc/Vendors_Popup';
import { Bill_Right_Donutchart_ } from '../Pc/Bill_Right_Donutchart_';

export const Bill_RightComponent_Mob = (props) => {

    
    const [vendorData, setVendorData] = useState([]);
    const [vendor, setVendor] = useState(UserContext);
    const [vendorsPopup, setVendorsPopup] = useState("none");
    const [selectedVendor, setSelectedVendor] = useState({});

    const [searchText, setSearchText] = useState("");


    //fetching Vendor
    useEffect(() => {

        if (vendor) {
            GetVendors()
        }
    }, [vendorsPopup])


    const GetVendors = () => {

        let apiURL = process.env.REACT_APP_API_URL + "/vendor/getvendors";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setVendorData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

  return (
    <>
    <div id="rigth_side_bill_mob">
        <div className='blur_right_bill' onClick={() => props.componentClose(false)}>
           
        </div>
        <div id="all_grp_right_side_mob_bill">
            <svg class="bg_ogf_side_part_mob_bill">
                <rect id="bg_ogf_side_part_mob_bill" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
                </rect>
            </svg>
            <div className='chart_mob_right_bill'>
                <Bill_Right_Donutchart_ />
            </div>
            <div id="product_add_mob_bill">
                <img id="search_bill_right_mob" src="../RightSide_Component/search.png" srcset="../RightSide_Component/search.png 1x" />
                <input class="filter_bill_right_mob" type='text' placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)}  />
                <div id="product_grp_with_add_mob_bill">
                    <div id="add_btn_tax_authority_mob_bill" onClick={() => { setVendorsPopup("Add") }}>
                        <svg class="add_bg_Add_Tax_Authority_mob_bill">
                            <rect id="add_bg_Add_Tax_Authority_mob_bill" rx="8" ry="8" x="0" y="0" width="26" height="26">
                            </rect>
                        </svg>
                        <svg class="Add_Tax_Authorityplus__mob_bill" viewBox="6.75 6.75 18 18">
                            <path id="Add_Tax_Authorityplus__mob_bill" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                    </div>
                    <div id="tax_authority_lbl_tax_page_mob_bill">
                        <span>Vendors</span>
                    </div>
                </div>
                <div id="Scroll_product_mob_bill">
                {vendorData.filter(rec => rec.vendorName.toLowerCase().includes(searchText.toLowerCase()) || rec.accountId.toLowerCase().includes(searchText.toLowerCase()) || rec.frequency.toLowerCase().includes(searchText.toLowerCase())).map((rec) => {
                        return (
                            <div id="tax_authority_card_1_tax_page_bill" style={{ position: "relative", display: "block", marginBottom: "12px" }} onClick={() => { setVendorsPopup("Edit"); setSelectedVendor(rec) }}>
                                <svg class="card_1_bdr_tax_authority_mob_bill">
                                    <rect id="card_1_bdr_tax_authority_mob_bill" rx="10" ry="10" x="0" y="0" width="100%" height="117">
                                    </rect>
                                </svg>
                                <div id="Tax_Authority_lbl_tax_page_mob_dx_bill">
                                    <span><marquee behavior="scroll" direction="">{rec.vendorName}</marquee></span>
                                </div>
                                <div id="mob_tax_authority_bill">
                                    <span>{rec.accountId}</span>
                                </div>
                                <div id="State_tax_authority_mob_bill">
                                    <span>{rec.frequency}</span>
                                </div>
                                <div class="btw_tax_and_reg_mob_bill"></div>
                                <div class="between_reg_and_state_line_mob_bill"></div>
                                <img id="taxes_1__img_all_page_right_mo_bill" src="../RightSide_Component/taxes_1__img_all_page_right.png" srcset="../RightSide_Component/taxes_1__img_all_page_right.png 1x" />

                                <img id="reg__img_all_page_right_mob_bill" src="../RightSide_Component/reg__img_all_page_right.png" srcset="../RightSide_Component/reg__img_all_page_right.png 1x" />

                                <img id="location_img_all_page_right_mo_bill" src="../RightSide_Component/categorization.png" srcset="../RightSide_Component/categorization.png 1x" />

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    </div>

<Vendors_Popup display={vendorsPopup} data={selectedVendor} closeVendorsPopup={(refresh) => {refresh===true && props.refreshBills(); setVendorsPopup("none")}}/>
</>
  )
}
