import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../../Styles/Pc/ext-nav.css"
import { UserContext } from '../UserContext';
import { Active_Page } from '../Pc/Active_Page'

export const Enav = (props) => {

    const { user, setUser } = useContext(UserContext);

    const { activePage, setActivePage } = useContext(Active_Page);

    const handleIconClick = (page) => {
        setActivePage(page);
    };

    const handlelogout = () => {
        localStorage.clear();
        setUser(null);
        window.location.href = "/";
    }


    return (
        <div className="slideInLeft">
            <div id="extended_nav_finance">
                <div id="Group_3">
                    <svg onClick={() => props.toggleNav("SmallNav")} class="side_nav_bar_border">
                        <rect id="side_nav_bar_border" rx="28" ry="28" x="0" y="0" z="1000" width="246" height="calc(100vh - 100px)">
                        </rect>
                    </svg>
                    <div id="over_all_grp_expand">
                        <img onClick={() => props.toggleNav("SmallNav")} id="side_logo_icon_expand"
                            src={user.imageId && user.imageId.length > 10
                                ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
                                user.imageId
                                :
                                "../NavBar/side_logo_icon.png"
                            }
                        />

                        <div id="menu_name_pc">
                            <marquee behavior="scroll" direction="">
                                <span>{user && user.name}</span>
                            </marquee>
                        </div>

                        <Link to={"/mydashboard"} onClick={() => handleIconClick("Dashboard")} >
                            <div id="dash_bdr_grp_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Dashboard" ? "active_page_e" : ""}>
                                <div id="Dashboard_side_lbl_pc">
                                    <span>Dashboard</span>
                                </div>
                                <img id="dashboard_Icon_I_1" src="../NavBar/dashboard.png" srcset="../NavBar/dashboard.png 1x" />

                            </div>
                        </Link>
                        {
                            (user && (user.userDepartments === "Management") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                            <Link to={"/invoices"} onClick={() => handleIconClick("Invoice")}>
                                <div id="offering_grp_expand_" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Invoice" ? "active_page_e" : ""}>
                                    <div id="Offerings_side_lbl_pc_">
                                        <span>Invoices</span>
                                    </div>
                                    <img id="offerings_icon_I_1_" src="../NavBar/invoice.png" srcset="../NavBar/invoice.png 1x" />

                                </div>
                            </Link>
                        }
                        {
                            (user && (user.userDepartments === "Human Resource") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                            <Link to={"/salaries"} onClick={() => handleIconClick("Salary")} >
                                <div id="salary_grp_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Salary" ? "active_page_e" : ""}>
                                    <div id="salary_side_lbl_pc">
                                        <span>Salaries</span>
                                    </div>
                                    <img id="salary_Icon_I_1" src="../NavBar/Salaries.png" srcset="../NavBar/Salaries.png 1x" />

                                </div>
                            </Link>
                        }
                        {
                            (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Administration") || (user.userDepartments === "Directors")) &&
                            <Link to={"/bills"} onClick={() => handleIconClick("Bill")}>
                                <div id="connection_grp_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Bill" ? "active_page_e" : ""}>
                                    <div id="My_Connection_side_lbl_pc">
                                        <span>Bills</span>
                                    </div>
                                    <img id="my_connections_icon_I_1" src="../NavBar/bill.png" srcset="../NavBar/bill.png 1x" />

                                </div>
                            </Link>
                        }
                        {
                            (user && (user.userDepartments === "Accounts") || (user.userDepartments === "Directors")) &&
                            <Link to={"/taxes"} onClick={() => handleIconClick("Tax")}>
                                <div id="offering_grp_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Tax" ? "active_page_e" : ""}>
                                    <div id="Offerings_side_lbl_pc">
                                        <span>Taxes</span>
                                    </div>
                                    <img id="offerings_icon_I_1" src="../NavBar/taxes.png" srcset="../NavBar/taxes.png 1x" />
                                </div>
                            </Link>
                        }
                        {
                            (user && (user.userDepartments === "Administration") || (user.userDepartments === "Accounts") || (user.userDepartments === "Directors") || (user.userDepartments === "Management")) &&
                            <Link to={"/purchases"} onClick={() => handleIconClick("Purchase")} >
                                <div id="com_grp_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Purchase" ? "active_page_e" : ""}>
                                    <div id="Communities_side_lbl_pc">
                                        <span>Purchases</span>
                                    </div>
                                    <img id="community_icon_I_1" src="../NavBar/purchases.png" srcset="../NavBar/purchases.png 1x" />

                                </div>
                            </Link>
                        }
                        <Link to={"/settings"} onClick={() => handleIconClick("Setup")}>
                            <div id="setup_expand" onClick={() => props.toggleNav("SmallNav")} className={activePage === "Setup" ? "active_page_e" : ""}>
                                <div className="setup_side_lbl">
                                    <span>SetUp</span>
                                </div>
                                <img className="setup_icon_ext" src="../NavBar/management.png" alt="" />
                            </div>
                        </Link>
                        <div id="offering_grp_expand_ba" onClick={() => handlelogout()}>
                            <div id="Offerings_side_lbl_pc_ba">
                                <span>Log Out</span>
                            </div>
                            <img id="logout_icon_I" src="../NavBar/logout.png" srcset="../NavBar/logout.png 1x" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}