import React from "react";
import "../../Styles/Pc/paymentmodes.css"
import { Invoices_Container } from "./Invoices_Container";
import { useLocation } from 'react-router-dom';
import { Bills_Container } from "./Bills_Container";
import { Purchases_Container } from "./Purchases_Container";
import { Salaries_Container } from "./Salaries_Container";
import { Tax_Container } from "./Tax_Container";
import { PaymentModes_Main } from "./PaymentModes_Main";

export const PaymentModes = (props) => {
    let location = useLocation()
	const renderComponents = () => {
		switch (location.pathname) {
			case '/settings':
				return <PaymentModes_Main tab={"PaymentModes"} refresh={props.refresh} />;
			default:
				return '';
		}
	}


	return (
		<>

			<div id="new_layout_near_expiry">
				<div id="top_lbls_purchase_grid_grp_near_expiry">
					<svg class="new_layout_down_card_1_near_expiry">
						<rect id="new_layout_down_card_1_near_expiry" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
						</rect>
					</svg>
					<div id="heading_grp_new_lay_near_expiry">
						<div id="paymentModes_expiry_lbl_purchase_g_paymentModes" onClick={() => props.changeTab("Users")}>
							<span>Users</span>
						</div>
						<div id="onetime__lbl_purchase_grid_paymentModes">
							<span>Payment Modes</span>
						</div>
						{/* <svg class="undesrline_near_expiry" viewBox="0 0 69 1">
							<path id="undesrline_near_expiry" d="M 0 0 L 69 0">
							</path>
						</svg> */}
					</div>
				</div>


				{
					renderComponents()
				}

			</div>


		</>
	)
}
