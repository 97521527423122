import React, { useState, useEffect } from 'react'
import "../../Styles/Pc/tax_container.css"
import { AddTax_Popup } from './AddTax_Popup';
import { UserContext } from "../UserContext";

export const Tax_Container = (props) => {

    const [taxData, setTaxData] = useState([]);
    const [tax, setTax] = useState(UserContext);
    const [addTaxPopup, setAddTaxPopup] = useState("none");
    const [selectedTax, setSelectedTax] = useState({});

    useEffect(() => {
        GetTax(props.tab)

    }, [addTaxPopup])


    const GetTax = (tab) => {

        let apiURL = process.env.REACT_APP_API_URL + "/tax/gettaxes/" + tab;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    setTaxData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }



    return (
        <>
            <div className='tax_overall_container'>
                <div id="add_btn_tax" onClick={() => setAddTaxPopup("Add")} >
                    <svg class="add_bg_Add_tax">
                        <rect id="add_bg_Add_tax" rx="8" ry="8" x="0" y="0" width="26" height="26">
                        </rect>
                    </svg>
                    <svg class="Add_tax" viewBox="6.75 6.75 18 18">
                        <path id="Add_tax" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg>
                </div>
                <div id="lbl_tax">
                    <span>Taxes</span>
                </div>
                <div id="top_menu_bdr_upcome_grp_pc">
                    <svg class="top_menu_bdr_upcome_bdr">
                        <rect id="top_menu_bdr_upcome_bdr" rx="15" ry="15" x="0" y="0" width="100%" height="30">
                        </rect>
                    </svg>
                    <div className='tax_heading'>
                        <div id="Registration_top_menu_bdr_upco_pc">
                            <span>Registration</span>
                        </div>
                        <div id="Tax_Authority_top_menu_bdr_upc">
                            <span>Tax Authority</span>
                        </div>
                        <div id="Due_Date_top_menu_bdr_upcome_pc">
                            <span>Due Date</span>
                        </div>
                        <div id="Tax_Amount_top_menu_bdr_upcome">
                            <span>Tax Amount</span>
                        </div>
                        <div id="Fine_top_menu_bdr_upcome_pc">
                            <span>Fine</span>
                        </div>
                        <div id="Interest_top_menu_bdr_upcome_pc">
                            <span>Interest</span>
                        </div>
                        <div id="Due_Date_tax_menu_bdr_upcome_pc">
                            <span>Paid Date</span>
                        </div>
                        <div id="Status_top_menu_bdr_upcome_pc">
                            <span>Status</span>
                        </div>
                    </div>
                </div>
                <div id="upcoming_scroll_tax_page">
                    {taxData.map((rec) => {
                        return (
                            <div id="card_1upcoming_page" onClick={() => { setAddTaxPopup("Edit"); setSelectedTax(rec) }} >
                                <svg class="card_1_bg_upcoming_page">
                                    <rect id="card_1_bg_upcoming_page" rx="15" ry="15" x="0" y="0" width="100%" height="41">
                                    </rect>
                                </svg>
                                <div className='tax_data'>
                                    <div id="EID_tax_upcoming_page">
                                        <span>{rec.registrationNumber}</span>
                                    </div>
                                    <div id="name_taxes_upcoming_page">
                                        <span>{rec.taxAuthority}</span>
                                    </div>
                                    <div id="from_date_taxes_upcoming_page">
                                        <span>{rec.dueDate && rec.dueDate.slice(0, 10)}</span>
                                    </div>
                                    <div id="to_date_upcomingtax_page">
                                        <span>{rec.taxAmount}</span>
                                    </div>
                                    <div id="amount_tax_upcoming_page">
                                        <span>{rec.fine}</span>
                                    </div>
                                    <div id="amount_tax_upcoming_page_cj">
                                        <span>{rec.interest}</span>
                                    </div>                                    
                                    <div id="from_date_taesx_upcoming_page">
                                        <span>{rec.paidDate && rec.paidDate.slice(0, 10)}</span>
                                    </div>
                                    <div className='status_tax'>
                                        <svg class="to_pay_tax" style={{ fill: rec.status === "Draft" ? "#c088d6" : rec.status === "Approved" ? "#fc8787" : rec.status === "On Hold" ? "rgba(255,153,0,1)" : rec.status === "Paid" ? "rgba(106,169,255,1)" : rec.status === "Cancelled" ? "rgba(153,153,153,1)" : "rgba(255,0,0,1)" }}>
                                            <rect id="to_pay_tax" rx="15" ry="15" x="0" y="0" width="100" height="38">
                                            </rect>
                                        </svg>
                                        <div id="status_tax_upcoming_page">
                                            <div className='status_word'>
                                                <span>{rec.status}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>


                <AddTax_Popup display={addTaxPopup} data={selectedTax} closeAddTaxPopup={() => setAddTaxPopup("none")} refresh={props.refresh} />
            </div>
        </>
    )
}

